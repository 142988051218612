<template lang="pug">
  .cardy-kontakt.page-section
    .form-card
      .card-content.pa-5.pa-lg-10
        h1 Kontakt
        p.mt-8 Ihr Ansprechpartner in technischen Fragen Udo Polder
        p Für technische Fragen schreiben Sie bitte eine Mail an contact@cardy.cloud

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

@Component({
})
export default class Kontakt extends Vue {

}
</script>
