import axios from 'axios'
import moment from 'moment'

import config from '@/config'
import store from '@/store'
import Urls from '@/router/urls'

const MAX_VALUE_SIZE = 1024 * 10 // 10KB

const updateLastActionTime = (uri) => {
  if (!store.getters.authToken || uri === Urls.Auth.Login || uri === Urls.Auth.Refresh) {
    return
  }

  store.dispatch('setLastActionTime', Math.floor(new Date() / 1000))
}

const parseErrorResponse = (error, path, apiParams) => {
  if (!error.response) {
    return 'Not connected'
  }

  if (store.getters.authToken && error.response.status === 401) {
    store.dispatch('clearAuthToken')
    return 'Authentication required'
  }

  if (!error.response.data || !error.response.data.message) {
    return 'Unknown backend error'
  }

  if (error.response.data.code >= 100 && error.response.data.code <= 104) {
    return error.response.data
  }

  const errorCode = error.response.data.code
    ? `ERROR ${ error.response.data.code }:`
    : 'ERROR:'

  const errMessage = `${ errorCode } ${ error.response.data.message }`

  let paramsStr = `url: ${ path }`
  if (apiParams) {
    let params = {}
    if (apiParams instanceof FormData) {
      apiParams.forEach((value, key) => {
        if (value && typeof value === 'string' && value.length > MAX_VALUE_SIZE) {
          params[key] = `${ value.substring(0, 20) }...` // restrict only 20 chars to send
        } else {
          params[key] = value
        }
      })
    } else {
      Object.keys(apiParams).forEach(key => {
        const value = apiParams[key]
        if (value && typeof value === 'string' && value.length > MAX_VALUE_SIZE) {
          params[key] = `${ value.substring(0, 20) }...` // restrict only 20 chars to send
        } else {
          params[key] = value
        }
      })
    }
    paramsStr = `${ paramsStr }, params: ${ JSON.stringify(params) }`
  }

  // send error info to the back-end
  const params = {
    secret_key: config.JSLOG_SECRET_KEY,
    client_timestamp: moment().format(),
    message: errMessage,
    type: 'API Error',
    component: 'axios',
    line_nr: '0',
    params: paramsStr,
    browser: navigator.userAgent
  }
  // console.log(params)

  axios.post(`${ config.API_BASE }${ Urls.Dev.SaveError }`, params)

  return errMessage
}

export default {
  get (path = '', params = null, download = false) {
    const data = {
      params,
      headers: {}
    }

    if (store.getters.authToken) {
      data.headers.Authorization = `Bearer ${ store.getters.authToken }`
    }

    if (store.getters.selectedTenant) {
      data.headers['cardytenantid'] = store.getters.selectedTenant.id
    }

    if (download) {
      data.responseType = 'blob'
    }

    updateLastActionTime(path)

    return axios
      .get(`${ config.API_BASE }${ path }`, data)
      .catch(err => {
        throw new Error(parseErrorResponse(err, path, params))
      })
  },

  post (path = '', params = null, fileUpload = false, fileDownload = false) {
    let headers = {}

    if (store.getters.authToken) {
      headers.Authorization = `Bearer ${ store.getters.authToken }`
    }

    if (store.getters.selectedTenant) {
      headers['cardytenantid'] = store.getters.selectedTenant.id
    }

    if (fileUpload) {
      headers['Content-Type'] = 'multipart/form-data'
    }

    let conf = {
      headers: headers,
    }

    if (fileDownload) {
      conf.responseType = 'blob'
    }

    updateLastActionTime(path)

    return axios
      .post(`${ config.API_BASE }${ path }`, params, conf)
      .catch(err => {
        throw new Error(parseErrorResponse(err, path, params))
      })
  },

  put (path = '', params = null, fileUpload = false) {
    let headers = {}

    if (store.getters.authToken) {
      headers.Authorization = `Bearer ${ store.getters.authToken }`
    }

    if (store.getters.selectedTenant) {
      headers['cardytenantid'] = store.getters.selectedTenant.id
    }

    if (fileUpload) {
      headers['Content-Type'] = 'multipart/form-data'
    }

    updateLastActionTime(path)

    return axios
      .put(`${ config.API_BASE }${ path }`, params, { headers })
      .catch(err => {
        throw new Error(parseErrorResponse(err, path, params))
      })
  },

  delete (path = '', data = null) {
    let headers = {}

    if (store.getters.authToken) {
      headers.Authorization = `Bearer ${ store.getters.authToken }`
    }

    if (store.getters.selectedTenant) {
      headers['cardytenantid'] = store.getters.selectedTenant.id
    }

    updateLastActionTime(path)

    return axios
      .delete(`${ config.API_BASE }${ path }`, { data, headers })
      .catch(err => {
        throw new Error(parseErrorResponse(err, path, data))
      })
  },
}
