import apiCall from '@/utils/api-call'
import Urls from '@/router/urls'

export default {
  loadCompanies ({ commit, state }) {
    commit('SELECT_COMPANY', null)
    commit('SET_COMPANIES', [])

    return apiCall.get(Urls.Company())
      .then(res => {
        const companies = res.data || []
        commit('SET_COMPANIES', companies)

        if (state.selectedTenant) {
          // check if selected agency exists in the agency list from server
          const index = companies.findIndex(c => c.id === state.selectedTenant.id)
          if (index === -1) {
            commit('SELECT_COMPANY', null)
          }
        }
      })
  },

  setOrderData ({ commit }, payload) {
    commit('SET_ORDER_DATA', payload)
  },

  setPageFilter ({ commit }, payload) {
    commit('SET_PAGE_FILTER', payload)
  },

  resetPageFilter ({ commit }, payload) {
    commit('RESET_PAGE_FILTER', payload)
  },

  selectTenant ({ commit }, payload) {
    commit('SELECT_TENANT', payload)
  },

  setIsLoading ({ commit }, payload) {
    commit('SET_IS_LOADING', payload)
  },

  setSidebarVisible ({ commit }, payload) {
    commit('SET_SIDEBAR_VISIBLE', payload)
  },
}
