<template lang="pug">
  UserDetails(:id="userData.user_id" is-my-profile)

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import AuthMixin from '@/mixins/auth'

@Component({
  mixins: [
    AuthMixin
  ],

  components: {
    UserDetails: () => import('@/pages/admin/user/details')
  }
})
export default class MyProfile extends Vue {

}
</script>
