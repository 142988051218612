<template lang="pug">
  div.app-admin-base
    template
      navigation-bar

      v-main
        v-container.pt-0.pb-5.px-6(fluid)
          tool-bar.py-5

          .base-view
            .loading-overlay(v-if="isLoading")
              v-progress-circular(:size="80" color="#666" indeterminate)

            router-view

</template>

<script>
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  components: {
    NavigationBar: () => import('@/components/navigation-bar'),
    ToolBar: () => import('@/components/tool-bar'),
  },

  computed: mapGetters([
    'authToken',
    'isLoading',
    'sidebarVisible'
  ]),
})
export default class AdminBase extends Vue {
  @Watch('authToken')
  onUpdateAuthToken (token) {
    if (!token && this.$route.name !== 'login') {
      return this.$router.push({
        name: 'login'
      })
    }
  }
}
</script>

<style lang="sass">
.app-admin-base
  min-height: 100vh

  .base-view
    min-height: calc(100vh - 104px)

    .action-header
      position: sticky
      top: 76px
      z-index: 2
      display: flex
      align-items: center
      justify-content: flex-end
      background-color: #fff
      margin-bottom: 15px

    .loading-overlay
      position: fixed
      top: 0
      left: 0
      right: 0
      bottom: 0
      z-index: 9999
      background-color: rgba(0, 0, 0, 0.25)

      .v-progress-circular
        position: fixed
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
</style>
