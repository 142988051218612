import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'userData',
      'userGroup',
      'selectedTenant'
    ]),

    isSuperAdmin () {
      return this.userData && this.userData.is_super_admin === true
    },

    isAdmin () {
      return this.userGroup === 'Admin'
    },

    isUser () {
      return this.userGroup === 'User'
    },
  }
}
