<template lang="pug">
  v-checkbox(
    :input-value="value"
    :class="['tri-state', { 'checkbox-disabled': value === null || value === undefined, 'checkbox-checked': value === true, 'checkbox-unchecked': value === false }]"
    :label="label"
    @click="onClickCheckbox"
  )
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
})
export default class TriState extends Vue {
  @Prop({
    type: [ Number, Boolean ],
    default: null
  }) value

  @Prop({
    type: String,
    default: ''
  }) label

  onClickCheckbox () {
    if (this.value === null || this.value === undefined) {
      this.$emit('input', false)
    } else if (this.value === false) {
      this.$emit('input', true)
    } else if (this.value === true) {
      this.$emit('input', null)
    }
  }
}
</script>

<style lang="sass">
.tri-state.checkbox-disabled
  .v-icon.material-icons
    color: gray !important

.tri-state.checkbox-checked
  .v-icon.material-icons
    color: rgba(0,128,0,.7) !important

.tri-state.checkbox-unchecked
  .v-icon.material-icons
    color: transparent !important
    background: #ff000050
    border-radius: 3px
    box-sizing: border-box
    width: calc(100% - 5px)
    height: calc(100% - 5px)
    margin: auto
</style>
