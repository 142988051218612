<template lang="pug">
  v-card.pa-6
    v-row.sticky-card-header.align-center.mt-4
      v-col(cols="6")
        h2.primary--text Liste Bestellungen
    v-checkbox.ml-2(v-if="isSuperAdmin" v-model="fromArchive" label="Aus-Archiv" @change="loadArchive()")

    custom-table.scrolling-table.mt-3(
      page-id="orders"
      :headers="headers"
      :table-data="tableData"
      stripped
      @load="(e) => loadCards(e)"
    )
      template(#item.tenant_name="{ item }") {{ item.tenant_name }} {{ item.tenant_name2 }}
      template(#item.is_delete="{ value }") {{ value ? 'Ja' : 'Nein' }}
      template(#item.online_ready="{ value }") {{ value ? 'Ja' : 'Nein' }}
      template(#item.validated="{ value }") {{ value ? 'Ja' : 'Nein' }}
      template(#item.created_at="{ value }") {{ formatDateTime(value, 'DD.MM.YYYY HH:mm') }}
      template(#item.##="{ item }")
        .d-flex.items-center
          a.admin-view(v-if="isSuperAdmin" title="Ausweis herunterladen" @click.prevent="downloadCard(item.id)")
            v-icon file_download

          a(title="Card bearbeiten" @click.prevent="onEditCard(item)")
            v-icon edit

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import { computed } from 'vue'

import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'
import Urls from '@/router/urls'
import {
  mapGetters
} from "vuex";

@Component({
  mixins: [
    AuthMixin,
    FormMixin
  ],

  computed: {
    fromArchiveStr() {
      return this.fromArchive ?  'order_archive.id': 'ordering.id'
    },

    headers() {
      return [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          pagination_value: this.fromArchiveStr
        },
        {
          text: 'Mandant',
          align: 'left',
          value: 'tenant_name',
          use_slot_cell: true,
        },
        {
          text: 'Mand-ID',
          align: 'left',
          value: 'tenant_id'
        },
        {
          text: 'Produkt Typ',
          align: 'left',
          value: 'product_type'
        },
        {
          text: 'Nachname',
          align: 'left',
          value: 'familyname'
        },
        {
          text: 'Vorname',
          align: 'left',
          value: 'forname',
        },
        {
          text: 'Gelöscht?',
          align: 'center',
          value: 'is_delete',
          use_slot_cell: true,
          filter_type: 'checkbox',
          filter_default: false
        },
        {
          text: 'Online-Ready?',
          align: 'center',
          value: 'online_ready',
          use_slot_cell: true,
          filter_type: 'checkbox'
        },
        {
          text: 'Validiert?',
          align: 'center',
          value: 'validated',
          use_slot_cell: true,
          filter_type: 'checkbox'
        },
        {
          text: 'Produktcode',
          align: 'center',
          value: 'product_short_code',
        },
        {
          text: 'Preis',
          align: 'center',
          value: 'price',
        },
        {
          text: 'Erstellt',
          align: 'center',
          value: 'created_at',
          use_slot_cell: true
        },
        {
          text: '',
          align: 'right',
          value: '##',
          use_slot_cell: true
        },
      ]
    }
  }
})
export default class CardList extends Vue {
  tableData = {}
  tableItems = []
  fromArchive = false

  page = 1
  pagination = {}

  loadArchive() {
    return this.loadCards(this.pagination)
  }

  loadCards (pagination = null) {
    if (pagination) {
      this.pagination = pagination
    }
    this.page = pagination.page

    this.$axios
      .post(Urls.Orders.List, {
        pagination,
        'use_archive': this.fromArchive,
      })
      .then(res => {
        this.tableData = {
          ...res.data,
          pagination
        }
      })
  }

  onEditCard (item) {
    this.selectTenant({
      id: item.tenant_id,
      name: item.tenant_name,
      name2: item.tenant_name2,
      short_code: item.short_code,
      is_test: item.is_test,
    })
      .then(() => {
        this.$router.push({
          name: 'card-edit',
          params: {
            id: item.id,
            fromArchive: this.fromArchive,
          }
        })
      })
  }

}
</script>
