<template lang="pug">
  v-card.cardy-admin-home.pa-6
    h2.primary--text Dashboard

    template(v-if="isSuperAdmin")
      v-card.admin-view.pa-6.mt-5
        v-row.sticky-card-header.align-center.mt-4
          v-col(cols="12" sm="6")
            h2.primary--text Offene Registrierungen

          v-col.text-right(cols="12" sm="6")
            v-btn(color="primary" @click="gotoRoute('register-filter')") Zu den Registrierungen

        v-data-table.mt-6(
          v-bind="tableProps"
          :headers="registerheaders"
          :items="registers"
          :options.sync="dataOptions"
        )
          template(#item.created_at="{ value }") {{ formatDateTime(value, 'DD.MM.YYYY HH:mm') }}

      v-card.admin-view.pa-6.mt-5
        v-row.sticky-card-header.align-center.mt-4
          v-col(cols="12" sm="6")
            h2.primary--text Letzten Bestellungen

          v-col.text-right(cols="12" sm="6")
            v-btn(color="primary" @click="gotoRoute('order-list')") Zu den Bestellungen

        v-data-table.mt-6(
          v-bind="tableProps"
          :headers="orderHeaders"
          :items="orders"
          :options.sync="dataOptions"
          hide-default-footer
        )
          template(#item.tenant_name="{ item }") {{ item.tenant_name }} {{ item.tenant_name2 }}
          template(#item.is_delete="{ value }") {{ value ? 'Ja' : 'Nein' }}
          template(#item.online_ready="{ value }") {{ value ? 'Ja' : 'Nein' }}
          template(#item.validated="{ value }") {{ value ? 'Ja' : 'Nein' }}
          template(#item.created_at="{ value }") {{ formatDateTime(value, 'DD.MM.YYYY HH:mm') }}

    v-card.pa-6.mt-5(v-else)
      v-row.sticky-card-header.align-center.mt-4
        v-col(cols="12" sm="6")
          h2.primary--text Neuste Ausweise

        v-col.text-right(cols="12" sm="6")
          v-btn(color="primary" @click="gotoRoute('card-list')") Zu den Ausweisen

      v-data-table.mt-6(
        v-bind="tableProps"
        :headers="cardHeaders"
        :items="cards"
        :options.sync="dataOptions"
        hide-default-footer
      )
        template(#item.is_delete="{ value }") {{ value ? 'Ja' : "Nein" }}
        template(#item.online_ready="{ value }") {{ value ? 'Ja' : "Nein" }}
        template(#item.validated="{ value }") {{ value ? 'Ja' : "Nein" }}
        template(#item.birthday="{ value }") {{ formatDateTime(value, 'DD.MM.YYYY') }}

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'
import Urls from '@/router/urls'

@Component({
  mixins: [
    AuthMixin,
    FormMixin
  ],
})
export default class AdminHome extends Vue {
  registers = []
  registerheaders = [
    {
      text: 'ID',
      align: 'left',
      value: 'id'
    },
    {
      text: 'Anrede',
      align: 'left',
      value: 'salutation'
    },
    {
      text: 'Vorname',
      align: 'left',
      value: 'forename'
    },
    {
      text: 'Nachname',
      align: 'left',
      value: 'familyname',
    },
    {
      text: 'Mandanten-Name',
      align: 'left',
      value: 'tenantname'
    },
    {
      text: 'Bereich',
      align: 'left',
      value: 'area',
    },
    {
      text: 'Telefone',
      align: 'center',
      value: 'telefone',
    },
    {
      text: 'Strasse',
      align: 'center',
      value: 'street',
    },
    {
      text: 'PLZ',
      align: 'center',
      value: 'plz',
    },
    {
      text: 'Ort',
      align: 'center',
      value: 'city',
    },
    {
      text: 'Erstellt am',
      align: 'center',
      value: 'created_at',
    },
  ]

  orders = []
  orderHeaders = [
    {
      text: 'ID',
      align: 'left',
      value: 'id',
    },
    {
      text: 'Mandant',
      align: 'left',
      value: 'tenant_name',
      use_slot_cell: true
    },
    {
      text: 'Mand-ID',
      align: 'left',
      value: 'tenant_id'
    },
    {
      text: 'Produkt Typ',
      align: 'left',
      value: 'product_type'
    },
    {
      text: 'Nachname',
      align: 'left',
      value: 'familyname'
    },
    {
      text: 'Vorname',
      align: 'left',
      value: 'forname',
    },
    {
      text: 'Gelöscht?',
      align: 'center',
      value: 'is_delete',
    },
    {
      text: 'Online-Ready?',
      align: 'center',
      value: 'online_ready',
    },
    {
      text: 'Validiert?',
      align: 'center',
      value: 'validated',
    },
    {
      text: 'Produktcode',
      align: 'center',
      value: 'product_short_code',
    },
    {
      text: 'Erstellt',
      align: 'center',
      value: 'created_at',
    },
    {
      text: '',
      align: 'right',
      value: '##',
    },
  ]

  cards = []
  cardHeaders = [
    {
      text: 'ID',
      align: 'left',
      value: 'id'
    },
    {
      text: 'Produkt Typ',
      align: 'left',
      value: 'product_type'
    },
    {
      text: 'Nachname',
      align: 'left',
      value: 'familyname'
    },
    {
      text: 'Vorname',
      align: 'left',
      value: 'forname',
    },
    {
      text: 'Externe-ID',
      align: 'left',
      value: 'external_id'
    },
    {
      text: 'Externe-Abteilung',
      align: 'left',
      value: 'external_department',
    },
    {
      text: 'Gelöscht?',
      align: 'center',
      value: 'is_delete',
    },
    {
      text: 'Online-Ready?',
      align: 'center',
      value: 'online_ready',
    },
    {
      text: 'Validiert?',
      align: 'center',
      value: 'validated',
    },
    {
      text: 'Produktcode',
      align: 'center',
      value: 'product_short_code',
    },
    {
      text: 'Geburtstag',
      align: 'center',
      value: 'birthday',
    },
    {
      text: 'Bild',
      align: 'center',
      value: 'image',
    },
  ]

  created () {
    if (this.isSuperAdmin) {
      this.loadData()
      this.loadOrders()
    } else {
      this.loadCards()
    }
  }

  loadData () {
    this.$axios
      .get(Urls.Register.RegisterListNew)
      .then(res => {
        this.registers = (res.data || [])
      })
  }

  loadOrders () {
    this.$axios
      .post(Urls.Orders.List, {
        pagination: {
          count: 10,
          page: 1,
          sorts: [
            {
              field: 'ordering.id',
              is_desc: true
            }
          ]
        }
      })
      .then(res => {
        this.orders = res.data.data || []
      })
  }

  loadCards () {
    this.$axios
      .post(Urls.Card.List, {
        pagination: {
          count: 10,
          page: 1,
          sorts: [
            {
              field: 'id',
              is_desc: true
            }
          ]
        }
      })
      .then(res => {
        this.cards = res.data.data || []
      })
  }
}
</script>
