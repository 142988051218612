<template lang="pug">
  div
    v-main
      v-container.grid-list-md
        router-view

    v-footer(:fixed="false" app)
      span &copy; Cardy

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

@Component({

})
export default class AuthBase extends Vue {

}
</script>
