<template lang="pug">
  v-container.pa-0
    v-card
      v-card-title
        h3 Build Info

      v-card-text
        p info back
        pre.app-info {{ backend_info }}
        p.mt-5 info front
        pre.app-info {{ frontend_info }}

</template>

<script>
import Urls from '@/router/urls'

export default {
  name: 'AppInfo',
  data () {
    return {
      backend_info: '{}',
      frontend_info: {
        BuildDate: process.env.VUE_APP_BUILD_DATE,
        GitHash: process.env.VUE_APP_COMMIT_HASH,
        GitRemote: process.env.VUE_APP_GIT_REMOTE,
      },
    }
  },
  mounted: function () {
    this.loadInfo()
  },
  methods: {
    loadInfo () {
      this.$axios
        .get(Urls.Dev.AppInfo)
        .then(response => {
          this.backend_info = response.data
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>
