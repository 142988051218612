<template lang="pug">
  .tenant-details
    .action-header
      v-btn(v-if="isUpdated" color="primary" @click="onSaveTenant") Speichern

    v-card.pa-6: v-form(ref="form-tenant" lazy-validation)
      v-row
        v-col(sm="6"): h2.primary--text {{ title }}

      v-row.justify-center(v-if="tenant.tenant_type")
        v-card.d-inline-block.my-4.mx-8(
          v-for="(image, index) in images"
          :key="index"
        )
          product-image(
            v-if="tenant.tenant_type !== 'Verband' || image.id !== 'sign'"
            :file="image.src"
            :type="image.id"
            :width="image.width"
            :height="image.height"
            is-base64
            @select="(e) => onSelectImage(index, e)"
          )
            template(#action-text)
              span.mr-2 {{ getImageLabel(image.id) }}
              info-tooltip.image-hint(align="center") Ideale Bildgröße Breite {{ image.width }} Pixel und Höhe {{ image.height }} Pixel

      v-row.my-6
        v-col.pb-0(cols="12" sm="6"): v-select(
          v-model="tenant.country_id"
          label="Land"
          outlined
          dense
          :items="countries"
          :rules="[ rules.required ]"
          @change="onSelectCountry"
        )

        v-col.pb-0(cols="12" sm="6"): v-select(
          v-model="tenant.tenant_type"
          label="Mandantentype"
          outlined
          dense
          :items="tenantTypes"
          item-value="id"
          item-text="id"
          :rules="[ rules.required ]"
          @change="onSelectType"
        )

        template(v-if="tenant.country_id && tenant.tenant_type")
          v-col.pb-0(cols="12" sm="6"): v-text-field(
            v-model="tenant.short_code"
            label="Zugangscode"
            dense
            outlined
            :readonly="!!id"
            :rules="[ rules.required ]"
          )

          v-col.pb-0(cols="12" sm="6"): v-text-field(
            v-model="tenant.name"
            label="Name"
            dense
            outlined
            :rules="[ rules.required ]"
          )

          v-col.pb-0(cols="12" sm="6"): v-text-field(
            v-model="tenant.name2"
            label="Name2"
            dense
            outlined
          )

          v-col.pb-0(cols="12" sm="6"): v-text-field(
            v-model="tenant.street"
            label="Strasse"
            dense
            outlined
            :rules="[ rules.required ]"
          )

          v-col.pb-0(cols="12" sm="3"): v-text-field(
            v-model="tenant.plz"
            label="PLZ"
            dense
            outlined
            :rules="[ rules.required ]"
          )

          v-col.pb-0(cols="12" sm="3"): v-text-field(
            v-model="tenant.city"
            label="Ort"
            dense
            outlined
            :rules="[ rules.required ]"
          )

          v-col.pb-0(cols="12" sm="6"): v-text-field(
            v-model="tenant.usst_id"
            label="UsstID"
            dense
            outlined
          )

          v-col.pb-0(cols="12" sm="6"): v-text-field(
            v-model="tenant.telefone"
            label="TelefonNr."
            dense
            outlined
            :rules="[ rules.required ]"
          )

          v-col.pb-0(cols="12" sm="6"): v-text-field(
            v-model="tenant.email"
            label="E-Mail"
            dense
            outlined
            :rules="[ rules.required, rules.email ]"
          )

          v-col.mb-3(cols="12" sm="3"): v-checkbox.mt-1(
            v-model="tenant.is_test"
            label="Ist Test"
            hide-details
          )

          v-col.mb-3(cols="12" sm="3"): v-checkbox.mt-1(
            v-model="tenant.tenant_payment"
            label="Mandaten-Zahlung"
            hide-details
            @change="onChangeTenantPayment"
          )

          v-col.pb-0(cols="12"): h4.primary--text Produktkonfiguration
          v-col(cols="12")
            v-card.pa-6: v-data-table(
              :headers="headers"
              :items="tenantProducts"
              :options="tenantTableOptions"
              :hide-default-footer="true"
            )
              template(#item.#="{ item }")
                v-checkbox(v-model="item.selected" dense)

              template(#item.max_years_valid="{ item }")
                v-text-field.input-max-days(
                  v-model="item.max_years_valid"
                  dense
                  outlined
                  hide-details
                  type="number"
                  :rules="[ item.selected ? rules.required : true ]"
                  :disabled="!item.selected"
                )

              template(#item.payment_disabled="{ item }")
                v-checkbox.d-inline-block.mt-0.pt-0(
                  v-model="item.payment_disabled"
                  dense
                  hide-details
                  :disabled="!item.selected"
                )

              template(#item.can_create_tenant_product="{ item }")
                v-checkbox.d-inline-block.mt-0.pt-0(
                  v-model="item.can_create_tenant_product"
                  dense
                  hide-details
                  :disabled="!item.selected"
                )

              template(#item.tenant_payment="{ item }")
                v-checkbox.d-inline-block.mt-0.pt-0(
                  v-model="item.tenant_payment"
                  dense
                  hide-details
                  :disabled="!item.selected"
                )

            v-input(
              :value="selectedProducts"
              :rules="[ rules.valid_array ]"
            )

          template(v-if="id")
            v-col.pb-0(cols="12"): h4.primary--text Mandaten-Benutzer
            v-col.pb-0(cols="12")
              user-list(is-of-tenant)

          template(v-if="!id")
            v-col.d-flex.align-center.py-0(cols="12")
              h4.primary--text.mr-4 Initialer Benutzer
              v-switch(
                v-model="isAddUser"
              )

            template(v-if="isAddUser")
              v-col.pb-0(cols="12" sm="6"): v-text-field(
                v-model="tenant.user_email"
                label="UserEmail"
                dense
                outlined
                :rules="[ rules.required, rules.email ]"
              )

              v-col.pb-0(cols="12" sm="6"): v-select(
                v-model="tenant.salutation"
                label="Anrede"
                dense
                outlined
                :items="salutations"
                :rules="[ rules.required ]"
              )

              v-col.pb-0(cols="12" sm="6"): v-text-field(
                v-model="tenant.user_forename"
                label="UserForename"
                dense
                outlined
              )

              v-col.pb-0(cols="12" sm="6"): v-text-field(
                v-model="tenant.user_lastname"
                label="UserLastname"
                dense
                outlined
                :rules="[ rules.required ]"
              )

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

import config from '@/config'
import Urls from '@/router/urls'
import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    FormMixin
  ],

  components: {
    ProductImage: () => import('@/components/common/product-image'),
    UserList: () => import('@/pages/admin/user/list')
  },

  watch: {
    tenant: {
      deep: true,
      handler () {
        this.isUpdated = this.dataLoaded
      }
    },

    products: {
      deep: true,
      handler () {
        this.isUpdated = this.dataLoaded
      }
    }
  }
})
export default class TenantDetails extends Vue {
  @Prop({ type: [ Number, String ], default: 0 }) id
  @Prop({ type: Boolean, default: false }) readonly

  tenant = {}
  tenantConfig = []
  tenantTypes = []
  tenantProducts = []
  products = []

  headers = [
    {
      text: '#',
      align: 'left',
      value: '#',
      sortable: false
    },
    {
      text: 'ID',
      align: 'left',
      value: 'id',
      sortable: false
    },
    {
      text: 'Produkt Typ',
      align: 'left',
      value: 'description'
    },
    {
      text: 'Max Jahre Gültig',
      align: 'left',
      value: 'max_years_valid',
      sortable: false
    },
    {
      text: 'Zahlung deaktiviert',
      align: 'center',
      value: 'payment_disabled',
      sortable: false
    },
    {
      text: 'Darf Mand.Produkte anlegen',
      align: 'center',
      value: 'can_create_tenant_product',
      sortable: false
    },
    {
      text: 'Mandaten-Zahlung',
      align: 'center',
      value: 'tenant_payment',
      sortable: false
    },
  ]

  tenantTableProps = {
    'no-data-text': 'Keine Daten vorhanden',
    'no-results-text': 'Keine Daten gefunden',
    'footer-props': {
      'items-per-page-options': [-1],
      'items-per-page-text': 'Anzahl pro Seite',
      'pagination': {
        itemsLength: 0,
        pageStart: 0,
        pageStop: 0
      },
    }
  }

  tenantTableOptions = {
    page: 1,
    itemsPerPage: -1,
    sortBy: [ 'id' ],
    sortDesc: [ false ],
    multiSort: false,
  }

  dataLoaded = false
  isUpdated = false
  isAddUser = false

  images = [
    {
      id: 'logo',
      label: 'Logo Bild',
      src: '',
      file: null,
      width: 230,
      height: 130
    },
    {
      id: 'stamp',
      label: 'Stempel Bild',
      src: '',
      file: null,
      width: 260,
      height: 205
    },
    {
      id: 'sign',
      label: 'Signum Bild',
      src: '',
      file: null,
      width: 260,
      height: 70
    }
  ]

  get title () {
    if (!this.id) {
      return 'Mandanten anlegen'
    }

    return this.tenant && this.tenant.id
      ? `Mandanten (${ this.tenant.name }) bearbeiten`
      : `Mandanten bearbeiten`
  }

  get selectedProducts () {
    return this.tenantProducts
      .filter(p => p.selected)
      .map(p => ({
        product_id: p.id,
        max_years_valid: parseInt(p.max_years_valid) || 0,
        payment_disabled: p.payment_disabled,
        can_create_tenant_product: p.can_create_tenant_product,
        tenant_payment: p.tenant_payment
      }))
  }

  async created () {
    this.dataLoaded = !this.id
    this.isUpdated = !this.id
    await this.loadTenantConfig()
    await this.loadTenantDetails()
  }

  getImageLabel (id) {
    const defaultLabels = {
      logo: 'Logo Bild',
      stamp: 'Stempel Bild',
      sign: 'Signum Bild',
    }
    if (this.tenant.tenant_type !== 'Verband') {
      return defaultLabels[id] || ''
    }
    const verbandLabels = {
      logo: 'Verbands-Logo',
      stamp: 'Stempel Verein',
      sign: 'Verein Logo',
    }
    return verbandLabels[id] || ''
  }

  async loadTenantConfig () {
    this.tenantTypes = []
    return this.$axios
      .get(Urls.Tenant.Config)
      .then(res => {
        this.tenantConfig = res.data || []
        this.tenantConfig.forEach(t => {
          const tIndex = this.tenantTypes.findIndex(tt => tt.id === t.tenant_type)
          if (tIndex === -1) {
            this.tenantTypes.push({
              id: t.tenant_type,
              products: [ t.product_type ]
            })
          } else {
            this.tenantTypes[tIndex].products.push(t.product_type)
          }
        })
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
  }

  async loadTenantDetails () {
    if (!this.id) {
      return
    }

    return this.$axios
      .get(Urls.Tenant.Details, {
        id: this.id
      })
      .then(res => {
        this.tenant = res.data
        this.loadTenantProducts(res.data.country_id)

        this.images = this.images.map(i => {
          const fileName = this.tenant[`${ i.id }_file`]
          i.src = fileName ? this.getImagePath(fileName) : ''
          i.file = null
          return i
        })
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.isUpdated = false
      })
  }

  loadTenantProducts (countryId) {
    this.$axios
      .get(Urls.Tenant.Products, {
        country_id: countryId
      })
      .then(res => {
        this.products = (res.data || []).map(p => {
          let product = null
          if (this.tenant.products_available) {
            product = this.tenant.products_available.find(p_ => p_.product_id === p.id)
          }
          return {
            ...p,
            max_years_valid: product ? product.max_years_valid : 1,
            payment_disabled: product ? product.payment_disabled : false,
            can_create_tenant_product: product ? product.can_create_tenant_product : true,
            tenant_payment: product ? product.tenant_payment : false,
            selected: !!product
          }
        })
        if (this.tenant.tenant_type) {
          this.onSelectType(this.tenant.tenant_type)
        }
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.dataLoaded = true
      })
  }

  onChangeTenantPayment (e) {
    if (!e || this.id) {
      return
    }
    this.tenantProducts = this.tenantProducts.map(p => ({
      ...p,
      tenant_payment: true
    }))
  }

  onSelectImage (index, blob) {
    this.images[index].file = blob
    this.isUpdated = true
  }

  onSaveTenant () {
    if (!this.isFormValid('form-tenant')) {
      return
    }

    const parmas = {
      ...this.tenant,
      products: this.selectedProducts
    }

    if (parmas.products_available) {
      parmas.products_available = null
    }

    this.images
      .filter(i => !!i.file && (this.tenant.tenant_type !== 'Verband' || i.id !== 'sign'))
      .forEach(i => {
        parmas[`file_${ i.id }`] = i.file
      })

    this.setIsLoading(true)

    this.$axios
      .post(Urls.Tenant.Upsert, parmas)
      .then(res => {
        this.isUpdated = false
        this.$toast.success('Daten gespeichert', 'Speichern')

        if (!this.id || (this.selectedTenant && this.selectedTenant.id === this.id)) {
          this.selectTenant(res.data)
        }

        if (!this.id) {
          this.$router.push({
            name: 'tenant-list'
          })
        } else {
          this.images = this.images.map(i => {
            const fileName = res.data[`${ i.id }_file`]
            i.src = fileName ? this.getImagePath(fileName) : ''
            i.file = null
            return i
          })
        }
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  onSelectCountry (v) {
    this.tenant.product_ids = []
    this.loadTenantProducts(v)
  }

  onSelectType (e) {
    this.tenant.product_ids = []
    const tenantType = this.tenantTypes.find(tt => tt.id === e)
    if (!tenantType) {
      return
    }
    this.tenantProducts = this.products.filter(p => tenantType.products.includes(p.product_type))
  }
}
</script>

<style lang="scss">
.input-max-days {
  max-width: 100px;
}
</style>
