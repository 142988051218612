console.log(process.env)

export default {
  APP_VERSION: '0.1.0',

  // do not forget authToken on page reload (for development)
  // KEEP_LOGGED_IN: process.env.NODE_ENV === 'development',
  KEEP_LOGGED_IN: true,
  STORAGE_KEY: 'cardy-storage',
  JSLOG_SECRET_KEY: 'lfdksjfdsopi93kfdnsdk§gfrsd',

  ...((process.env.NODE_ENV === 'development') ? require('./dev').default : {}),
  ...((process.env.NODE_ENV === 'staging') ? require('./stage').default : {}),
  ...((process.env.NODE_ENV === 'production') ? require('./prod').default : {}),
}
