<template lang="pug">
  div.app-base(:class="$route.name")
    v-row.nav-bar.ma-0
      v-col.px-xl-2(cols="6")
        a(@click.prevent="gotoRoute('home')"): img.img-logo.ma-2.ma-lg-5.ma-xl-8(src="@/assets/images/logo.png")

      v-col.d-flex.align-center.justify-end.px-xl-0(v-if="isShowNav" cols="6")
        a.cursor-pointer.mr-2.mr-md-4.mr-lg-6.mr-xl-10(@click="gotoRoute('home')") Start
        a.cursor-pointer.mr-2.mr-md-4.mr-lg-6.mr-xl-10(@click="gotoRoute('register')") Kontakt-Anforderung

        a.d-flex.align-center.cursor-pointer(v-if="!isLoggedIn" @click.prevent="gotoRoute('login')")
          span.mr-2.mr-md-3.mr-xl-5 Login
          img.icon-login.ma-0.ma-md-3.ma-lg-5.ma-xl-10(src="@/assets/images/icon-login.png")

        template(v-else)
          a.d-flex.align-center.cursor-pointer(@click.prevent="gotoRoute('admin-home')")
            span.mr-2.mr-md-3.mr-xl-5 Admin
          a.d-flex.align-center.cursor-pointer(@click.prevent="onLogout")
            v-icon.icon-login.ma-0.ma-md-3.ma-lg-5.ma-xl-10(color="primary") logout

      v-col.d-flex.align-center.justify-end.px-xl-0(v-else-if="$route.name === 'card' || $route.name === 'ordering' || $route.name === 'faq'" cols="6")
        a.mr-5(@click="openRoute('datenschutz')") Datenschutz

    v-main
      .main-container
        router-view

      v-row.footer.ma-0.px-2.px-lg-4.px-xl-5.py-0.py-lg-2.py-xl-4
        v-col.text-center.text-sm-left.pb-0.pb-sm-3(cols="12" sm="6") © 2024 Cardy.cloud
        v-col.text-center.text-sm-right(cols="12" sm="6")
          a.white--text.mr-10(v-if="$route.name !== 'ordering' && $route.name !== 'card' && $route.name !== 'faq'" @click="openRoute('datenschutz')") Datenschutz
          a.white--text(@click="openRoute('impressum')") Impressum

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    FormMixin
  ]
})
export default class Base extends Vue {
  get isLoggedIn () {
    return !!this.$store.getters.authToken
  }

  get isShowNav () {
    const routeName = this.$route.name
    return routeName !== 'card' && routeName !== 'ordering' && routeName !== 'datenschutz' && routeName !== 'impressum' && routeName !== 'faq'
  }

  onLogout () {
    this.$store.dispatch('logout')
      .then(() => {
        this.gotoRoute('home')
      })
  }
}
</script>

<style lang="sass">
.app-base
  .v-main
    height: 100vh
    overflow-y: auto
    overflow-x: hidden

  .v-main__wrap
    background-color: #f3f3f3

  &.home .v-main__wrap
    background-color: #fff

  .nav-bar
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 9
    background-color: #fff

    &:after
      content: ""
      position: absolute
      bottom: 0
      left: 0
      right: 0
      height: 3px
      background-image: linear-gradient(90deg, #ed6f7b 0%, #6363d5 100%)

  .main-container
    position: relative
    max-width: 2000px
    margin: auto
    padding: 0 60px

    @media screen and (max-width: 1240px)
      width: auto !important

  .footer
    align-items: center
    background-color: #1a176e
    color: #fff
    font-size: 120%
    line-height: 1
</style>
