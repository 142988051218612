var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"cardy-admin-home pa-6"},[_c('h2',{staticClass:"primary--text"},[_vm._v("Dashboard")]),(_vm.isSuperAdmin)?[_c('v-card',{staticClass:"admin-view pa-6 mt-5"},[_c('v-row',{staticClass:"sticky-card-header align-center mt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Offene Registrierungen")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.gotoRoute('register-filter')}}},[_vm._v("Zu den Registrierungen")])],1)],1),_c('v-data-table',_vm._b({staticClass:"mt-6",attrs:{"headers":_vm.registerheaders,"items":_vm.registers,"options":_vm.dataOptions},on:{"update:options":function($event){_vm.dataOptions=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.formatDateTime(value, 'DD.MM.YYYY HH:mm')))]}}],null,false,212978814)},'v-data-table',_vm.tableProps,false))],1),_c('v-card',{staticClass:"admin-view pa-6 mt-5"},[_c('v-row',{staticClass:"sticky-card-header align-center mt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Letzten Bestellungen")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.gotoRoute('order-list')}}},[_vm._v("Zu den Bestellungen")])],1)],1),_c('v-data-table',_vm._b({staticClass:"mt-6",attrs:{"headers":_vm.orderHeaders,"items":_vm.orders,"options":_vm.dataOptions,"hide-default-footer":""},on:{"update:options":function($event){_vm.dataOptions=$event}},scopedSlots:_vm._u([{key:"item.tenant_name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.tenant_name)+" "+_vm._s(item.tenant_name2))]}},{key:"item.is_delete",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : 'Nein'))]}},{key:"item.online_ready",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : 'Nein'))]}},{key:"item.validated",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : 'Nein'))]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.formatDateTime(value, 'DD.MM.YYYY HH:mm')))]}}],null,false,3303928463)},'v-data-table',_vm.tableProps,false))],1)]:_c('v-card',{staticClass:"pa-6 mt-5"},[_c('v-row',{staticClass:"sticky-card-header align-center mt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Neuste Ausweise")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.gotoRoute('card-list')}}},[_vm._v("Zu den Ausweisen")])],1)],1),_c('v-data-table',_vm._b({staticClass:"mt-6",attrs:{"headers":_vm.cardHeaders,"items":_vm.cards,"options":_vm.dataOptions,"hide-default-footer":""},on:{"update:options":function($event){_vm.dataOptions=$event}},scopedSlots:_vm._u([{key:"item.is_delete",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : "Nein"))]}},{key:"item.online_ready",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : "Nein"))]}},{key:"item.validated",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : "Nein"))]}},{key:"item.birthday",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.formatDateTime(value, 'DD.MM.YYYY')))]}}])},'v-data-table',_vm.tableProps,false))],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }