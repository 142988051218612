import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/auth',
      component: () => import('@/pages/auth/base'),
      meta: {
        authNeeded: false,
      },
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/pages/auth/login'),
        },
        {
          path: '/email_validate/:token',
          name: 'email-validate',
          component: () => import('@/pages/auth/email-validate'),
          props: true
        },
        {
          path: '/password_create/:token',
          name: 'set-password',
          component: () => import('@/pages/auth/password'),
          props: true
        },
        {
          path: '/forgot-password',
          name: 'forgot-password',
          component: () => import('@/pages/auth/forgot-password'),
        },
        {
          path: '/tenant',
          name: 'tenant',
          component: () => import('@/pages/auth/tenant'),
        },
        {
          path: '/info',
          name: 'info',
          component: () => import('@/pages/app-info'),
        },
      ]
    },
    {
      path: '',
      component: () => import('@/pages/base'),
      meta: {
        authNeeded: false,
        baseRoute: true
      },
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/pages/home'),
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('@/pages/register'),
        },
        {
          path: 's/:tenantShortCode/:productShortCode',
          name: 'ordering',
          component: () => import('@/pages/ordering'),
          props: true
        },
        {
          path: 'card/:tenantShortCode/:productShortCode/:userCode/:orderId',
          name: 'card',
          component: () => import('@/pages/card'),
          props: true
        },
        {
          path: 'datenschutz',
          name: 'datenschutz',
          component: () => import('@/pages/datenschutz'),
        },
        {
          path: 'kontakt',
          name: 'kontakt',
          component: () => import('@/pages/kontakt'),
        },
        {
          path: 'contact',
          name: 'contact',
          component: () => import('@/pages/kontakt'),
        },
        {
          path: 'impressum',
          name: 'impressum',
          component: () => import('@/pages/impressum'),
        },
        {
          path: 'faq',
          name: 'faq',
          component: () => import('@/pages/faq'),
        },
        {
          path: 'paypal_success/:orderId',
          name: 'paypal-success',
          component: () => import('@/pages/stripe/success'),
          props: true
        },
        {
          path: 'paypal_cancel/:orderId',
          name: 'paypal-cancel',
          component: () => import('@/pages/stripe/cancel'),
          props: true
        },
        {
          path: 'stripe_success/:orderId',
          name: 'stripe-success',
          component: () => import('@/pages/stripe/success'),
          props: true
        },
        {
          path: 'stripe_cancel/:orderId',
          name: 'stripe-cancel',
          component: () => import('@/pages/stripe/cancel'),
          props: true
        },
      ]
    },
    {
      path: '/admin',
      component: () => import('@/pages/admin/base'),
      meta: {
        authNeeded: true,
      },
      children: [
        {
          path: '',
          name: 'admin-home',
          component: () => import('@/pages/admin/home'),
        },
        {
          path: 'profile',
          name: 'my-profile',
          component: () => import('@/pages/admin/my-profile'),
        },
        {
          path: 'tenant',
          name: 'tenant-list',
          component: () => import('@/pages/admin/tenant/list'),
        },
        {
          path: 'tenant/add',
          name: 'tenant-add',
          component: () => import('@/pages/admin/tenant/details'),
        },
        {
          path: 'tenant/:id',
          name: 'tenant-edit',
          component: () => import('@/pages/admin/tenant/details'),
          props: true
        },
        {
          path: 'product',
          name: 'product-list',
          component: () => import('@/pages/admin/product/list'),
        },
        {
          path: 'product/add',
          name: 'product-add',
          component: () => import('@/pages/admin/product/details'),
        },
        {
          path: 'product/:id',
          name: 'product-edit',
          component: () => import('@/pages/admin/product/details'),
          props: true
        },
        {
          path: 'card',
          name: 'card-list',
          component: () => import('@/pages/admin/card/list'),
        },
        {
          path: 'card/:id/:fromArchive',
          name: 'card-edit',
          component: () => import('@/pages/admin/card/details'),
          props: true
        },
        {
          path: 'user',
          name: 'user-list',
          component: () => import('@/pages/admin/user/list'),
        },
        {
          path: 'user/add',
          name: 'user-add',
          component: () => import('@/pages/admin/user/details'),
        },
        {
          path: 'user/:id',
          name: 'user-edit',
          component: () => import('@/pages/admin/user/details'),
          props: true
        },
        {
          path: 'orders',
          name: 'order-list',
          component: () => import('@/pages/admin/order/list'),
        },
        {
          path: 'invoices',
          name: 'invoice-list',
          component: () => import('@/pages/admin/invoice/list'),
        },
        {
          path: 'listruns',
          name: 'run-list',
          component: () => import('@/pages/admin/invoice/list_runs'),
        },
        {
          path: 'filter-registers',
          name: 'register-filter',
          component: () => import('@/pages/admin/register/list'),
        },
        {
          path: 'report',
          name: 'report',
          component: () => import('@/pages/admin/report'),
        },
      ]
    },
    {
      path: '*',
      component: () => import('@/pages/not-found'),
    }
  ]
})

router.beforeEach((to, from, next) => {
  let timeNow = Math.floor(new Date() / 1000)
  let timeDiff = timeNow - (store.getters.lastTokenRefreshAt || 0)

  store.dispatch('setLastActionTime', timeNow)

  // refresh token if the token will be expired in 1 minutes
  if (Math.floor(timeDiff / 60) === store.getters.tokenExpireInMinutes - 1) {
    store.dispatch('refreshAuthToken')
  }

  if (!to.matched.some(record => record.meta.authNeeded)) {
    return next()
  }

  if (!store.getters.authToken || !store.getters.lastTokenRefreshAt) {
    next({
      name: 'login',
    })
  } else {
    next()
  }
})

router.afterEach((to) => {
  /* global gtag */
  gtag('config', window.GA_TRACKING_ID, {
    page_path: to.fullPath,
    app_name: 'cardy.cloud App',
    send_page_view: true,
  })
})

export default router
