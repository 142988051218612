<template lang="pug">
  .sign-pad(:class="readonly && 'readonly'")
    // span.placeholder(v-if="!value") Ihre Unterschrift
    span.field-label(:class="checkValid && !isValid() ? 'red--text' : 'primary--text'") {{ label }}
    canvas#sign-pad
    a.icon-clear(v-if="!readonly" title="Clear Signature" @click="clearPad"): v-icon(color="primary") md_close
    p.error-message(v-if="checkValid && !isValid()") Bitte einen Wert angeben

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import SignaturePad from 'signature_pad'

@Component({
  watch: {
    readonly: {
      handler () {
        if (!this.signaturePad) {
          return
        }
        if (this.readonly) {
          this.signaturePad.off()
        } else {
          this.signaturePad.on()
        }
      },
    }
  }
})
export default class NSignPad extends Vue {
  @Prop({ type: String, default: '' }) value
  @Prop({ type: String, default: '' }) label
  @Prop({ type: Boolean, default: false }) readonly
  @Prop({ type: Boolean, default: false }) required

  signaturePad = null
  checkValid = false

  mounted () {
    window.addEventListener('resize', this.resizeCanvas)

    this.$nextTick(() => {
      const canvas = document.getElementById('sign-pad')
      if (!canvas) {
        return
      }

      this.resizeCanvas()

      this.signaturePad = new SignaturePad(canvas)

      this.signaturePad.addEventListener("beginStroke", () => {
        this.onDrawBegin()
      })

      this.signaturePad.addEventListener("endStroke", () => {
        this.onDrawEnd()
      })

      if (this.readonly) {
        this.signaturePad.off()
      }

      if (!this.value) {
        return
      }

      const img = new Image()

      img.onload = () => {
        this.signaturePad.fromDataURL(this.value, {
          width: img.naturalWidth,
          height: img.naturalHeight
        })
      }
      img.src = this.value
    })
  }

  isValid () {
    if (!this.required) {
      return true
    }

    this.checkValid = true
    return !!this.value
  }

  resizeCanvas () {
    const canvas = document.getElementById('sign-pad')
    if (!canvas) {
      return
    }

    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d').scale(ratio, ratio)

    if (this.signaturePad) {
      this.signaturePad.clear()
    }
  }

  onDrawBegin () {
    // console.log('begin draw')
  }

  onDrawEnd () {
    this.$emit('input', this.signaturePad.toDataURL())
  }

  clearPad () {
    if (this.signaturePad) {
      this.signaturePad.clear()
      this.$emit('input', null)
      this.$emit('clear')

      if (this.readonly) {
        this.signaturePad.off()
      } else {
        this.signaturePad.on()
      }
    }
  }
}
</script>

<style lang="sass">
.sign-pad
  position: relative
  width: 100%
  height: 35vh
  max-height: 220px
  padding: 16px 0

  &.readonly
    pointer-events: none

  #sign-pad
    border: 1px solid #707070
    border-radius: 10px
    width: 100%
    height: 100%

  .placeholder
    position: absolute
    left: 40px
    top: 30px
    font-size: 30px
    font-weight: normal
    font-style: italic
    color: #959595
    pointer-events: none

  .field-label
    position: absolute
    top: -4px
    left: 0
    font-size: 14px

  .icon-clear
    position: absolute
    top: 16px
    right: 0
    padding: 5px

  .error-message
    position: absolute
    bottom: 0
    left: 0
    color: #ff5252
    font-size: 12px
    line-height: 1
    margin: 0
</style>
