import apiCall from '@/utils/api-call'
import Urls from '@/router/urls'

export default {
  clearAuthToken ({ commit }) {
    commit('SET_AUTH_TOKEN', null)
  },

  refreshAuthToken ({ commit, getters }) {
    if (!getters.authToken) {
      return false
    }

    return apiCall.post(Urls.Auth.Refresh)
      .then(res => {
        commit('SET_AUTH_TOKEN', res.data.token)
        commit('SET_LAST_REFRESH_TIME', Math.round(new Date() / 1000))
      })
  },

  login ({ commit }, params) {
    return apiCall.post(Urls.Auth.Login, params)
      .then(res => {
        commit('SET_USER_DATA', res.data)

        if (res.data.token) {
          commit('SET_LAST_REFRESH_TIME', Math.round(new Date() / 1000))
          commit('SET_AUTH_TOKEN', res.data.token)
          commit('SET_TOKEN_EXPIRE_IN_MINUTES', res.data.jwt_token_duration_minutes)
        }

        if (res.data.is_super_admin) {
          return true
        }

        if (res.data.tenants && res.data.tenants.length === 1) {
          const tenant = res.data.tenants[0]
          commit('SELECT_TENANT', tenant)
          commit('SET_USER_GROUP', tenant.user_group)
          return true
        }

        return false
      })
  },

  logout ({ commit }) {
    commit('SET_AUTH_TOKEN', null)
    commit('SET_LAST_REFRESH_TIME', 0)
    commit('SET_LAST_ACTION_TIME', 0)
    commit('SELECT_TENANT', null)
    commit('RESET_STATE')
  },

  setLastActionTime ({ commit }, payload) {
    commit('SET_LAST_ACTION_TIME', payload)
  },

  setUserGroup ({ commit }, payload) {
    commit('SET_USER_GROUP', payload)
  },

  updateUserData ({ commit }, payload) {
    commit('UPDATE_USER_DATA', payload)
  }
}
