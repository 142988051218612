export default {
  authToken: (state) => state.authToken,
  lastActionAt: (state) => state.lastActionAt,
  lastTokenRefreshAt: (state) => state.lastTokenRefreshAt,
  tokenExpireInMinutes: (state) => state.tokenExpireInMinutes,
  userData: (state) => state.userData,
  userGroup: (state) => state.userGroup || '',
  userName: (state) => {
    return state.userData
      ? `${ state.userData.forename } ${ state.userData.lastname }`
      : ''
  },
}
