<template lang="pug">
  v-app.cardy-root(:class="isUserPage && 'user-based'")
    router-view
    vue-snotify

    confirm-dialog(ref="confirm-dialog")

</template>

<script>
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'

@Component({
  computed: mapGetters([
    'authToken',
    'lastActionAt',
    'lastTokenRefreshAt',
    'tokenExpireInMinutes',
  ]),

  methods: mapActions([
    'logout'
  ]),
})
export default class App extends Vue {
  @Watch('$route')
  onRouteChange (to, from) {
    if (!this.authToken && to && to.meta && !!to.meta.authNeeded) {
      if (this.$route.name !== 'login') {
        return this.$router.push({
          name: 'login'
        })
      }
    }
  }

  // @Watch('authToken')
  // onUpdateAuthToken (token) {
  //   if (!token && this.$route.name !== 'login') {
  //     return this.$router.push({
  //       name: 'login'
  //     })
  //   }
  // }

  authTimer = null

  get isUserPage () {
    return this.$route.matched.some(record => record.meta.baseRoute)
  }

  mounted () {
    const appUrl = location.href
    if (appUrl && appUrl.indexOf('#') >= 0) {
      location.href = appUrl.replace('/#', '')
    }
    this.authTimer = setInterval(() => {
      this.checkLoginState()
    }, 1000)

    this.$nextTick(() => {
      Vue.prototype.$confirmDialog = this.$refs['confirm-dialog']
    })
  }

  beforeDestroy () {
    if (this.authTimer) {
      clearInterval(this.authTimer)
    }
  }

  checkLoginState () {
    if (!this.authToken || !this.$route.matched.find(r => r.meta.authNeeded)) {
      return
    }

    const timeNow = Math.floor(new Date() / 1000)
    const refreshTimeDiff = timeNow - (this.lastTokenRefreshAt || 0)
    const actionTimeDiff = timeNow - (this.lastActionAt || 0)

    if (
      (refreshTimeDiff / 60) >= this.tokenExpireInMinutes ||
      (actionTimeDiff / 60) >= this.tokenExpireInMinutes
    ) {
      console.log('time out!!!')
      this.logout()
    }
  }
}
</script>
