const formatNumber = (value, decimals = 0) => {
  value = parseFloat(value)

  if (value === null || value === undefined || isNaN(value)) {
    return ''
  }

  const fixed = value.toFixed(decimals)

  const decimal = decimals !== 0
    ? `,${ fixed.slice(-decimals) }`
    : ''

  const formatted = (Math.abs(Math.trunc(fixed)) + '.')
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1.')
    .slice(0, -1) + decimal

  return value >= 0
    ? formatted
    : `-${ formatted }`
}

const roundNumber = (value, decimals = 0) => {
  const p = Math.pow(10, decimals)
  return Math.round(value * p) / p
}

export {
  formatNumber,
  roundNumber,
}
