import config from '@/config'
import store from '@/store'

export default {
  companies: state => state.companies || [],
  getPageFilters: (state) => (pageId) => {
    const filters = state.filters || {}
    if (!filters[pageId]) {
      return {}
    }

    const filterExpires = state.filter_expires || {}
    if (!filterExpires[pageId]) {
      return filters[pageId]
    }

    const pageFilterExpire = filterExpires[pageId]
    const timeDiff = (Math.floor(new Date() / 1000) - pageFilterExpire) / 60
    if (timeDiff > config.FILTER_EXPIRES) {
      store.dispatch('resetPageFilter', pageId)
      return {}
    }

    return filters[pageId]
  },
  orderData: (state) => state.orderData,
  sidebarVisible: state => state.sidebarVisible,
  selectedTenant: state => state.selectedTenant,
  isLoading: state => state.isLoading === true,
}
