export default {
  SET_AUTH_TOKEN (state, payload) {
    state.authToken = payload
  },

  SET_USER_DATA (state, payload) {
    state.userData = payload
  },

  SET_USER_GROUP (state, payload) {
    state.userGroup = payload
  },

  SET_LAST_REFRESH_TIME (state, payload) {
    state.lastTokenRefreshAt = payload
  },

  SET_LAST_ACTION_TIME (state, payload) {
    state.lastActionAt = payload
  },

  SET_TOKEN_EXPIRE_IN_MINUTES (state, payload) {
    state.tokenExpireInMinutes = payload
  },

  UPDATE_USER_DATA (state, payload) {
    Object.keys(payload).forEach(k => {
      state.userData[k] = payload[k]
    })
  }
}
