<template lang="pug">
  label.info-tooltip(:class="[ showTooltip ? 'active' : 'deactive', `${ align }-aligned` ]")
    a(@click.stop.prevent="showTooltip = !showTooltip"): v-icon.icon-info info

    .hint-text(@click.stop="() => {}")
      slot(name="default")
      a(@click.stop="showTooltip = false"): v-icon.icon-delete clear

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class NInfoTooltip extends Vue {
  @Prop({ type: String, default: 'left' }) align

  showTooltip = false

  mounted () {
    window.addEventListener('click', () => {
      this.showTooltip = false
    })
  }
}
</script>

<style lang="scss">
.info-tooltip {
  position: relative;

  .hint-text {
    position: absolute;
    left: 0;
    margin-top: 5px;
    background-color: #888;
    color: #fff;
    width: auto;
    min-width: 240px;
    padding: 1rem 1rem;
    text-align: left;
    font-size: 1rem;
    line-height: 140%;
    display: none;
    white-space: pre-wrap;
    z-index: 8;

    &:after {
      position: absolute;
      content: " ";
      top: -7px;
      left: 4px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent #888 transparent;
    }
  }

  &.active {
    .hint-text {
      display: block;
    }
  }

  &.deactive {
    .hint-text {
      display: none !important;
    }
  }

  .icon-delete {
    position: absolute;
    font-size: 18px;
    top: 4px;
    right: 4px;
    color: #fff;
  }

  &.center-aligned {
    .hint-text {
      left: 12px;
      transform: translateX(-50%);

      &:after {
        left: calc(50% - 8px);
      }
    }
  }

  @media screen and (min-width: 420px) {
    .hint-text {
      min-width: 300px;
    }
  }
}
</style>
