<template lang="pug">
  .pa-10
    h1 Page Not Found
    p {{ url }}

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

@Component({

})
export default class Home extends Vue {
  url = ''

  mounted () {
    this.url = location.href
  }
}
</script>
