<template lang="pug">
  v-card.pa-6
    h2.primary--text Bestellungen

    v-row.my-3
      v-col(cols="12" sm="4" lg="3"): n-date-picker(
        v-model="filter.date_from"
        label="Von"
        format="YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
        clearable
        :input-rules="[ rules.valid_date() ]"
        @input="isDateInvalid = false"
        @invalid="isDateInvalid = true"
      )

      v-col(cols="12" sm="4" lg="3"): n-date-picker(
        v-model="filter.date_to"
        label="Bis"
        format="YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
        clearable
        :input-rules="[ rules.valid_date() ]"
        @input="isDateInvalid = false"
        @invalid="isDateInvalid = true"
      )

      v-col(cols="12" sm="4" lg="3")
        v-btn(color="primary" :disabled="isDateInvalid" @click="onFilterOrders") Filtern

    template(v-if="reportData")
      .report-table.mt-5
        table
          tr
            td Summe aller bezahlten Bestellungen (pF.)
            td {{ reportData.count_all_payed || 0 }}
            td(colspan="4")

          tr
            td Betrag aller bezahlten Bestellungen (pF.)
            td {{ reportData.sum_all_payed || 0 }}
            td(colspan="4")

          tr
            td Summe aller Bestellungen (ges.)
            td {{ reportData.count_all || 0 }}
            td(colspan="4")

          template(v-for="(c, i) in countryOrders")
            tr
              td(colspan="6") &nbsp;

            tr
              td Daten für Land: {{ c.country }}
              td(colspan="5")

            tr
              td
              td IsOnline
              td IsHardcopy
              td TenantPayee
              td Anzahl
              td Summe Betrag

            tr(v-for="(d, i) in c.data")
              td
              td {{ getBooleanText(d.IsOnline) }}
              td {{ getBooleanText(d.IsHardcopy) }}
              td {{ getBooleanText(d.TenantPayee) }}
              td {{ d.Count }}
              td {{ d.PriceSum }}

      v-btn.mt-5(color="primary" @click="onDownloadOrders") Bestellungen herunterladen
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import FormMixin from '@/mixins/form'
import Urls from '@/router/urls'
import { downloadBlob } from '@/utils/download'

@Component({
  components: {
    NDatePicker: () => import('@/components/common/n-date-picker')
  },

  mixins: [
    FormMixin
  ]
})
export default class Report extends Vue {
  filter = {
    date_from: '',
    date_to: '',
    as_excel: true,
  }

  reportData = null
  countryOrders = []
  isDateInvalid = false

  getBooleanText (value) {
    return value ? 'X' : '-'
  }

  onFilterOrders () {
    if (this.isDateInvalid) {
      return
    }

    this.filter.as_excel = false
    this.$axios
      .get(Urls.InvoiceOrders.Report, this.filter)
      .then(res => {
        this.reportData = res.data || {}

        const values = this.reportData.values || {}
        this.countryOrders = Object.keys(values).map(k => ({
          country: k,
          data: values[k].data || [],
          sum_orders: values[k].sum_orders || 0,
          sum_orders_all: values[k].sum_orders_all || 0,
          sum_price: values[k].sum_price || 0,
        }))
      })
  }

  onDownloadOrders () {
    if (this.isDateInvalid) {
      return
    }

    this.filter.as_excel = true
    this.$axios
      .get(Urls.InvoiceOrders.Report, this.filter, true)
      .then(res => {
        downloadBlob(res.data, this.getFileNameFromHeader(res.headers))
      })
      .catch(err => {
        this.$toast.error('Fehler', err.message || 'Failed to download card')
      })
  }
}
</script>

<style lang="scss">
.report-table {
  width: 100%;
  overflow: auto;

  table {
    border-collapse: collapse;
    min-width: 650px;
    width: 100%;

    tr td {
      border: 1px solid #d5d5d5;
      padding: 4px 8px;
      text-align: center;

      &:first-child {
        text-align: left;
        width: 500px;
      }
    }
  }
}
</style>
