<template lang="pug">
  .card-details
    .action-header
      v-btn.admin-button.mr-4(v-if="isSuperAdmin" color="primary" @click="downloadCard(id)") Ausweis herunterladen
      v-btn(v-if="!fromArchive && card && !card.is_delete" color="primary" @click="showDeleteConfirm = true") Ausweis Löschen
      v-btn(v-else-if="!fromArchive && isUndeleteable" color="primary" @click="onUndeleteCard") Ausweis Wiederherstellen
      v-btn.ml-2(v-if="!fromArchive && isUpdated" color="primary" :loading="loading === 'update'" @click="onSaveCard") Speichern

    delete-dialog(
      :value="showDeleteConfirm"
      :loading="loading === 'delete'"
      @ok="onDeleteCard"
      @close="showDeleteConfirm = false"
    )
      label
        | Sind Sie sicher, dass der Ausweis gelöscht werden soll?<br><br>
        | ACHTUNG: Der Ausweis wird dadurch auch in der Card-App des Benutzers entfernt (falls dort vorhanden).<br><br>

    v-card.pa-6: v-form(v-if="card" ref="form-card" lazy-validation)
      v-row.align-center
        v-col(sm="6")
          h2.primary--text(v-if="!fromArchive") Ausweis bearbeiten
          h2.primary--text(v-if="fromArchive") Ausweis betrachten (ARCHIV)

      v-row.mt-3.mb-6
        v-col(cols="12" sm="6"): v-row
          v-col(cols="12"): h4.primary--text Produktdetails

          v-col.pb-0(cols="12"): v-text-field(
            v-model="card.id"
            label="ID"
            dense
            outlined
            disabled
          )

          v-col.pb-0(cols="12"): v-text-field(
            v-model="card.product_type"
            label="Produkt Typ"
            dense
            outlined
            disabled
          )

          v-col.pt-0(cols="12"): v-text-field(
            v-model="card.tenant_short_code"
            label="Zugangscode"
            dense
            outlined
            disabled
          )

          v-col.pt-0(cols="12"): v-text-field(
            v-model="card.product_short_code"
            label="Produktcode"
            dense
            outlined
            disabled
          )

        v-col.text-center(cols="12" sm="6")
          h4.primary--text Ausweis-Foto hochladen
          student-photo.mt-6(
            :file="studentImage"
            :disabled="card.is_delete"
            @reset="card.image_file = null"
            @select="onSelectImage"
          )

        v-col(cols="12"): h4.primary--text Bestellerdaten

        v-col.pb-0(cols="12" sm="6"): v-text-field(
          v-model="card.email"
          label="E-Mail"
          dense
          outlined
          :disabled="card.is_delete"
          :rules="[ rules.required, rules.email ]"
        )

        v-col.pb-0(cols="12" sm="6"): v-text-field(
          v-model="card.familyname"
          label="Nachname"
          dense
          outlined
          :disabled="card.is_delete"
          :rules="[ rules.required ]"
        )

        v-col.pb-0(cols="12" sm="6"): v-text-field(
          v-model="card.forname"
          label="Vorname"
          dense
          outlined
          :disabled="card.is_delete"
          :rules="[ rules.required ]"
        )

        v-col.pb-0(cols="6" sm="3"): v-text-field(
          v-model="card.birthday"
          label="Geburtstag"
          dense
          outlined
          :disabled="card.is_delete"
          :rules="[ rules.required, rules.valid_date() ]"
        )

        v-col.pb-0(cols="6" sm="3"): v-text-field(
          v-model="card.created_at"
          label="Bestelldatum"
          dense
          outlined
          :disabled="true"
        )

        v-col.pb-0(cols="12" sm="6"): v-text-field(
          v-model="card.external_id"
          :label="card.external_id_name"
          dense
          outlined
          :disabled="card.is_delete"
          :rules="[ card.need_external_id ? rules.required : true ]"
        )

        v-col.pb-0(cols="12" sm="6"): v-text-field(
          v-model="card.external_department"
          :label="card.external_department_name"
          dense
          outlined
          :disabled="card.is_delete"
          :rules="[ card.need_external_department ? rules.required : true ]"
        )

        v-col.pb-0(cols="12" sm="6"): v-text-field(
          :value="card.card_valid_from"
          label="Gültig ab"
          dense
          outlined
          :disabled="!isSuperAdmin || card.is_delete"
          :rules="[ rules.required, rules.valid_date() ]"
          @change="onChanageValidFrom"
        )

        v-col.pb-0(cols="12" sm="6")
          v-text-field(
            v-model="card.card_valid_to"
            label="Gültig bis"
            dense
            outlined
            :disabled="!isSuperAdmin || card.is_delete"
            :rules="[ rules.required, rules.valid_date() ]"
          )
          label.max-date-hint.admin-view(v-if="isSuperAdmin") Das Datum sollte nach Produkt-Konfiguration vor dem {{ maxDate }} liegen

        template(v-if="card.tenant_product.with_lable")
          v-col(cols="12"): h4.primary--text Bezeichner

          v-col.pb-0(cols="12" sm="3")
            v-checkbox.mt-0(
              v-model="card.with_lable"
              label="Kennzeichen?"
              hide-details
            )
          v-col.pb-0(cols="12" sm="3")
            v-text-field(
              v-if="card.with_lable"
              v-model="card.lable_value"
              label="Kennzeichen Zeichen"
              dense
              outlined
              :rules="[ rules.maxLen(2), card.with_lable ? rules.required : true ]"
            )
          v-col.pb-0(cols="12" sm="3")
            v-text-field(
                v-if="card.with_lable"
                v-model="card.lable_color"
                label="Kennzeichen Farbe"
                dense
                outlined
                :rules="[ card.with_lable ? rules.required : true ]"
            )

        v-col(cols="12"): h4.primary--text Mobile Information

        v-col.pb-0(cols="12" sm="3"): v-text-field(
          :value="formatDateTime(card.mobile_loaded_at, 'DD.MM.YYYY HH:mm:ss')"
          label="Heruntergeladen am"
          dense
          outlined
          disabled
        )

        v-col.pb-0(cols="12" sm="3"): v-text-field(
          :value="formatDateTime(card.mobile_updated_at, 'DD.MM.YYYY HH:mm:ss')"
          label="Aktualisiert am"
          dense
          outlined
          disabled
        )
        v-col.pb-0(cols="12" sm="3"): v-text-field(
          :value="formatDateTime(card.mobile_checked_at, 'DD.MM.YYYY HH:mm:ss')"
          label="Überprüft am"
          dense
          outlined
          disabled
        )

        v-col(cols="12"): h4.primary--text Status
        v-col.pb-0(cols="12" sm="3" md="2" xl="1"): v-checkbox.mt-0(
          v-model="card.validated"
          label="Validiert"
          hide-details
          :disabled="card.is_delete"
        )

        v-col.pb-0(v-if="card.validated && card.validated_from && isSuperAdmin" cols="12" sm="3" md="2"): v-text-field.admin-input(
          v-model="card.validated_from"
          label="Validiert von"
          dense
          outlined
          disabled
        )

        v-col.pb-0(cols="12" sm="3" md="2"): v-text-field(
          v-if="card.validated"
          :value="formatDateTime(card.validated_at, 'DD.MM.YYYY')"
          label="Validiert am"
          dense
          outlined
          disabled
        )

      v-row
        v-col(cols="12" sm="3" md="2" xl="1"): v-checkbox.mt-0.mb-2(
          v-model="card.is_delete"
          label="Gelöscht?"
          hide-details
          disabled
        )

        v-col.pb-0(cols="12" sm="3" md="2"): v-text-field.admin-input(
          v-if="card.is_delete && card.delete_from && isSuperAdmin"
          v-model="card.delete_from"
          label="Gelöscht von"
          dense
          outlined
          disabled
        )

        v-col.pb-0(cols="12" sm="3" md="2"): v-text-field(
          v-if="card.is_delete"
          :value="formatDateTime(card.delete_at, 'DD.MM.YYYY')"
          label="Gelöscht am"
          dense
          outlined
          disabled
        )

      v-row
        v-col.pb-0(cols="12" sm="3" md="2" xl="1"): v-checkbox.mt-0(
          v-if="card.is_online"
          v-model="card.online_ready"
          label="Online-Ready?"
          hide-details
          disabled
        )

        v-col.pb-0(cols="12" sm="3" md="2"): v-text-field(
          v-if="card.online_code || isSuperAdmin"
          v-model="card.online_code"
          label="Online-Code"
          dense
          outlined
          disabled
        )

        v-col.pb-0(cols="12" sm="3" md="3")
          v-btn.mb-3(
            v-if="!fromArchive && !isUpdated && card.online_ready && card.is_online"
            color="primary"
            :loading="loading === 'resend'"
            @click="onResendOnlineCode"
          ) Code erneut schicken

      v-row
          v-col(cols="12"): h4.primary--text Erweiterte Daten

          v-col.pb-0(v-if="isSuperAdmin" cols="12" sm="6"): v-text-field.admin-input(
            v-model="card.tenant_product_id"
            label="Mandanten Produkt-ID"
            dense
            outlined
            disabled
          )

          v-col.pb-0(cols="12" sm="6" md="3"): v-checkbox.mt-0(
            v-model="card.is_online"
            label="Ist Online?"
            hide-details
            :disabled="card.is_delete||!isSuperAdmin"
            :readonly="!isSuperAdmin"
          )

          v-col.mb-2(cols="12" sm="6" md="3"): v-checkbox.mt-0(
            v-model="card.is_hardcopy"
            label="Ist Karte?"
            hide-details
            :disabled="card.is_delete||!isSuperAdmin"
            :readonly="!isSuperAdmin"
          )

          v-col(cols="12"): v-row.admin-view(v-if="isSuperAdmin")
            v-col.pb-0(cols="12" sm="6"): v-text-field(
              v-model="card.status"
              label="Status"
              dense
              outlined
              disabled
            )

            v-col.mb-2(cols="12" sm="6" md="3"): v-checkbox.mt-0(
              v-model="card.is_test"
              label="Ist Test?"
              hide-details
              :disabled="card.is_delete"
            )

            v-col.mb-2(cols="12" sm="6" md="3"): v-checkbox.mt-0(
              v-model="card.is_payed"
              label="Ist Bezahlt?"
              hide-details
              :disabled="card.is_delete || card.status === 'payed'"
            )

            v-col.pb-0(cols="12" sm="6" md="4" lg="2"): v-text-field(
              v-model="card.price"
              label="Preis"
              dense
              outlined
              disabled
            )

            v-col.pb-0(cols="12" sm="6" md="4" lg="2"): v-text-field(
              :value="formatDateTime(card.exported_at, 'DD.MM.YYYY HH:mm:ss')"
              label="(Druck) Exportiert am"
              dense
              outlined
              disabled
            )

            v-col.pb-0(cols="12" sm="6" md="4" lg="2")
              v-text-field(
                :value="formatDateTime(card.exported_card_loaded_at, 'DD.MM.YYYY HH:mm:ss')"
                label="(Druck) Bild-Exportiert am"
                dense
                hide-details
                outlined
                disabled
              )
              v-btn.mt-3(v-if="!fromArchive" block color="primary" @click="onResetExport") Export zurücksetzen

            template(v-if="card.tenant_payment")
              v-col.mb-2(cols="12" sm="3" md="2"): v-checkbox.mt-0(
                v-model="card.tenant_payment"
                label="Mandaten-Zahlung"
                hide-details
                disabled
              )
              v-col.mb-2(cols="12" sm="3" md="2"): v-checkbox.mt-0(
                v-model="card.tenant_payee_billed"
                label="Abgerechnet"
                hide-details
                disabled
              )
              v-col.mb-2(cols="12" sm="6" md="4"): v-text-field(
                v-model="card.payment_run_id"
                label="LaufID"
                dense
                outlined
                disabled
              )

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

import Urls from '@/router/urls'
import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    AuthMixin,
    FormMixin
  ],

  components: {
    DeleteDialog: () => import('@/components/common/delete-dialog'),
    StudentPhoto: () => import('@/components/common/student-photo'),
  },

  watch: {
    card: {
      deep: true,
      handler () {
        this.isUpdated = this.dataLoaded
      }
    }
  }
})
export default class CardDetails extends Vue {
  @Prop({ type: [ Number, String ], default: 0 }) id
  @Prop({ type: [ Boolean ], default: false }) fromArchive

  card = null
  studentImage = null
  dataLoaded = false
  isUpdated = false

  showDeleteConfirm = false
  loading = null

  get maxDate () {
    if (!this.card || !this.card.card_valid_from) {
      return ''
    }

    return this.getDateFromOffset(this.card.card_valid_from, 'DD.MM.YYYY', this.card.max_years_valid || 0, 'years')
  }

  get isUndeleteable () {
    if (!this.card || !this.card.card_valid_to) {
      return ''
    }

    const today = this.formatDateTime(new Date(), 'DD.MM.YYYY')
    return this.isAfter(this.card.card_valid_to, today)
  }

  mounted () {
    this.dataLoaded = !this.id
    this.isUpdated = !this.id
    this.loadCardDetails()
    this.loadCardImage()
  }

  loadCardDetails () {
    if (!this.id) {
      return
    }

    this.$axios
      .get(Urls.Card.Details, {
        order_id: this.id,
        from_archive: this.fromArchive,
      })
      .then(res => {
        this.card = res.data
        this.card.birthday = this.formatDateTime(this.card.birthday, 'DD.MM.YYYY')
        this.card.card_valid_from = this.formatDateTime(this.card.card_valid_from, 'DD.MM.YYYY')
        this.card.card_valid_to = this.formatDateTime(this.card.card_valid_to, 'DD.MM.YYYY')
        this.card.is_payed = this.card.online_ready || this.card.status === 'payed' || this.card.status === 'added_to_pdf'
        this.card.external_id_name = res.data.external_id_name || 'Externe-ID'
        this.card.external_department_name = res.data.external_department_name || 'Externe Abteilung'
        this.card.tenant_payment = this.card.tenant_payee
        this.card.created_at = this.formatDateTime(this.card.created_at, 'DD.MM.YYYY HH:mm:ss')

        this.card = {
          ...this.card
        }
        if (this.card.tenant_product.with_lable && !this.card.lable_value) {
            this.card.lable_value = this.card.tenant_product.lable_value
            this.card.lable_color = this.card.tenant_product.lable_color
        }
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.dataLoaded = true
        this.isUpdated = false
      })
  }

  loadCardImage () {
    const params = {
      order_id: parseInt(this.id),
      hash: this.getHashString(),
      from_archive: this.fromArchive,
    }

    return this.$axios
      .get(Urls.Card.GetImage, params, true)
      .then(res => {
        this.studentImage = window.URL.createObjectURL(res.data)
      })
      .catch(err => {
        this.$toast.error('Fehler', err.message)
      })
  }

  onSelectImage (v) {
    this.card.image_file = v
    this.isUpdated = true
  }

  onBezeichnerChanged (v) {
    this.isUpdated = true
  }

  onChanageValidFrom (v) {
    this.card.card_valid_from = v
    if (!this.maxDate) {
      return
    }

    if (!this.card.card_valid_to) {
      this.card.card_valid_to = this.maxDate
    } else if (this.isAfter(this.card.card_valid_to, this.maxDate)) {
      this.card.card_valid_to = ''
    }

    this.card = {
      ...this.card
    }
  }

  onSaveCard () {
    if (!this.isFormValid('form-card')) {
      return
    }

    const formData = new FormData()

    formData.append('order_id', parseInt(this.id))
    formData.append('email', this.card.email)
    formData.append('familyname', this.card.familyname)
    formData.append('forname', this.card.forname)
    if (this.card.with_lable){
        formData.append('with_lable', this.card.with_lable)
        formData.append('lable_value', this.card.lable_value)
        formData.append('lable_color', this.card.lable_color)
    }

    formData.append('birthday', this.changeDateFormat(this.card.birthday))
    if (this.card.external_id !== undefined && this.card.external_id !== null) {
      formData.append('external_id', this.card.external_id)
    }
    if (this.card.external_department !== undefined && this.card.external_department !== null) {
      formData.append('external_department', this.card.external_department)
    }
    formData.append('validated', this.card.validated)
    if (this.card.card_valid_from) {
      formData.append('card_valid_from', this.changeDateFormat(this.card.card_valid_from))
    }
    if (this.card.card_valid_to) {
      formData.append('card_valid_to', this.changeDateFormat(this.card.card_valid_to))
    }
    if (this.card.image_file) {
      formData.append('image_file', this.card.image_file)
    }
    if (this.isSuperAdmin) {
      formData.append('is_hardcopy', this.card.is_hardcopy || false)
      formData.append('is_online', this.card.is_online || false)
      formData.append('is_test', this.card.is_test || false)
      formData.append('is_payed', this.card.is_payed || false)
      formData.append('tenant_payment', this.card.tenant_payment || false)
    }

    this.loading = 'update'
    this.$axios
      .post(Urls.Card.Update, formData, true)
      .then(() => {
        this.isUpdated = false
        this.$toast.success('Daten gespeichert', 'Speichern')
        this.loadCardDetails()
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.loading = null
      })
  }

  onDeleteCard () {
    const formData = new FormData()

    formData.append('order_id', parseInt(this.id))
    this.loading = 'delete'
    this.$axios
      .delete(Urls.Card.Delete, formData, false)
      .then(() => {
        this.$toast.success('Ausweis gelöscht', 'Löschen')
        this.showDeleteConfirm = false
        this.loadCardDetails()
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.loading = null
      })
  }

  onUndeleteCard () {
    const formData = new FormData()

    formData.append('order_id', parseInt(this.id))
    this.$axios
      .post(Urls.Card.Undelete, formData, false)
      .then(() => {
        this.$toast.success('Ausweis wiederhergestellt', 'Wiederherstellen')
        this.loadCardDetails()
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
  }

  onResendOnlineCode () {
    this.loading = 'resend'
    this.$axios
      .post(Urls.Card.ResendOnlineCode, {
        order_id: parseInt(this.id)
      })
      .then(() => {
        this.$toast.success('E-Mail mit Zugangsdaten wurde an den Benutzer gesendet', 'Erfolg')
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.loading = null
      })
  }

  onResetExport () {
    this.$confirmDialog.show({
      text: 'Sind Sie sicher, dass Sie den Export zurücksetzen wollen?<br>Der Ausweis wird dadurch erneut gedruckt und versendet!',
      handleOk: () => {
        this.$axios
          .post(Urls.Card.ResetExport, {
            order_id: parseInt(this.id)
          })
          .then(() => {
            this.$toast.success('Export zurücksetzen', 'Erfolg')
            this.loadCardDetails()
            this.$confirmDialog.close()
          })
          .catch(err => {
            this.$toast.error(err.message, 'Fehler')
          })
      }
    })
  }
}
</script>
