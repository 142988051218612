var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"product-list pa-6"},[_c('v-row',{staticClass:"align-center mt-4"},[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Liste Produkte")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.gotoRoute('product-add')}}},[_vm._v("+ Neues Produkt konfigurieren")])],1)],1),_c('custom-table',{staticClass:"scrolling-table mt-3",attrs:{"page-id":"products","headers":_vm.headers,"table-data":_vm.tableData},on:{"load":function (e) { return _vm.loadProducts(e); }},scopedSlots:_vm._u([{key:"item.can_hardcopy",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : "Nein"))]}},{key:"item.card_valid_from",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.formatDateTime(value, 'DD.MM.YYYY')))]}},{key:"item.card_valid_to",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.formatDateTime(value, 'DD.MM.YYYY')))]}},{key:"item.is_disabled",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : "Nein"))]}},{key:"item.is_test",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : "Nein"))]}},{key:"item.#",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-end"},[_c('a',{staticClass:"mr-1",attrs:{"title":"Produkt bearbeiten"},on:{"click":function($event){$event.preventDefault();return _vm.onEditProduct(item)}}},[_c('v-icon',[_vm._v("edit")])],1),(_vm.isSuperAdmin)?_c('a',{staticClass:"admin-view",attrs:{"title":"Zur Bestellseite"},on:{"click":function($event){$event.preventDefault();return _vm.gotoOrderPage(item.product_short_code)}}},[_c('v-icon',[_vm._v("link")])],1):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }