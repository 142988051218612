<template lang="pug">
  .cardy-faq.page-section
    .form-card: .card-content.pa-5.pa-xl-10
      h1 FAQ

      v-expansion-panels.mt-4(v-model="panel" accordion multiple)
        v-expansion-panel(v-for="(faq, i) in faqs" :key="i")
          v-expansion-panel-header.sub-heading {{ faq.question }}
          v-expansion-panel-content
            span.pre-wrap {{ faq.content }}

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

@Component({
})
export default class Impressum extends Vue {
  panel = []

  faqs = [
    {
      question: 'Wie bekomme ich meinen Ausweis auf mein Smartphone',
      content: `Nach der Bestellung erhalten Sie die Zugangsdaten für die Smartphone-App via Mail.\n\n` +
        `Durch klicken des blauen "+" Symbols in der CARDY App, können Sie den Ausweis eintragen.\n` +
        `Geben Sie hierzu den Zugangscode, den Produktcode und den Freischaltcode aus der E-Mail an und klicken Sie "+Hinzufügen"\n\n` +
        `Der Ausweis wird anschließend in der App angezeigt.`

    },
  ]
}
</script>

<style lang="scss">
.cardy-faq {
  .v-expansion-panel-header {
    font-size: inherit;
  }

  .pre-wrap {
    white-space: pre-wrap;
  }
}
</style>
