<template lang="pug">
  v-dialog(v-model="value_" :width="width")
    svg#progres-rectangle(v-if="confirmTimer !== null" viewBox="0 0 100 100" preserveAspectRatio ="none")
      path#rect-path(
        d="M0,0L100,0 100,100 0,100 0,0"
        fill="none"
        stroke="red"
        stroke-width="50"
        vector-effect="non-scaling-stroke"
        :style="`stroke-dashoffset: ${ dashOffset }`"
      )

    v-card.primary--text
      v-card-title(v-if="title") {{ title }}

      v-card-text.primary--text.mt-5
        slot(name="default")

      v-card-actions.pa-5
        v-btn.btn-no-shadow(
          v-if="seconds"
          color="secondary"
          :disabled="disabled"
          :loading="loading"
          large
          @mousedown="onStartConfirming"
          @mouseup="onEndConfirming"
        )
          span.primary--text {{ Math.ceil(currentSecond) }} sekunden drücken {{ buttonText }}

        v-btn.btn-no-shadow(
          v-else
          color="secondary"
          :disabled="disabled"
          :loading="loading"
          large
          @click="$emit('ok')"
        )
          span.primary--text Ja

        v-spacer

        v-btn(color="secondary" large outlined @click="onClose")
          span.primary--text Nein

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  watch: {
    value () {
      this.currentSecond = this.seconds
      this.confirmTimer = null
    }
  }
})
export default class NDeleteDialog extends Vue {
  @Prop({ type: Boolean, default: false }) value
  @Prop({ type: String, default: 'Achtung' }) title
  @Prop({ type: String, default: 'um zu löschen' }) buttonText
  @Prop({ type: Boolean, default: false }) disabled
  @Prop({ type: Boolean, default: false }) loading
  @Prop({ type: Number, default: 5 }) seconds
  @Prop({ type: Number, default: 500 }) width

  confirmTimer = null
  currentSecond = 0

  timerStep = 0.005

  get value_ () {
    return this.value
  }

  set value_ (v) {
    this.onClose(v)
  }

  get dashOffset () {
    if (!this.seconds || !this.confirmTimer) {
      return 0
    }

    const totalLength = 2 * window.innerWidth + 2 * window.innerHeight
    const offset = 1 - (this.seconds - this.currentSecond) / this.seconds
    return totalLength * offset
  }

  onStartConfirming () {
    if (this.confirmTimer) {
      clearInterval(this.confirmTimer)
    }

    this.currentSecond = this.seconds

    this.confirmTimer = setInterval(() => {
      this.currentSecond = this.currentSecond - this.timerStep
      if (this.currentSecond <= 0) {
        this.currentSecond = 0
        clearInterval(this.confirmTimer)
        this.$emit('ok')
      }
    }, 1000 * this.timerStep)
  }

  onEndConfirming () {
    this.currentSecond = this.seconds
    clearInterval(this.confirmTimer)
  }

  onClose () {
    if (this.confirmTimer) {
      clearInterval(this.confirmTimer)
      this.currentSecond = this.seconds
    }
    this.$emit('input', false)
    this.$emit('close')
  }
}
</script>

<style lang="scss">
#progres-rectangle {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  max-height: 100vh;
  pointer-events: none;

  #rect-path {
    stroke-dasharray: calc(2 * 100vw + 2* 100vh);
    stroke-dashoffset: calc(2 * 100vw + 2* 100vh);
  }
}
</style>
