<template lang="pug">
  v-card.student-card-plr(outlined)
    p.watermark COPY-SAFE
    canvas(width="600" height="380")
    svg(
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8.5 5.4"
      version="1.1"
    )
      g
        image(
          x="0.3"
          y="0.25"
          width="2.3"
          height="1.3"
          :xlink:href="logoImage"
        )

        image(
          x="0.3"
          y="1.75"
          width="2.3"
          height="3.4"
          :xlink:href="studentImage_"
        )

        image(
          x="5.85"
          y="1.2"
          width="2"
          height="1.58"
          :xlink:href="signImage"
        )

        image(
          x="5.85"
          y="3.1"
          width="2"
          height="1.58"
          :xlink:href="stampImage"
        )

        image(
          x="3.1"
          y="4.3"
          width="2.3"
          height="0.62"
          :xlink:href="studentSign_"
        )

        template(v-for="(svgText, index) in svgTexts")
          text(
            v-if="!svgText.hidden"
            :key="index"
            :x="svgText.x"
            :y="svgText.y + 0.2"
            alignment-baseline="middle"
            style="text-anchor: start;"
          )
            tspan(
              :fill="svgText.color || '#000000'"
              :font-family="svgText.fontFamily || 'Helvetica'"
              :font-size="svgText.size"
              :font-style="svgText.fontStyle || 'normal'"
              :font-weight="svgText.fontWeight || 'normal'"
            ) {{ svgText.text }}
            tspan(
              v-if="svgText.text_bold"
              dx="0.1"
              :fill="svgText.color || '#000000'"
              :font-family="svgText.fontFamily || 'Helvetica'"
              :font-size="svgText.size"
              :font-style="svgText.fontStyle || 'normal'"
              font-weight="bold"
            ) {{ svgText.text_bold }}

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    FormMixin
  ]
})
export default class StudentCardPlr extends Vue {
  @Prop({ type: String, default: '' }) logoImage
  @Prop({ type: String, default: '' }) stampImage
  @Prop({ type: String, default: '' }) signImage
  @Prop({ type: [Blob, String], default: null }) studentImage
  @Prop({ type: String, default: '' }) studentSign
  @Prop({ type: Object, default: () => {} }) productInfo
  @Prop({ type: Object, default: () => {} }) studentInfo

  get studentImage_ () {
    if (!this.studentImage) {
      return require('@/assets/images/default-student.jpg')
    }
    if (typeof this.studentImage === 'string') {
      return this.studentImage
    }
    return URL.createObjectURL(this.studentImage)
  }

  get studentSign_ () {
    if (!this.studentSign) {
      return require('@/assets/images/student-sign.png')
    }
    return this.studentSign
  }

  get svgTexts () {
    if (!this.productInfo) {
      return []
    }

    let xOrg = 2.75
    let yOrg = 0.4
    const fontSize = 0.2
    const lineHeight = fontSize + 0.06
    const lineSpacing = fontSize + 0.12

    return [
      {
        text: `${ this.productInfo.product.card_title } |`,
        color: '#1a176e',
        fontWeight: 'bold',
        x: xOrg,
        y: yOrg,
        size: 0.35,
      },
      {
        text: this.productInfo.product.card_title2,
        color: '#1a176e',
        x: xOrg + 2.8,
        y: yOrg - 0.03,
        size: 0.22
      },
      {
        text: "Verein",
        fontWeight: 'normal',
        x: xOrg,
        y: (yOrg = yOrg + 0.4),
        size: fontSize
      },
      {
        text: this.productInfo.tenant_name,
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineHeight),
        size: fontSize
      },
      {
        text: this.productInfo.tenant_name2 || '',
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineHeight),
        size: fontSize
      },
      {
        text: this.productInfo.product.external_id_name,
        text_bold: this.studentInfo.external_id || '',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: fontSize,
        hidden: !this.productInfo.need_external_id
      },
      {
        text: "Name",
        fontWeight: 'normal',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: fontSize
      },
      {
        text: this.studentInfo.forname || 'Mustervorname',
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineHeight),
        size: fontSize
      },
      {
        text: "Vorname",
        fontWeight: 'normal',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: fontSize
      },
      {
        text: this.studentInfo.familyname || 'Mustername',
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineHeight),
        size: fontSize
      },
      {
        text: 'geb. am',
        fontWeight: 'normal',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: fontSize
      },
      {
        text: this.studentInfo.birthday || '18.10.2008',
        fontWeight: 'bold',
        x: xOrg + 0.85,
        y: yOrg,
        size: fontSize
      },
      {
        text: 'Spielberechtigt für',
        fontWeight: 'normal',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: fontSize
      },
      {
        text: `${ this.productInfo.product.field1_desc || 'Verbands-SpR ab' }:`,
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineHeight),
        size: fontSize
      },
      {
        text: this.studentInfo.field1 || '21.05.21',
        fontWeight: 'bold',
        x: xOrg + 1.9,
        y: yOrg,
        size: fontSize
      },
      {
        text: `${ this.productInfo.product.field2_desc || 'Privat-SpR ab' }:`,
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineHeight),
        size: fontSize
      },
      {
        text: this.studentInfo.field2 || '21.05.21',
        fontWeight: 'bold',
        x: xOrg + 1.9,
        y: yOrg,
        size: fontSize
      },
      {
        text: 'Unterschrift Spieler/Spielerin',
        x: xOrg,
        y: 4.95,
        size: fontSize
      },
      {
        text: this.productInfo.product.field3_desc || 'Gültig bis',
        fontWeight: 'bold',
        x: 6,
        y: 4.85,
        size: fontSize
      },
      {
        text: this.studentInfo.field3 || '05 / 24',
        fontWeight: 'bold',
        x: 7.05,
        y: 4.85,
        size: fontSize
      },
    ]
  }
}
</script>

<style lang="sass">
.student-card-plr
  position: relative
  border-radius: 16px
  max-width: 620px
  width: 100%

  svg
    position: absolute
    top: 0
    left: 0
    width: 100%

  canvas
    opacity: 0
    max-width: 100%

  img
    width: 100%
    height: auto

  .card-image
    position: absolute
    width: 100%
    height: auto

    &--logo
      top: 8%
      left: 3%
      max-width: 25%
      max-height: 20%

    &--sign
      top: 69%
      left: 70%
      max-width: 25%
      max-height: 12%

    &--stamp
      top: 23%
      left: 67%
      max-width: 30%
      max-height: 50%
</style>
