<template lang="pug">
  v-card.product-list.pa-6
    v-row.align-center.mt-4
      v-col(cols="6")
        h2.primary--text Rechnungsläufe

    custom-table.list-runs.mt-3(
      page-id="runs"
      :headers="headers"
      :table-data="tableData"
      show-expand
      @load="(e) => loadPaymenRuns(e)"
    )
      tr(slot="item" slot-scope="props" @click="loadOrders(props)")
        td {{ props.item.id }}
        td {{ props.item.tenant_id }}
        td {{ props.item.tenant_name }}
        td {{ props.item.total_count }}
        td {{ props.item.total_amount }}
        td.text-center {{ formatDateTime(props.item.till_date, 'DD.MM.YYYY HH:mm:ss') }}
        td.text-center {{ props.item.user_profile_id }}

      template(#expanded-item="{ item, headers }")
        td(:colspan="headers.length")
          v-data-table.payment-orders.px-3.py-2(
            v-bind="tableProps"
            :items="orders"
            :headers="orderHeaders"
            :options="defaultPagination"
            hide-default-footer
          )
            template(#item.is_delete="{ value }") {{ value ? 'Ja' : 'Nein' }}
            template(#item.online_ready="{ value }") {{ value ? 'Ja' : 'Nein' }}
            template(#item.validated="{ value }") {{ value ? 'Ja' : 'Nein' }}
            template(#item.created_at="{ value }") {{ formatDateTime(value, 'DD.MM.YYYY HH:mm') }}
            template(#item.##="{ item }")
              a(title="Card bearbeiten" @click.prevent="onEditOrder(item)")
                v-icon edit

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'
import Urls from '@/router/urls'

@Component({
  mixins: [
    AuthMixin,
    FormMixin
  ],

  components: {
    NDatePicker: () => import('@/components/common/n-date-picker')
  },
})
export default class CardList extends Vue {
  tableData = {}
  orders = []

  headers = [
    {
      text: 'Lauf. ID',
      align: 'left',
      value: 'id',
      pagination_value: 'payment_run.id'
    },
    {
      text: 'Mand. ID',
      align: 'left',
      value: 'tenant_id'
    },
    {
      text: 'Mandant',
      align: 'left',
      value: 'tenant_name'
    },
    {
      text: 'Anzahl',
      align: 'left',
      value: 'total_count'
    },
    {
      text: 'Summe Preis',
      align: 'left',
      value: 'total_amount'
    },
    {
      text: 'Bis',
      align: 'center',
      value: 'till_date',
      use_slot_cell: true
    },
    {
      text: 'Erst. von',
      align: 'center',
      value: 'user_profile_id',
    },
  ]

  orderHeaders = [
    {
      text: 'ID',
      align: 'left',
      value: 'id',
    },
    {
      text: 'Mandant',
      align: 'left',
      value: 'tenant_name',
    },
    {
      text: 'Mand-ID',
      align: 'left',
      value: 'tenant_id'
    },
    {
      text: 'Produkt Typ',
      align: 'left',
      value: 'product_type'
    },
    {
      text: 'Nachname',
      align: 'left',
      value: 'familyname'
    },
    {
      text: 'Vorname',
      align: 'left',
      value: 'forname',
    },
    {
      text: 'Gelöscht?',
      align: 'center',
      value: 'is_delete',
    },
    {
      text: 'Online-Ready?',
      align: 'center',
      value: 'online_ready',
    },
    {
      text: 'Validiert?',
      align: 'center',
      value: 'validated',
    },
    {
      text: 'Produktcode',
      align: 'center',
      value: 'product_short_code',
    },
    {
      text: 'Erstellt',
      align: 'center',
      value: 'created_at',
    },
    {
      text: '',
      align: 'right',
      value: '##',
    },
  ]

  loadPaymenRuns (pagination) {
    this.$axios
      .post(Urls.InvoiceOrders.ListRuns, {
        pagination
      })
      .then(res => {
        this.tableData = {
          ...res.data,
          pagination
        }
      })
  }

  loadOrders (e) {
    if (e.isExpanded) {
      e.expand(false)
      return
    }

    this.$axios
      .get(Urls.InvoiceOrders.PaymentRunOrders, {
        payment_run_id: e.item.id
      })
      .then(res => {
        this.orders = res.data || []
        e.expand(true)
      })
  }

  onEditOrder (item) {
    this.selectTenant({
      id: item.tenant_id,
      name: item.tenant_name,
      name2: item.tenant_name2,
      short_code: item.short_code,
      is_test: item.is_test,
    })
      .then(() => {
        this.$router.push({
          name: 'card-edit',
          params: {
            id: item.id
          }
        })
      })
  }
}
</script>

<style lang="scss">
.table-action {
  max-width: 270px;
}

.email-input {
  max-width: 450px;
}

.list-runs {
  .v-data-table__wrapper {
    max-height: calc(100vh - 280px);
    overflow: auto !important;
  }

  tbody tr:not(.header-filter) td {
    position: sticky;
    top: 48px;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
  }

  .payment-orders {
    background-color: #f0f0f0 !important;

    .v-data-table__wrapper {
      max-height: 100%;
      overflow: unset !important;
    }

    thead {
      position: relative !important;
      background-color: transparent !important;
      z-index: 0 !important;
    }

    tbody tr td {
      position: relative;
      top: 0;
      background-color: transparent;
      z-index: 0;
      cursor: default;
    }
  }
}
</style>
