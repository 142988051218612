var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-6"},[_c('v-row',{staticClass:"sticky-card-header align-center mt-4"},[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Liste Bestellungen")])])],1),(_vm.isSuperAdmin)?_c('v-checkbox',{staticClass:"ml-2",attrs:{"label":"Aus-Archiv"},on:{"change":function($event){return _vm.loadArchive()}},model:{value:(_vm.fromArchive),callback:function ($$v) {_vm.fromArchive=$$v},expression:"fromArchive"}}):_vm._e(),_c('custom-table',{staticClass:"scrolling-table mt-3",attrs:{"page-id":"orders","headers":_vm.headers,"table-data":_vm.tableData,"stripped":""},on:{"load":function (e) { return _vm.loadCards(e); }},scopedSlots:_vm._u([{key:"item.tenant_name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.tenant_name)+" "+_vm._s(item.tenant_name2))]}},{key:"item.is_delete",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : 'Nein'))]}},{key:"item.online_ready",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : 'Nein'))]}},{key:"item.validated",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : 'Nein'))]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.formatDateTime(value, 'DD.MM.YYYY HH:mm')))]}},{key:"item.##",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex items-center"},[(_vm.isSuperAdmin)?_c('a',{staticClass:"admin-view",attrs:{"title":"Ausweis herunterladen"},on:{"click":function($event){$event.preventDefault();return _vm.downloadCard(item.id)}}},[_c('v-icon',[_vm._v("file_download")])],1):_vm._e(),_c('a',{attrs:{"title":"Card bearbeiten"},on:{"click":function($event){$event.preventDefault();return _vm.onEditCard(item)}}},[_c('v-icon',[_vm._v("edit")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }