var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"product-list pa-6"},[_c('v-row',{staticClass:"align-center mt-4"},[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Rechnungsläufe")])])],1),_c('custom-table',{staticClass:"list-runs mt-3",attrs:{"page-id":"runs","headers":_vm.headers,"table-data":_vm.tableData,"show-expand":""},on:{"load":function (e) { return _vm.loadPaymenRuns(e); }},scopedSlots:_vm._u([{key:"item",fn:function(props){return _c('tr',{on:{"click":function($event){return _vm.loadOrders(props)}}},[_c('td',[_vm._v(_vm._s(props.item.id))]),_c('td',[_vm._v(_vm._s(props.item.tenant_id))]),_c('td',[_vm._v(_vm._s(props.item.tenant_name))]),_c('td',[_vm._v(_vm._s(props.item.total_count))]),_c('td',[_vm._v(_vm._s(props.item.total_amount))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.formatDateTime(props.item.till_date, 'DD.MM.YYYY HH:mm:ss')))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.user_profile_id))])])}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',_vm._b({staticClass:"payment-orders px-3 py-2",attrs:{"items":_vm.orders,"headers":_vm.orderHeaders,"options":_vm.defaultPagination,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.is_delete",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : 'Nein'))]}},{key:"item.online_ready",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : 'Nein'))]}},{key:"item.validated",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : 'Nein'))]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.formatDateTime(value, 'DD.MM.YYYY HH:mm')))]}},{key:"item.##",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"title":"Card bearbeiten"},on:{"click":function($event){$event.preventDefault();return _vm.onEditOrder(item)}}},[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},'v-data-table',_vm.tableProps,false))],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }