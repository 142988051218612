<template lang="pug">
  div.password-form.mx-auto(:class="userId && 'change-password'" @keydown.enter="onSetPassword")
    v-card.pa-6
      v-card-title.pa-0
        v-icon(color="primary" size="32") settings
        h3.primary--text.ml-2 Passwort setzen

      v-form.mt-6(ref="password-form" lazy-validation)
        p.mb-4
          span(v-if="userId") Bitte geben Sie das neue Passwort für den Benutzer an.
          span(v-else-if="userInfo") Hallo {{ userInfo.forename }} {{ userInfo.lastname }},<br>bitte gib dein neues Passwort ein.
          span.red--text.mb-4(v-if="errorMessage") {{ errorMessage }}

        v-text-field(
          v-model="form.password"
          label="Neues Passwort"
          type="password"
          append-icon="lock"
          outlined
          :disabled="!!errorMessage"
          :rules="[ rules.required, rules.minLen(8) ]"
        )

        v-text-field(
          v-model="form.password_repeat"
          label="Wiederholung"
          type="password"
          append-icon="lock"
          outlined
          :disabled="!!errorMessage"
          :rules="[ rules.required, rules.password_match(form.password) ]"
        )

        v-row.mt-3
          v-col(:cols="userId ? 6 : 12")
            v-btn(block color="primary" :loading="loading" :disabled="!!errorMessage" @click="onSetPassword")
              span {{ userId ? 'Passwort setzen' : 'Speichern' }}

          v-col(v-if="userId" :cols="6")
            v-btn(block color="secondary" :disabled="loading" @click="$emit('close')")
              span.primary--text Abbrechen

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

import Urls from '@/router/urls'
import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    FormMixin
  ],

  watch: {
    userId () {
      this.form.password = ''
      this.form.password_repeat = ''
      this.resetForm('password-form')
    }
  }
})
export default class SetPassword extends Vue {
  @Prop({ type: String, default: '' }) token
  @Prop({ type: [ Number, String ], default: 0 }) userId

  form = {
    password: '',
    password_repeat: '',
  }

  userInfo = null
  errorMessage = ''
  loading = false

  mounted () {
    // check token
    if (!this.token) {
      return
    }

    this.$axios
      .get(Urls.Auth.CheckUserToken, {
        token: this.token,
        token_name: 'UserPassword'
      })
      .then(res => {
        this.userInfo = res.data
      })
      .catch(err => {
        this.errorMessage = err.message
      })
  }

  onSetPassword () {
    if (!this.isFormValid('password-form')) {
      return
    }

    let params = {
      new_password: this.form.password
    }
    let requestUri = Urls.Auth.SetNewPassword

    if (!this.userId) {
      params = {
        token: this.token,
        token_name: 'UserPassword',
        user_id: this.userInfo.id,
        email: this.userInfo.email,
        password: this.form.password,
      }
      requestUri = Urls.Auth.SetPasswordByToken
    }

    this.loading = true
    this.$axios
      .post(requestUri, params)
      .then(() => {
        if (!this.userId) {
          this.gotoRoute('login')
        } else {
          this.$emit('close')
        }
        this.$toast.success('Passwort wurde gesetzt. Bitte anmelden', 'Erfolg')
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>

<style scoped lang="scss">
.password-form {
  margin-top: 150px;
  max-width: 450px;
  width: 100%;

  &.change-password {
    margin-top: 0
  }
}
</style>
