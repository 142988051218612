// import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import 'vuetify/src/styles/main.sass'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1264,
      xl: 1544,
      xxl: 1904,
    }
  },

  icons: {
    iconfont: 'md',
  },

  theme: {
    themes: {
      light: {
        primary: '#024589',
        secondary: '#d7e5f8',
        accent: '#ed707b',
        info: '#6d9ac9',
        error: '#FF5252',
        success: '#4CAF50',
        warning: '#FFC107',
        ligher: '#e4ecf8'
      }
    },
    options: {
      customProperties: false
    }
  }
})
