<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 170 170"
  >
    <g i:extraneous="self">
      <g id="Ebene_1">
        <g>
          <g>
            <g>
              <path
                class="st0"
                d="M109.73,97c4.71,2.03,6.9,7.5,4.87,12.2c-2.03,4.71-7.5,6.9-12.21,4.87l-32.23-11.64"
              />
              <g>
                <path
                  class="st0"
                  d="M109.73,97L73.54,83.59c0,0-20.32-3.2-60.37,8.13l6.33,29.71c0,0,21.17-5.91,26.17-5.91
                  c5.01,0,22.5,12.85,33.72,16.39c11.23,3.54,30.14,1.72,30.14,1.72l37.14-25.88c4.76-1.92,7.05-7.33,5.13-12.08
                  c-1.92-4.75-7.33-7.06-12.1-5.13"
                />
                <line
                  class="st0"
                  x1="139.71"
                  y1="90.53"
                  x2="115.33"
                  y2="104.95"
                />
              </g>
            </g>
          </g>
          <line class="st0" x1="118.84" y1="67.57" x2="118.84" y2="99.24" />
          <g>
            <path
              class="st0"
              d="M156.81,36.03c-40.07-2.62-37.96,37.75-37.96,37.75l0,0"
            />
            <path class="st0" d="M156.81,36.03" />
            <path
              class="st0"
              d="M118.85,73.78c40.06,2.62,37.96-37.75,37.96-37.75l0,0"
            />
            <path class="st0" d="M156.81,36.03" />
          </g>
          <g>
            <path
              class="st0"
              d="M80.86,42.36c40.07-2.62,37.96,37.75,37.96,37.75l0,0"
            />
            <path class="st0" d="M80.86,42.36" />
            <path
              class="st0"
              d="M118.82,80.11c-40.07,2.62-37.96-37.75-37.96-37.75l0,0"
            />
            <path class="st0" d="M80.86,42.36" />
          </g>
        </g>
      </g>
      <g id="Ebene_4" class="st1">
        <g class="st2">
          <g>
            <path
              class="st0"
              d="M84.46,136.29H23.21c-3.37,0-6.12-2.76-6.12-6.12v-98c0-3.37,2.75-6.12,6.12-6.12h42.87"
            />
            <path
              class="st0"
              d="M84.46,38.29h61.25c3.36,0,6.12,2.76,6.12,6.12v36.75"
            />
            <g>
              <line class="st0" x1="66.08" y1="26.04" x2="84.46" y2="38.29" />
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <rect
                    x="115.08"
                    y="124.14"
                    class="st0"
                    width="36.75"
                    height="24.5"
                  />
                </g>
                <g>
                  <path
                    class="st0"
                    d="M121.2,121.9v-10.01c0-6.76,5.48-12.25,12.24-12.25c6.78,0,12.26,5.49,12.26,12.25v9.1"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path class="st3" d="M117.53,127.81" />
                </g>
              </g>
            </g>
          </g>
        </g>
        <path
          class="st4"
          d="M96.7,117.04c-5.93-9.36-16.35-15.59-28.25-15.59c-11.9,0-22.32,6.23-28.25,15.59"
        />
        <g class="st2">
          <path
            class="st5"
            d="M83.73,68.26c-2.02,1.14-4.27,1.95-6.69,2.34c-8.39,1.35-16.39-2.74-20.43-9.66"
          />
          <circle class="st5" cx="68.48" cy="71.32" r="15.55" />
        </g>
      </g>
      <g id="Ebene_3" class="st1">
        <g class="st2">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <polyline
                      class="st0"
                      points="84.83,155.41 32.32,155.41 32.32,24.15 57.98,24.15"
                    />
                    <polyline
                      class="st0"
                      points="111.75,24.15 137.34,24.15 137.34,96.34"
                    />
                  </g>
                  <path
                    class="st0"
                    d="M111.08,11.02v9.84c0,5.41-4.7,9.84-10.47,9.84H69.05c-5.77,0-10.47-4.44-10.47-9.84v-9.84"
                  />
                  <line
                    class="st0"
                    x1="58.58"
                    y1="11.02"
                    x2="111.08"
                    y2="11.02"
                  />
                </g>
                <line
                  class="st0"
                  x1="110.74"
                  y1="56.96"
                  x2="84.49"
                  y2="56.96"
                />
              </g>
            </g>
          </g>
          <g>
            <polyline
              class="st0"
              points="150.46,122.6 128.72,155.41 111.08,139.33"
            />
          </g>
          <path
            class="st6"
            d="M58.24,60.37L58.24,60.37c-1.88,0-3.41-1.53-3.41-3.41v0c0-1.88,1.53-3.41,3.41-3.41h0
            c1.88,0,3.41,1.53,3.41,3.41v0C61.64,58.84,60.12,60.37,58.24,60.37z"
          />
          <path
            class="st6"
            d="M58.24,86.71L58.24,86.71c-1.88,0-3.41-1.53-3.41-3.41v0c0-1.88,1.53-3.41,3.41-3.41h0
            c1.88,0,3.41,1.53,3.41,3.41v0C61.64,85.18,60.12,86.71,58.24,86.71z"
          />
          <path
            class="st6"
            d="M58.6,113.4L58.6,113.4c-1.88,0-3.41-1.53-3.41-3.41v0c0-1.88,1.53-3.41,3.41-3.41h0
            c1.88,0,3.41,1.53,3.41,3.41v0C62.01,111.88,60.48,113.4,58.6,113.4z"
          />
          <line class="st0" x1="110.74" y1="83.22" x2="84.49" y2="83.22" />
          <line class="st0" x1="110.74" y1="109.47" x2="84.49" y2="109.47" />
        </g>
      </g>
      <g id="Ebene_2" class="st1">
        <g class="st2">
          <line class="st0" x1="73.97" y1="91.02" x2="73.97" y2="56.4" />
          <line class="st0" x1="73.97" y1="91.02" x2="94.74" y2="77.17" />
          <path
            class="st0"
            d="M136.28,59.13c-8.45-17.65-24.63-31.35-45.18-35.84c-35.35-7.72-70.25,14.8-77.94,50.29
            c-7.67,35.49,14.75,70.52,50.12,78.23c17.68,3.85,35.24,0.16,49.35-8.95"
          />
        </g>
        <g class="st2">
          <line class="st7" x1="113.96" y1="104.53" x2="139.74" y2="104.53" />
          <line class="st7" x1="113.96" y1="113.83" x2="139.74" y2="113.83" />
          <path
            class="st7"
            d="M159.83,125.63c-4.24,4.28-10.12,6.93-16.62,6.93c-12.91,0-23.38-10.47-23.38-23.38
            c0-12.91,10.47-23.38,23.38-23.38c6.43,0,12.26,2.6,16.48,6.8"
          />
          <path class="st7" d="M159.99,91.81" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgEnvironment',
}
</script>

<style lang="scss">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st1 {
  display: none;
}

.st2 {
  display: inline;
}

.st3 {
  opacity: 0.1;
  fill: none;
  stroke: currentColor;
  stroke-width: 7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st4 {
  display: inline;
  fill: none;
  stroke: currentColor;
  stroke-width: 7;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st5 {
  fill: none;
  stroke: currentColor;
  stroke-width: 7;
  stroke-miterlimit: 10;
}

.st6 {
  fill: currentColor;
}

.st7 {
  fill: none;
  stroke: currentColor;
  stroke-width: 7;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
</style>
