<template lang="pug">
  v-card.product-list.pa-6
    v-row.align-center.mt-4
      v-col(cols="6")
        h2.primary--text Liste Produkte

      v-col.text-right(cols="6")
        v-btn(color="primary" @click="gotoRoute('product-add')") + Neues Produkt konfigurieren

    custom-table.scrolling-table.mt-3(
      page-id="products"
      :headers="headers"
      :table-data="tableData"
      @load="(e) => loadProducts(e)"
    )
      template(#item.can_hardcopy="{ value }") {{ value ? 'Ja' : "Nein" }}
      template(#item.card_valid_from="{ value }") {{ formatDateTime(value, 'DD.MM.YYYY') }}
      template(#item.card_valid_to="{ value }") {{ formatDateTime(value, 'DD.MM.YYYY') }}
      template(#item.is_disabled="{ value }") {{ value ? 'Ja' : "Nein" }}
      template(#item.is_test="{ value }") {{ value ? 'Ja' : "Nein" }}
      template(#item.#="{ item }")
        .d-flex.align-center.justify-end
          a.mr-1(title="Produkt bearbeiten" @click.prevent="onEditProduct(item)")
            v-icon edit

          a.admin-view(v-if="isSuperAdmin" title="Zur Bestellseite" @click.prevent="gotoOrderPage(item.product_short_code)")
            v-icon link

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'
import Urls from '@/router/urls'

@Component({
  mixins: [
    AuthMixin,
    FormMixin
  ],
})
export default class ProductList extends Vue {
  tableData = {}

  get headers () {
    let hideName2 = true
    if (this.tableData && this.tableData.data && this.selectedTenant) {
      hideName2 = this.tableData.data.filter(d => d.tenant_name_2 !== this.selectedTenant.name2).length === 0
    }
    const tableHeaders = [
      {
        text: 'ID',
        align: 'left',
        value: 'id'
      },
      {
        text: 'Produkt ID',
        align: 'left',
        value: 'product_id'
      },
      {
        text: 'Produkt Name',
        align: 'left',
        value: 'product_type'
      },
      {
        text: 'Name2',
        align: 'left',
        value: 'tenant_name_2',
        hidden: hideName2
      },
      {
        text: 'Karte möglich',
        align: 'left',
        value: 'can_hardcopy',
        use_slot_cell: true
      },
      {
        text: 'Produkt Code',
        align: 'left',
        value: 'product_short_code'
      },
      {
        text: 'Gültig ab',
        align: 'left',
        value: 'card_valid_from',
        use_slot_cell: true
      },
      {
        text: 'Gültig bis',
        align: 'center',
        value: 'card_valid_to',
        use_slot_cell: true
      },
      {
        text: 'Test?',
        align: 'center',
        value: 'is_test',
        use_slot_cell: true,
        filter_type: 'checkbox'
      },
      {
        text: 'Deaktiviert?',
        align: 'center',
        value: 'is_disabled',
        use_slot_cell: true,
        filter_type: 'checkbox'
      },
      {
        text: 'Aktion',
        align: 'right',
        value: '#',
        use_slot_cell: true
      },
    ]
    return tableHeaders.filter(h => !h.hidden)
  }

  loadProducts (pagination) {
    this.$axios
      .post(Urls.Product.List, {
        pagination
      })
      .then(res => {
        this.tableData = {
          ...res.data,
          pagination
        }
      })
  }

  onEditProduct (item) {
    this.$router.push({
      name: 'product-edit',
      params: {
        id: item.id
      }
    })
  }
}
</script>
