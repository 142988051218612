<template lang="pug">
  v-card.student-card-nrw(outlined)
    p.watermark COPY-SAFE
    canvas(width="600" height="380")
    svg(
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8.5 5.4"
      version="1.1"
    )
      g
        image(
          x="0.2"
          y="0.25"
          width="2.3"
          height="1.3"
          :xlink:href="logoImage"
        )

        image(
          x="0.2"
          y="1.7"
          width="2.3"
          height="3.4"
          :xlink:href="studentImage_"
        )

        image(
          x="6.15"
          y="1.15"
          width="2"
          height="1.58"
          :xlink:href="stampImage"
        )

        image(
          x="6"
          y="2.8"
          width="2.3"
          height="0.62"
          :xlink:href="signImage"
        )

        text(
          x="6.3"
          y="3.75"
          alignment-baseline="middle"
          style="text-anchor: start;"
        )
          tspan(
            font-family="Helvetica"
            font-size="0.25"
            font-style="italic"
            font-weight="normal"
          ) {{ productInfo.product.card_signer_desc }}

        text(
          x="6.8"
          y="4"
          alignment-baseline="middle"
          style="text-anchor: start;"
        )
          tspan(
            font-family="Helvetica"
            font-size="0.2"
            font-style="italic"
            font-weight="normal"
          ) principal

        image(
          x="6"
          y="4.25"
          width="2.3"
          height="0.62"
          :xlink:href="studentSign_"
        )

        text(
          x="6"
          y="5.1"
          alignment-baseline="middle"
          style="text-anchor: start;"
        )
          tspan(
            font-family="Helvetica"
            font-size="0.2"
            font-style="italic"
            font-weight="normal"
          ) Schüler / student / élève

        template(v-for="(svgText, index) in svgTexts")
          text(
            v-if="!svgText.hidden"
            :key="index"
            :x="svgText.x"
            :y="svgText.y"
            alignment-baseline="middle"
            style="text-anchor: start;"
          )
            tspan(
              :fill="svgText.color || '#000000'"
              :font-family="svgText.fontFamily || 'Helvetica'"
              :font-size="svgText.size"
              :font-style="svgText.fontStyle || 'normal'"
              :font-weight="svgText.fontWeight || 'normal'"
            ) {{ svgText.text }}

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    FormMixin
  ],
})
export default class StudentCardNrw extends Vue {
  @Prop({ type: String, default: '' }) logoImage
  @Prop({ type: String, default: '' }) stampImage
  @Prop({ type: String, default: '' }) signImage
  @Prop({ type: [Blob, String], default: null }) studentImage
  @Prop({ type: String, default: '' }) studentSign
  @Prop({ type: Object, default: () => {} }) productInfo
  @Prop({ type: Object, default: () => {} }) studentInfo

  get studentImage_ () {
    if (!this.studentImage) {
      return require('@/assets/images/default-student.jpg')
    }
    if (typeof this.studentImage === 'string') {
      return this.studentImage
    }
    return URL.createObjectURL(this.studentImage)
  }

  get studentSign_ () {
    if (!this.studentSign) {
      return require('@/assets/images/student-sign.png')
    }
    return this.studentSign
  }

  get svgTexts () {
    if (!this.productInfo) {
      return []
    }

    let xOrg = 2.6
    let yOrg = 0.65

    const valueSize = 0.2
    const labelSize = 0.16
    const lineSpacing = 0.24

    return [
      {
        text: this.productInfo.product.card_title,
        color: '#1a176e',
        fontWeight: 'bold',
        x: xOrg,
        y: yOrg,
        size: 0.5,
      },
      {
        text: this.productInfo.product.card_title2,
        color: '#1a176e',
        x: xOrg,
        y: (yOrg = yOrg + 0.33),
        size: 0.3
      },
      {
        text: `Schule / school / école`,
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing + 0.03),
        size: labelSize + 0.04,
        fontStyle: 'italic'
      },
      {
        text: this.productInfo.tenant_name || 'Staatl.Realschule RS',
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: valueSize,
        color: '#000'
      },
      {
        text: this.productInfo.tenant_name2,
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = this.productInfo.tenant_name2 ? (yOrg + lineSpacing) : (yOrg)),
        size: valueSize,
        color: '#000',
        hidden: !this.productInfo.tenant_name2
      },
      {
        text: this.productInfo.tenant_street || 'Schulstraße 111',
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: valueSize,
        color: '#000'
      },
      {
        text: `${ this.productInfo.tenant_plz || '48255' } ${ this.productInfo.tenant_city || 'Musterstadt' }`,
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: valueSize,
        color: '#000'
      },
      {
        text: `Name / name / nom`,
        x: xOrg,
        y: (yOrg = this.productInfo.tenant_name2 ? (yOrg + lineSpacing) : (yOrg + lineSpacing * 2)),
        size: labelSize,
        fontStyle: 'italic'
      },
      {
        text: this.studentInfo.familyname || 'Musternachname',
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: valueSize,
        color: '#000'
      },
      {
        text: `Vorname / first name / prénom`,
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: labelSize,
        fontStyle: 'italic'
      },
      {
        text: this.studentInfo.forname || 'Mustervorname',
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: valueSize,
        color: '#000'
      },
      {
        text: `Wohnort / place of residence / lieu de résidence`,
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: labelSize,
        fontStyle: 'italic'
      },
      {
        text: this.studentInfo.student_city || 'Musterort',
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: valueSize,
        color: '#000'
      },
      {
        text: `Strasse / street / rue`,
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: labelSize,
        fontStyle: 'italic'
      },
      {
        text: this.studentInfo.student_street || 'Musterstrasse',
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: valueSize,
        color: '#000'
      },
      {
        text: `Geb.Datum / birth date / date de naissance`,
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: labelSize,
        fontStyle: 'italic'
      },
      {
        text: this.studentInfo.birthday || 'Geb.Datum',
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: valueSize,
        color: '#000'
      },
      {
        text: `Gültig bis / date of expiry / date d'expiration`,
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: labelSize,
        fontStyle: 'italic'
      },
      {
        text: this.formatDateTime(this.productInfo.card_valid_to, 'DD.MM.YYYY') || '31.07.2099',
        fontWeight: 'bold',
        x: xOrg,
        y: (yOrg = yOrg + lineSpacing),
        size: valueSize,
        color: '#000'
      },
    ]
  }
}
</script>

<style lang="sass">
.student-card-nrw
  position: relative
  border-radius: 16px
  max-width: 620px
  width: 100%

  svg
    position: absolute
    top: 0
    left: 0
    width: 100%

  canvas
    opacity: 0
    max-width: 100%

  img
    width: 100%
    height: auto

  .card-image
    position: absolute
    width: 100%
    height: auto

    &--logo
      top: 8%
      left: 3%
      max-width: 25%
      max-height: 20%

    &--sign
      top: 69%
      left: 70%
      max-width: 25%
      max-height: 12%

    &--stamp
      top: 23%
      left: 67%
      max-width: 30%
      max-height: 50%
</style>
