var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-6"},[_c('v-row',{staticClass:"sticky-card-header align-center mt-4"},[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Liste Ausweise")])]),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"6"}},[(_vm.isSuperAdmin)?_c('v-checkbox',{staticClass:"ml-2",attrs:{"label":"Aus-Archiv"},on:{"change":_vm.onSelectArchive},model:{value:(_vm.fromArchive),callback:function ($$v) {_vm.fromArchive=$$v},expression:"fromArchive"}}):_vm._e(),_c('v-select',{staticClass:"table-action",attrs:{"clearable":"","dense":"","hide-details":"","outlined":"","items":_vm.tableActions,"readonly":!_vm.selectedItemIds.length},model:{value:(_vm.tableAction),callback:function ($$v) {_vm.tableAction=$$v},expression:"tableAction"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","disabled":!_vm.tableAction || !_vm.selectedItemIds.length},on:{"click":_vm.onTakeAction}},[_vm._v("Aktion durchführen")])],1)],1),_c('custom-table',{staticClass:"scrolling-table mt-3",attrs:{"page-id":"cards","headers":_vm.headers,"table-data":_vm.tableData,"stripped":""},on:{"load":function (e) { return _vm.loadCards(e); },"loaded":_vm.initSelectedItems},scopedSlots:_vm._u([{key:"header.group",fn:function(){return [_c('a',{staticClass:"d-flex",on:{"click":function($event){return _vm.onExpandColGroup(true)}}},[_c('v-icon',[_vm._v("more_horiz")]),_c('v-icon',[_vm._v("chevron_right")])],1)]},proxy:true},{key:"header.image",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[(_vm.isExpanded)?_c('a',{staticClass:"mr-3",on:{"click":function($event){return _vm.onExpandColGroup(false)}}},[_c('v-icon',[_vm._v("chevron_left")])],1):_vm._e(),_c('span',[_vm._v("Bild")])])]},proxy:true},{key:"filter.#",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"ml-2",attrs:{"dense":""},on:{"change":_vm.onChangeSelectAll},model:{value:(_vm.selectAllItems[_vm.page]),callback:function ($$v) {_vm.$set(_vm.selectAllItems, _vm.page, $$v)},expression:"selectAllItems[page]"}})]}},{key:"filter.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('v-checkbox',{staticClass:"d-inline-block",attrs:{"dense":""},on:{"change":_vm.onChangeShowImage},model:{value:(_vm.showImage[_vm.page]),callback:function ($$v) {_vm.$set(_vm.showImage, _vm.page, $$v)},expression:"showImage[page]"}})],1)]}},{key:"item.#",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":""},on:{"change":_vm.onChangeSelection},model:{value:(_vm.selectedItems[item.id]),callback:function ($$v) {_vm.$set(_vm.selectedItems, item.id, $$v)},expression:"selectedItems[item.id]"}})]}},{key:"item.online_ready",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : "Nein"))]}},{key:"item.validated",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : "Nein"))]}},{key:"item.birthday",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.formatDateTime(value, 'DD.MM.YYYY')))]}},{key:"item.is_delete",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : "Nein"))]}},{key:"item.is_online",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : "Nein"))]}},{key:"item.is_hardcopy",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : "Nein"))]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.formatDateTime(value, 'DD.MM.YYYY')))]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(_vm.showImage[_vm.page])?_c('img',{attrs:{"src":_vm.tableImages[item.id],"contain":"","width":"100","height":"80"}}):_vm._e()]}},{key:"item.##",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex items-center"},[(_vm.isSuperAdmin)?_c('a',{staticClass:"admin-view",attrs:{"title":"Ausweis herunterladen"},on:{"click":function($event){$event.preventDefault();return _vm.downloadCard(item.id)}}},[_c('v-icon',[_vm._v("file_download")])],1):_vm._e(),_c('a',{attrs:{"title":"Card bearbeiten"},on:{"click":function($event){$event.preventDefault();return _vm.onEditCard(item)}}},[_c('v-icon',[_vm._v("edit")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }