<template lang="pug">
  .cardy-home
    v-row.page-section.text-center.text-md-left.my-0
      v-col.d-none.d-md-block.d-xl-none(cols="1" md="1")
      v-col(cols="11" md="5")
        h2.font-weight-regular Schnell und sicher.
        h1.large Ausweise mit Onlinefunktion.

        .form-card.mx-auto.mx-lg-0.my-10: .card-content.mt-6.mt-lg-8.mt-xl-10: v-form.ma-5(ref="ordering-form" lazy-validation)
          v-text-field(
            v-model="tenantCode"
            label="Zugangscode"
            :rules="[ rules.required ]"
            outlined
            @keydown.enter="onFocus('product-code')"
          )

          v-text-field(
            v-model="productCode"
            id="product-code"
            label="Produktcode"
            :rules="[ rules.required ]"
            outlined
            @keydown.enter="onOrdering"
          )

          v-btn.mt-4(
            block
            color="accent"
            @click="onOrdering"
          ) Ausweis erstellen

      v-col(cols="12" md="6")
        img.v-image.large.mx-auto(src="@/assets/images/home-1.png" width="80%")

    v-row.page-section.align-center
      v-col(cols="12" md="6")
        img.v-image.mx-auto(src="@/assets/images/home-2.png" width="70%")

      v-col(cols="12" md="6")
        h1 Identität durch Online ID sicher verifizieren
        h3.black--text.font-weight-regular.mt-8
          | Profitieren Sie vom Cardy-Ausweis-System und nutzen Sie die schnelle und unkomplizierte Erstellung von Ausweisen.<br><br>
          | Egal ob Schülerausweis, Studentenausweis oder andere Ausweise. Mit nur ein paar Klicks übertragen sie Ihre Ausweise auf Ihr Smartphone.

    v-row.page-section.card-row
      v-col.text-center(cols="12")
        h1 Alle Vorteile auf einem Blick

      v-col.py-5.px-xl-8(
        v-for="(a, i) in advantages"
        :key="i"
        :cols="12"
        :sm="6"
        :lg="3"
      )
        .form-card.card-advantage.mx-auto: .card-content.pa-lg-5.pa-xl-10
          .text-center
            component(:is="a.icon")

          h3.text-uppercase.mt-4 {{ a.title }}
          h3.black--text.font-weight-regular.mt-4.mt-lg-6.mt-xl-10 {{ a.text }}

    v-row.page-section.align-center
      v-col.d-none.d-md-block.d-xl-none(cols="1" md="1")
      v-col(cols="11" md="5")
        h1 Und so einfach geht’s:
        .mt-10
          .d-flex.align-center.mt-5(
            v-for="(t, i) in easyLabels"
            :key="i"
          )
            h3.circle-badge.mr-5 {{ i + 1 }}
            h3.black--text.font-weight-regular {{ t }}

      v-col(cols="12" md="6")
        img.v-image.mx-auto(src="@/assets/images/home-3.png" width="70%")

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import FormMixin from '@/mixins/form'
import Urls from '@/router/urls'

@Component({
  mixins: [
    FormMixin
  ],

  components: {
    SvgEnvironment: () => import('@/components/svgs/svg-environment'),
    SvgTimeCost: () => import('@/components/svgs/svg-time-cost'),
    SvgChecklist: () => import('@/components/svgs/svg-checklist'),
    SvgSafeReliable: () => import('@/components/svgs/svg-safe-reliable'),
  }
})
export default class Home extends Vue {
  tenantCode = ''
  productCode = ''

  advantages = [
    {
      icon: 'svg-environment',
      title: 'NACHHALTIG und  umweltfreundlich',
      text: 'Mit Cardy reduzieren Sie nicht nur den Papier- und Plastikverbrauch, sondern auch Ihren CO2 Fußabdruck.'
    },
    {
      icon: 'svg-time-cost',
      title: 'Spart Zeit und Kosten',
      text: 'Cardy nimmt Ihnen viel bürokratische Arbeit ab und so entfällt die Wartezeit.'
    },
    {
      icon: 'svg-checklist',
      title: 'Schnell und einfach',
      text: 'Die Aktivierung ist nicht nur schnell gemacht, sondern ganz einfach von überall gemacht.'
    },
    {
      icon: 'svg-safe-reliable',
      title: 'Sicher und zuverlässig',
      text: 'Dank höhster Sicherheitsstufen, eignet sich Cardy besonders für sensible Daten.'
    },
  ]

  easyLabels = [
    'Ausweis mit Code bestellen',
    'Zugang per Mail erhalten',
    'App runterladen und anmelden'
  ]

  onFocus (id) {
    const el = document.getElementById(id)
    if (el) {
      el.focus()
    }
  }

  onOrdering () {
    if (!this.isFormValid('ordering-form')) {
      return
    }

    this.$axios
      .get(Urls.Order.ProductInfo(this.tenantCode, this.productCode))
      .then(() => {
        this.$router.push({
          name: 'ordering',
          params: {
            tenantShortCode: this.tenantCode,
            productShortCode: this.productCode
          }
        })
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
  }
}
</script>

<style lang="scss">
.cardy-home {
  .form-card {
    max-width: 480px;
  }
}
</style>
