<template lang="pug">
  v-menu(
    v-model="menu"
    :close-on-content-click="false"
    :max-width="320"
    :min-width="290"
    :disabled="disabled"
    :readonly="readonly"
  )
    template(#activator="{ on, attrs }")
      v-form.form-date-input(ref="form-date" lazy-validation)
        v-icon.icon-event(v-bind="attrs" v-on="on" color="primary") event
        v-text-field.n-date-picker(
          :class="readonly && 'n-date-picker--readonly'"
          :value="formattedValue"
          :label="label"
          :clearable="clearable"
          :disabled="disabled"
          :rules="inputRules"
          :placeholder="placeholder_"
          outlined
          dense
          hide-details
          @click:clear="onChangeDate(null)"
          @input="(v) => onInputDate(v)"
          @change="(v) => onInputDate(v)"
          @keydown="(e) => onKeydown(e)"
        )

    v-date-picker(
      ref="picker"
      :value="value_"
      :first-day-of-week="1"
      :locale="`de-DE`"
      :readonly="readonly"
      :no-title="type === 'year'"
      :scrollable="type === 'year'"
      @click:year="(v) => onSelectYear(v)"
      @change="onChangeDate"
    )

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import moment from 'moment'

import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    FormMixin
  ],

  watch: {
    menu (value) {
      if (this.type !== 'year' || !value) {
        return
      }

      this.$nextTick(() => {
        this.$refs.picker.activePicker = 'YEAR'
      })
    }
  }
})
export default class NDatePicker extends Vue {
  @Prop({ type: String, default: '' }) value
  @Prop({ type: String, default: 'YYYY-MM-DD' }) format
  @Prop({ type: String, default: 'DD.MM.YYYY' }) displayFormat
  @Prop({ type: String, default: '' }) label
  @Prop({ type: String, default: '' }) type
  @Prop({ type: Boolean, default: false }) clearable
  @Prop({ type: Boolean, default: false }) disabled
  @Prop({ type: Boolean, default: false }) readonly
  @Prop({ type: Array, default: () => [] }) inputRules

  menu = false

  get value_ () {
    return this.value
      ? moment(this.value).format('YYYY-MM-DD')
      : null
  }

  get formattedValue () {
    return this.value
      ? moment(this.value).format(this.displayFormat)
      : null
  }

  get placeholder_ () {
    if (!this.displayFormat) {
      return ''
    }

    let format_ = this.displayFormat
    format_ = format_.replace('DD', 'TT')
    format_ = format_.replace('YYYY', 'JJJJ')

    return format_
  }

  onChangeDate (value) {
    if (this.readonly || this.disabled) {
      return
    }

    if (!this.isFormValid('form-date')) {
      this.$emit('invalid')
      return
    }

    this.menu = false
    const emitValue = value
      ? moment(value).format(this.format)
      : null

    if (emitValue === 'Invalid date') {
      this.$emit('invalid')
      return
    }

    this.$emit('input', emitValue)
    this.$emit('change', emitValue)
  }

  onInputDate (value) {
    if (this.readonly || this.disabled) {
      return
    }

    if (!this.isFormValid('form-date')) {
      this.$emit('invalid')
      return
    }

    this.menu = false
    const emitValue = value
      ? moment(value, this.displayFormat).format(this.format)
      : null

    if (emitValue === 'Invalid date') {
      this.$emit('invalid')
      return
    }

    this.$emit('input', emitValue)
    this.$emit('change', emitValue)
  }

  onKeydown (e) {
    const validKeyCodes = [8, 9, 37, 38, 39, 40, 46, 190]
    if (validKeyCodes.includes(e.keyCode)) {
      return true
    }

    if (e.keyCode === 32 || isNaN(e.key)) {
      e.preventDefault()
    }
  }

  onSelectYear (year) {
    if (this.type !== 'year') {
      return
    }

    const emitValue = year !== null
      ? moment(`${ year }-01-01`).format(this.format)
      : null

    this.$emit('input', emitValue)
    this.$emit('change', emitValue)

    // Reset activePicker to type YEAR
    this.$refs.picker.activePicker = 'YEAR'

    // Close the menu/datepicker
    this.menu = false
  }
}
</script>

<style lang="sass">
.icon-event
  position: absolute !important
  right: 10px
  top: 8px
  z-index: 2

.form-date-input
  position: relative
  width: 100%

.n-date-picker
  width: 100%

  &--readonly
    pointer-events: none

  input
    padding-right: 30px

  .v-input__icon.v-input__icon--clear .v-icon
    margin-right: 40px
</style>
