import Vue from 'vue'

import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import config from '@/config'

const initState = {
  authToken: null,
  lastTokenRefreshAt: null,
  lastActionAt: null,
  tokenExpireInMinutes: null,
  userData: null,
  userGroup: ''
}

function getInitialState (reset = false) {
  const stateFromStorage = window.localStorage
    ? JSON.parse(Vue.localStorage.get(config.STORAGE_KEY, '{}'))
    : null
  const authState = stateFromStorage && stateFromStorage.auth
    ? stateFromStorage.auth
    : {}

  Object.keys(initState)
    .map(k => {
      initState[k] = config.KEEP_LOGGED_IN && !reset && authState[k]
        ? authState[k]
        : null
    })

  return initState
}

mutations.RESET_STATE = (state) => {
  const state_ = getInitialState(true)

  Object
    .keys(state_)
    .map(key => {
      state[key] = state_[key]
    })
}

export default {
  state: getInitialState(),
  getters,
  actions,
  mutations
}
