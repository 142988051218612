<template lang="pug">
  .page-section
    v-dialog(
      v-model="showConfirmModal"
      content-class="test-confirm-dialog"
      max-width="500"
      width="100%"
      persistent
      no-click-animation
    )
      v-card
        v-card-text.pt-6.pb-0
          | Sind Sie sicher, dass Sie eine Testbestellung durchführen möchten?<br><br>
          | Falls Sie eine vollständige/gültige Bestellung durchführen möchten, setzen Sie sich bitte mit Ihrer Organisation (Schule, Firma, Universität, Verein etc.) in Verbindung.<br>
          | Diese können Ihnen den korrekten Zugang zu dem Bestellsystem geben.<br><br>
          | Gerne kann Ihre Organisation über <a :href="registerPageUrl" target="_blank">Registrieren</a> oder unter <a href="https://www.sid-card.de/" target="_blank">https://www.sid-card.de/</a> mit uns in Verbindung treten.

        v-card-actions.mt-3
          v-spacer

          v-btn(
            class="px-5"
            color="green darken-1"
            text
            @click="showConfirmModal = false"
          ) Nein

          v-btn(
            class="px-5 ml-4"
            color="primary"
            text
            @click="handleSubmitOrder"
          ) Ja

    .cardy-ordering.mx-auto(v-if="productInfo"): v-row.align-start
      v-col.order-1.order-md-0.pr-md-10(cols="12" md="6")
        .d-none.d-md-block
          h1 Ausweis erstellen
          h3.font-weight-regular.black--text.mt-2 Bitte ein Bild hochladen und Daten eintragen.

        student-card-nrw.my-10(
          v-if="productInfo.product.product_type === 'Schülerausweis NRW'"
          :logo-image="logoImage"
          :stamp-image="stampImage"
          :sign-image="signImage"
          :student-sign="signFile"
          :student-image="studentImage"
          :product-info="productInfo"
          :student-info="studentInfo"
        )

        student-card-plr.my-10(
          v-else-if="productInfo.product.product_type === 'Verbandsausweis'"
          :logo-image="logoImage"
          :stamp-image="stampImage"
          :sign-image="signImage"
          :student-sign="signFile"
          :student-image="studentImage"
          :product-info="productInfo"
          :student-info="studentInfo"
        )

        student-card-sch.my-10(
          v-else-if="productInfo.product.product_type === 'Schülerausweis' || productInfo.product.product_type === 'Lehrerausweis'"
          :logo-image="logoImage"
          :stamp-image="stampImage"
          :sign-image="signImage"
          :student-image="studentImage"
          :product-info="productInfo"
          :student-info="studentInfo"
        )

        // productInfo.product.product_type === 'Studentenausweis' || productInfo.product.product_type === 'Vereinsausweis'
        student-card-stu.my-10(
          v-else
          :logo-image="logoImage"
          :stamp-image="stampImage"
          :sign-image="signImage"
          :student-image="studentImage"
          :product-info="productInfo"
          :student-info="studentInfo"
        )

        template(v-if="studentInfo.is_hardcopy")
          p.accent--text.text-uppercase.font-weight-bold WICHTIG!
          p Der Ausweis wird immer direkt an {{ productInfo.product.tenant_salutation_an }} und NICHT an den Besteller versendet. Bearbeitungszeit 10-12 Tage.<br>Datenschutzhinweis: Das hochgeladene Foto und die Daten werden nach Ausweisversand vollständig gelöscht.

        template(v-if="studentInfo.is_online")
          p.accent--text.text-uppercase.font-weight-bold WICHTIG!
          p Der Ausweis kann direkt nach der Bestellung in die Cardy app geladen werden. Sie erhalten hierzu anschließend eine E-Mail mit den benötigten Zugangsdaten und Links zu der Cardy app (iOS/Android Stores)

      v-col.order-0.order-md-1.col-photo(cols="12" md="6")
        .d-block.d-md-none
          h1 Ausweis bestellen
          h3.font-weight-regular.black--text.mt-2.mb-10 Für die Bestellung benötigen wir von Ihnen noch folgende Informationen.

        .form-card: .card-content.pa-8
          p.sub-heading.text-center(v-if="productInfo.is_tenant_test||productInfo.is_test") TEST-AUSWEIS

          student-photo(
            :file="studentImage"
            :disabled="!!orderId"
            :hide-select="!!studentImage"
            @reset="studentImage = null"
            @select="onSelectImage"
          )

          v-form.mt-10.pt-4(ref="order-form" lazy-validation)
            v-row
              v-col.py-0(cols="12" md="12")
                p.small.red--text(v-if="!studentImage") Foto oder Selfie hochladen und blaues Zoom-Feld verwenden um <u>das Gesicht</u> auszuwählen! Ihr Gesicht muss deutlich erkennbar sein.
                p.sub-heading.red(v-if="productInfo.is_tenant_test||productInfo.is_test") Dies ist eine Seite für Test-Bestellungen.
                p.sub-heading.red(v-if="productInfo.is_tenant_test||productInfo.is_test") Hier bitte KEINE offiziellen Bestellungen durchführen.
                p.sub-heading.red(v-if="productInfo.is_tenant_test||productInfo.is_test") Die Seite ist nur zum Anlegen von Testbestellungen zu nutzen.
                p.sub-heading.red(v-if="expiresSoon") Achtung: Dieser Ausweis wird nur noch {{productInfo.card_max_days_valid}} Tage gültig sein!
                p &nbsp;

            v-row
              v-col.py-0(cols="12" md="6")
                v-text-field(
                  v-model="studentInfo.forname"
                  :label="`Vorname des ${ productInfo.product.orderer_desc }*`"
                  outlined
                  :readonly="!!orderId"
                  :rules="[ rules.required, rules.maxLen(18) ]"
                )

              v-col.py-0(cols="12" md="6")
                v-text-field(
                  v-model="studentInfo.familyname"
                  :label="`Nachname des ${ productInfo.product.orderer_desc }*`"
                  outlined
                  :readonly="!!orderId"
                  :rules="[ rules.required, rules.maxLen(18) ]"
                )

              v-col.py-0(cols="12" md="12")
                v-text-field(
                  v-model="studentInfo.birthday"
                  :label="`Geburtsdatum des ${ productInfo.product.orderer_desc }* (TT.MM.JJJJ)`"
                  outlined
                  :readonly="!!orderId"
                  :rules="[ rules.required, rules.valid_date() ]"
                )

              v-col.py-0(cols="6" md="6")
                v-text-field(
                 :value="formatDateTime(productInfo.card_valid_from, 'DD.MM.YYYY')"
                  label="(info) Ausweis gültig von"
                  outlined
                  :readonly="true"
                  disabled
                )

              v-col.py-0(cols="6" md="6")
                v-text-field(
                  :value="formatDateTime(productInfo.card_valid_to, 'DD.MM.YYYY')"
                  label="(info) Ausweis gültig bis"
                  outlined
                  :readonly="true"
                  disabled
                  :class="expiresSoon?'expires-soon':''"
                )

              v-col.py-0(cols="12" md="12")
                v-text-field(
                  v-if="productInfo.product.needs_student_city"
                  v-model="studentInfo.student_city"
                  :label="`PLZ/Ort des ${ productInfo.product.orderer_desc }`"
                  outlined
                  :readonly="!!orderId"
                  :rules="[ rules.required ]"
                )

              v-col.py-0(cols="12" md="12")
                v-text-field(
                  v-if="productInfo.product.needs_student_street"
                  v-model="studentInfo.student_street"
                  :label="`Adresse/Straße des ${ productInfo.product.orderer_desc }`"
                  outlined
                  :readonly="!!orderId"
                  :rules="[ rules.required ]"
                )

              v-col.py-0(cols="12")
                v-text-field(
                  v-model="studentInfo.email"
                  label="E-Mail Adresse*"
                  outlined
                  :readonly="!!orderId"
                  :rules="[ rules.required, rules.email ]"
                )

              v-col.pt-0(v-if="productInfo.product.needs_student_sign" cols="12" md="12")
                sign-pad(
                  v-model="signDraft"
                  ref="sign"
                  :label="`Unterschrift des ${ productInfo.product.orderer_desc } (mit Maus oder Finger zeichnen)`"
                  required
                  :readonly="!!orderId"
                  @clear="signFile = null"
                )
                .text-right.mb-5: v-btn.btn-confirm(v-if="!orderId" small color="primary" @click="onConfirmSign") Unterschrift bestätigen

            v-row.pt-4
              v-col.py-0(v-if="productInfo.need_external_id" cols="12" md="6")
                v-text-field(
                  v-model="studentInfo.external_id"
                  :label="`${ productInfo.product.external_id_name }*`"
                  outlined
                  :readonly="!!orderId"
                  :rules="[ rules.required ]"
                )
                  template(#append-outer)
                    info-tooltip.input-hint
                      div(v-if="productInfo.barcode_hint" v-html="productInfo.barcode_hint")
                      div(v-else) Erfragen Sie Daten hierzu bei {{ productInfo.product.tenant_salutation_von }} unter {{ productInfo.tenant_phone}}

              v-col.py-0(v-if="productInfo.need_external_department" cols="12" md="6")
                v-text-field(
                  v-model="studentInfo.external_department"
                  :label="`${ productInfo.product.external_department_name }*`"
                  outlined
                  :readonly="!!orderId"
                  :rules="[ rules.required ]"
                )

              v-col.py-0(v-if="productInfo.needs_field1" cols="12" md="6")
                v-text-field(
                  v-model="studentInfo.field1"
                  :label="`${ productInfo.product.field1_desc }* (TT.MM.JJJJ)`"
                  outlined
                  :readonly="!!orderId"
                  :rules="[ rules.required, rules.valid_date() ]"
                )

              v-col.py-0(v-if="productInfo.needs_field2" cols="12" md="6")
                v-text-field(
                  v-model="studentInfo.field2"
                  :label="`${ productInfo.product.field2_desc }* (TT.MM.JJJJ)`"
                  outlined
                  :readonly="!!orderId"
                  :rules="[ rules.required, rules.valid_date() ]"
                )

              v-col.py-0(v-if="productInfo.needs_field3" cols="12" md="6")
                v-text-field(
                  v-model="studentInfo.field3"
                  :label="`${ productInfo.product.field3_desc }* (MM/JJ)`"
                  outlined
                  :readonly="!!orderId"
                  :rules="[ rules.required, rules.valid_date('MM/YY') ]"
                )

            v-row(v-if="productInfo.can_online && productInfo.can_hardcopy")
              v-col.pt-0(cols="12" sm="6"): v-checkbox.pt-0(
                v-model="studentInfo.is_online"
                label="Ausweis für Smartphone"
                hide-details
                on-icon="stop"
                off-icon=""
                color="accent"
                :ripple="false"
                :readonly="!!orderId"
                :rules="[ rules.required_optional(studentInfo.is_hardcopy) ]"
                @change="onChangeCheckbox()"
              )

              v-col.pt-0(cols="12" sm="6"): v-checkbox.pt-0(
                v-model="studentInfo.is_hardcopy"
                label="Ausweis als Scheckkarte"
                hide-details
                on-icon="stop"
                off-icon=""
                color="accent"
                :readonly="!!orderId"
                :rules="[ rules.required_optional(studentInfo.is_online) ]"
                @change="onChangeCheckbox()"
              )

            v-row(v-if="isSuperAdmin")
              v-col.pb-0(cols="12" sm="6"): v-checkbox.admin-input.pt-0(
                v-model="studentInfo.is_test"
                label="Ist Testbestellung"
                on-icon="stop"
                off-icon=""
                color="accent"
                hide-details
                :readonly="!!orderId"
              )

              v-col.pb-0(cols="12" sm="6"): v-checkbox.admin-input.pt-0(
                v-model="studentInfo.is_payed"
                label="Ist bezahlt"
                on-icon="stop"
                off-icon=""
                color="accent"
                hide-details
                :readonly="!!orderId"
              )

            v-row(v-if="!productInfo.payment_disabled && !productInfo.tenant_payee")
              v-col.mt-8.text-center(cols="12")
                span <b>Gesamtbetrag:</b> {{ price }} {{ productInfo.product.currency }}

          v-row.mt-6
            v-col.text-left.small(cols="12" sm="6") *Pflichtangaben
            v-col(v-if="studentImage && isSignConfirmed && !orderId" cols="12" sm="6")
              v-btn(block color="accent" :loading="loading" @click="onSubmitOrder") Bestellen

            v-col.text-center(cols="12")
              template(v-if="paidStauts")
                h4.sub-heading.mb-4
                  span(v-if="paidStauts === 'success'") {{ studentInfo.is_payed ? 'Ist bezahlt' : 'Ihre Bestellung ist bestätigt!' }}
                  span.red--text(v-else-if="paidStauts === 'failed'") Bei Ihrer Zahlung ist ein Fehler aufgetreten.
                  span(v-else-if="paidStauts === 'cancelled'") Ihre Bestellung wurde abgebrochen.

                v-btn.mb-2(block color="primary" @click="onNewOrder") Neue Bestellung

              .paypal-wrapper.show.mt-4(v-else-if="!!orderId && (showPaypalButton || showStripeButton)")
                p Bitte bestätigen Sie Ihre Bestellung durch Zahlung mit PayPal

                form(v-if="showPaypalButton" :action="paypalPaymentURL" method="post")
                  input(type="hidden" :value="orderId" name="order_id")
                  button.btn-checkout(type="submit" :class="paypalLoading && 'loading'" @click="onCheckout(true)")
                    img.logo-paypal(src="@/assets/images/paypal.png")
                    .loading-spinner(v-if="paypalLoading")
                      v-progress-circular(color="#f0f0f0" indeterminate size="36")

                p.my-3(v-if="showPaypalButton && showStripeButton") Oder

                form(v-if="showStripeButton" :action="stripePaymentURL" method="post")
                  input(type="hidden" :value="orderId" name="order_id")
                  button.btn-checkout(type="submit" :class="stripeLoading && 'loading'" @click="onCheckout(false)")
                    img.logo-stripe(src="@/assets/images/stripe.svg")
                    .d-flex.align-center.justify-center.flex-wrap
                      img.logo-pay--visa(src="@/assets/images/visa.png")
                      img.logo-pay(src="@/assets/images/master-card.png")
                      img.logo-pay(src="@/assets/images/amex.png")
                      img.logo-pay(src="@/assets/images/giro-pay.png")
                      img.logo-pay(src="@/assets/images/klarna.png")
                      img.logo-pay(src="@/assets/images/sofort.png")
                      img.logo-pay(v-if="mobileOS === 'iOS'" src="@/assets/images/apple-pay.png")
                      img.logo-pay(v-if="mobileOS === 'Android'" src="@/assets/images/g-pay.png")

                    .loading-spinner(v-if="stripeLoading")
                      v-progress-circular(color="#f0f0f0" indeterminate size="36")

                p.text-center.mt-3 Die Kosten für den Ausweis betragen <b>{{ formatMoney(price, 2, productInfo.product.currency) }}</b>.
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

import config from "@/config";
import Urls from '@/router/urls'
import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'
import Base64 from '@/utils/base64'

@Component({
  mixins: [
    FormMixin,
    AuthMixin
  ],

  components: {
    StudentCardStu: () => import('@/components/common/student-card-stu'),
    StudentCardSch: () => import('@/components/common/student-card-sch'),
    StudentCardNrw: () => import('@/components/common/student-card-nrw'),
    StudentCardPlr: () => import('@/components/common/student-card-plr'),
    StudentPhoto: () => import('@/components/common/student-photo'),
    SignPad: () => import('@/components/common/sign-pad'),
  },
})
export default class Ordering extends Vue {
  @Prop({
    type: String,
    default: '',
    required: true
  }) tenantShortCode

  @Prop({
    type: String,
    default: '',
    required: true
  }) productShortCode

  productInfo = null
  studentInfo = {
    email: '',
    forname: '',
    familyname: '',
    birthday: '',
    student_city: '',
    student_street: '',
    external_id: '',
    external_department: '',
    is_online: false,
    is_hardcopy: false,
    is_test: false,
    is_payed: false,
    field1: null,
    field2: null,
    field3: null,
  }

  showConfirmModal = false
  showPaypalButton = false
  showStripeButton = false
  logoImage = ''
  stampImage = require('@/assets/images/stamp.jpg')
  signImage = ''
  studentImage = null
  signDraft = null
  signFile = null

  client = {
    sandbox: '',
    production: ''
  }
  env = 'sandbox' // 'production' in live
  buttonStyle = {
    label: 'paypal',
    shape: 'rect',
    color: 'blue',
    size: 'responsive',
    tagline: false
  }

  orderItems = [
    {
      name: 'Ausweis',
      description: 'Ausweis',
      quantity: '1',
      price: this.price,
      currency: 'EUR'
    }
  ]

  orderId = 0
  paidStauts = null
  loading = false
  paypalLoading = false
  stripeLoading = false
  mobileOS = false

  paypalPaymentURL = `${ config.API_BASE }${ Urls.Order.PaypalCreateOrder }`
  stripePaymentURL = `${ config.API_BASE }${ Urls.Order.StripeCreateOrder }`

  get price () {
    if (!this.productInfo || !this.productInfo.product) {
      return 0
    }

    let price = 0
    if (this.studentInfo.is_online && this.studentInfo.is_hardcopy) {
      price = this.productInfo.price_hardcopy_online
    } else if (this.studentInfo.is_online) {
      price = this.productInfo.price_online
    } else if (this.studentInfo.is_hardcopy) {
      price = this.productInfo.price_hardcopy
    }

    price = parseFloat(price)
    return !isNaN(price)
      ? price
      : 0
  }

  get expiresSoon(){
    console.log("test")
    return this.productInfo.card_max_days_valid<93
  }

  get isSignConfirmed () {
    if (!this.productInfo.product.needs_student_sign) {
      return true
    }
    return !!this.signFile
  }

  get registerPageUrl () {
    return this.$router.resolve({
      name: 'register'
    }).href
  }

  destroyed () {
    this.paypalLoading = false
    this.stripeLoading = false
  }

  created () {
    window.addEventListener('unload', () => {
      this.paypalLoading = false
      this.stripeLoading = false
    })
  }

  mounted () {
    this.paypalLoading = false
    this.stripeLoading = false
    this.mobileOS = this.getMobileOperatingSystem()

    this.$axios
      .get(Urls.Order.ProductInfo(this.tenantShortCode, this.productShortCode))
      .then(res => {
        this.productInfo = res.data

        this.orderItems[0].price = `${ this.price }`
        this.orderItems[0].currency = this.productInfo.product.currency

        this.studentInfo.is_online = false
        this.studentInfo.is_hardcopy = false

        if (this.productInfo.can_online && this.productInfo.can_hardcopy) {
          this.studentInfo.is_online = true
        } else if (this.productInfo.can_online) {
          this.studentInfo.is_online = true
        } else if (this.productInfo.can_hardcopy) {
          this.studentInfo.is_hardcopy = true
        }

        if (!res.data.product.needs_logo) {
          this.productInfo.logo_file = res.data.product.logo
        }
        this.logoImage = this.getImagePath(this.productInfo.logo_file)
        this.signImage = this.getImagePath(this.productInfo.sign_file)

        this.getStoredOrderData()
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
        this.$router.back()
      })
  }

  getMobileOperatingSystem () {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains 'Android'
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
    }

    if (/android/i.test(userAgent)) {
        return 'Android'
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
    }

    return 'unknown'
  }

  getStoredOrderData () {
    const orderData = this.$store.getters.orderData
    if (orderData) {
      this.paidStauts = orderData.paid_status
      if (!this.paidStauts) {
        this.$store.dispatch('setOrderData', null)
        return
      }

      this.orderId = orderData.order_id
      this.studentInfo = orderData.student_info
      this.studentImage = orderData.student_image
      this.signDraft = orderData.sign_file

      if (this.paidStauts === 'success') {
        this.$toast.success('Vielen Dank für Ihre Bestellung.')
      } else if (this.paidStauts === 'failed') {
        this.$toast.error('Bei Ihrer Zahlung ist ein Fehler aufgetreten.')
      } else if (this.paidStauts === 'cancelled') {
        this.$toast.info('Ihre Bestellung wurde abgebrochen.')
      }

      if (this.paidStauts === 'processing') {
        this.showPaypalButton = orderData.show_paypal
        this.showStripeButton = orderData.show_stripe
        this.paidStauts = ''
        this.$store.dispatch('setOrderData', {
          paid_status: ''
        })
      } else {
        // remove order data in vuex
        this.$store.dispatch('setOrderData', null)
      }
    }
  }

  onChangeCheckbox () {
    this.studentInfo = {
      ...this.studentInfo
    }
    this.$nextTick(() => {
      this.isFormValid('order-form')
    })
  }

  onSelectImage (v) {
    this.studentImage = v
  }

  onConfirmSign () {
    this.signFile = this.signDraft
  }

  handleSubmitOrder () {
    this.showConfirmModal = false
    const params = new FormData()

    params.append('tenant_product_id', this.productInfo.id)
    params.append('tenant_short_code', this.productInfo.tenant_short_code)
    params.append('product_short_code', this.productInfo.product_short_code)
    params.append('image_file', this.studentImage)

    if (this.productInfo.product.needs_student_sign) {
      params.append('student_sign', this.signFile.toString().replace('data:image/png;base64,', ''))
    }

    Object.keys(this.studentInfo)
      .forEach(k => {
        let value = this.studentInfo[k]
        if (!value || value === undefined || value === null) {
          return
        }
        if (k === 'external_id' && !this.productInfo.need_external_id) {
          return
        }
        if (k === 'external_department' && !this.productInfo.need_external_department) {
          return
        }
        if (k === 'student_city' && !this.productInfo.product.needs_student_city) {
          return
        }
        if (k === 'student_street' && !this.productInfo.product.needs_student_street) {
          return
        }
        if (k === 'birthday') {
          value = this.changeDateFormat(value)
        }
        params.append(k, value)
      })

    this.loading = true
    this.paidStauts = ''

    this.$axios
      .post(Urls.Order.PlaceOrder, params)
      .then(res => {
        this.orderId = res.data.id

        // this.paymentSuccess = this.productInfo.payment_disabled || this.productInfo.tenant_payee || this.studentInfo.is_payed
        if (!res.data.price_to_pay || this.studentInfo.is_payed) {
          this.paidStauts = 'success'
        } else {
          if (res.data.pay_pal_client_id) {
            if (res.data.use_pay_pal_sandbox) {
              this.env = 'sandbox'
              this.client = {
                sandbox: res.data.pay_pal_client_id
              }
            } else {
              this.env = 'production'
              this.client = {
                production: res.data.pay_pal_client_id
              }
            }
            this.showPaypalButton = true
          }

          if (res.data.show_stripe) {
            this.showStripeButton = true
          }

          const reader = new FileReader();
          reader.readAsDataURL(this.studentImage);
          reader.onloadend = () => {
            // save ordered data in vuex
            this.$store.dispatch('setOrderData', {
              ts: this.tenantShortCode,
              pr: this.productShortCode,
              order_id: this.orderId,
              student_info: this.studentInfo,
              student_image: reader.result,
              sign_file: this.signFile,
              paid_status: '',
              show_paypal: this.showPaypalButton,
              show_stripe: this.showStripeButton,
            })
          }
        }

        if (!this.studentInfo.is_payed) {
          const message = this.paidStauts
            ? 'Bestellung gespeichert'
            : 'Bestellung gespeichert\nBitte mit PayPal oder Stripe bestätigen.'

          this.$toast.success(message)
        }
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.loading = false
      })
  }

  onSubmitOrder () {
    if (!this.studentImage || !!this.orderId || !this.isFormValid('order-form')) {
      return
    }

    if (this.productInfo.is_tenant_test || this.productInfo.is_test) {
      this.showConfirmModal = true
      return
    }

    this.handleSubmitOrder()
  }

  onCheckout (isPaypal = true) {
    this.$store.dispatch('setOrderData', {
      paid_status: 'processing'
    })
    if (isPaypal) {
      this.paypalLoading = true
    } else {
      this.stripeLoading = true
    }
  }

  onNewOrder () {
    this.studentInfo = {
      email: '',
      forname: '',
      familyname: '',
      birthday: '',
      student_city: '',
      student_street: '',
      external_id: '',
      external_department: '',
      is_online: false,
      is_hardcopy: false,
      is_test: false,
      is_payed: false,
      field1: null,
      field2: null,
      field3: null,
    }

    if (this.productInfo) {
      if (this.productInfo.can_online && this.productInfo.can_hardcopy) {
        this.studentInfo.is_online = true
      } else if (this.productInfo.can_online) {
        this.studentInfo.is_online = true
      } else if (this.productInfo.can_hardcopy) {
        this.studentInfo.is_hardcopy = true
      }
    }

    this.showPaypalButton = false
    this.showStripeButton = false
    this.paypalLoading = false
    this.stripeLoading = false

    this.studentImage = null
    this.signDraft = null
    this.signFile = null

    if (this.$refs.sign) {
      this.$refs.sign.clearPad()
    }

    this.client = {
      sandbox: '',
      production: ''
    }
    this.env = 'sandbox' // 'production' in live

    this.orderId = 0
    this.paidStauts = null
    this.loading = false
    this.$store.dispatch('setOrderData', null)
    this.resetForm('order-form')
  }
}
</script>

<style lang="sass">
.loading-spinner
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  display: flex
  align-items: center
  justify-content: center
  background-color: rgba(0, 0, 0, 0.3)
  z-index: 1

.expires-soon label
  color: #f28500 !important

.cardy-ordering
  position: relative
  margin-bottom: 170px
  z-index: 2

  .small
    font-size: 80%

  .inputs-info
    color: red
    white-space: pre-wrap

  .v-input--checkbox .v-input__control .v-input__slot
    padding-left: 0 !important
    min-height: 40px !important

    .v-input--selection-controls__input
      border: 2px solid #939393
      border-radius: 6px
      width: 30px
      height: 30px

    label
      color: #333

  input:-internal-autofill-selected
    background-color: transparent !important

  .btn-confirm
    font-size: 1rem
    padding: 8px 12px !important
    height: auto !important

  @media screen and (max-width: 820px)
    .col-card,
    .col-photo
      flex: 1 1 100%
      max-width: 100%

    #dynamic-form
      max-width: 400px

      .col
        flex: 1 1 100%
        max-width: 100%

.paypal-button
  width: 200px
  height: 36px
  margin: auto

.paypal-wrapper
  &.show
    opacity: 1

  &.hide
    opacity: 0

.btn-checkout
  position: relative
  border: 2px solid #003374
  padding: 10px 20px
  border-radius: 10px

  &.loading
    pointer-events: none

  .logo-paypal,
  .logo-stripe
    max-width: 300px

  .logo-pay
    max-height: 28px
    margin: 4px

  .logo-pay--visa
    max-height: 24px

  @media screen and (max-width: 1200px)
    .logo-pay
      max-height: 25px

    .logo-pay--visa
      max-height: 20px

  @media screen and (max-width: 640px)
    .logo-paypal,
    .logo-stripe
      width: 80%

    .logo-pay
      max-height: 20px

    .logo-pay--visa
      max-height: 15px

.input-hint
  top: -4px

  .hint-text
    left: auto
    right: 0

    &:after
      left: auto
      right: 4px

  @media screen and (min-width: 960px)
    .hint-text
      left: 0
      right: auto

      &:after
        left: 4px
        right: auto

  @media screen and (min-width: 1264px)
    .icon-info
      font-size: 32px

    .icon-delete
      font-size: 24px

    .hint-text
      margin-top: 8px
      font-size: 20px
      min-width: 400px
      padding: 20px

      &:after
        top: -8px
        left: 6px
        border-width: 0 10px 10px 10px

  @media screen and (min-width: 1920px)
    top: 0
    left: 16px

    .icon-info
      font-size: 48px

    .icon-delete
      font-size: 32px

    .hint-text
      margin-top: 15px
      min-width: 450px
      font-size: 30px
      padding: 30px

      &:after
        top: -14px
        left: 8px
        border-width: 0 16px 16px 16px

.test-confirm-dialog
  box-shadow: none
  border-radius: 4px
  border: 1px solid #1a176e !important

  .v-card__text
    color: rgba(0, 0, 0, 0.87) !important
</style>
