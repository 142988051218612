<template lang="pug">
  v-card.pa-6
    v-row.sticky-card-header.align-center.mt-4
      v-col(cols="6")
        h2.primary--text Liste Ausweise

      v-col.d-flex.justify-end.align-center(cols="6")
        v-checkbox.ml-2(v-if="isSuperAdmin" v-model="fromArchive" label="Aus-Archiv" @change="onSelectArchive")
        v-select.table-action(
          v-model="tableAction"
          clearable
          dense
          hide-details
          outlined
          :items="tableActions"
          :readonly="!selectedItemIds.length"
        )
        v-btn.ml-2(color="primary" :disabled="!tableAction || !selectedItemIds.length" @click="onTakeAction") Aktion durchführen

    custom-table.scrolling-table.mt-3(
      page-id="cards"
      :headers="headers"
      :table-data="tableData"
      stripped
      @load="(e) => loadCards(e)"
      @loaded="initSelectedItems"
    )
      template(#header.group): a.d-flex(@click="onExpandColGroup(true)")
        v-icon more_horiz
        v-icon chevron_right

      template(#header.image): .d-flex.align-center
        a.mr-3(v-if="isExpanded" @click="onExpandColGroup(false)"): v-icon chevron_left
        span Bild

      template(#filter.#="{ item }")
        v-checkbox.ml-2(v-model="selectAllItems[page]" dense @change="onChangeSelectAll")

      template(#filter.image="{ item }")
        .text-right: v-checkbox.d-inline-block(v-model="showImage[page]" dense @change="onChangeShowImage")

      template(#item.#="{ item }")
        v-checkbox(v-model="selectedItems[item.id]" dense @change="onChangeSelection")

      template(#item.online_ready="{ value }") {{ value ? 'Ja' : "Nein" }}
      template(#item.validated="{ value }") {{ value ? 'Ja' : "Nein" }}
      template(#item.birthday="{ value }") {{ formatDateTime(value, 'DD.MM.YYYY') }}
      template(#item.is_delete="{ value }") {{ value ? 'Ja' : "Nein" }}
      template(#item.is_online="{ value }") {{ value ? 'Ja' : "Nein" }}
      template(#item.is_hardcopy="{ value }") {{ value ? 'Ja' : "Nein" }}
      template(#item.created_at="{ value }") {{ formatDateTime(value, 'DD.MM.YYYY') }}

      template(#item.image="{ item }")
        img(v-if="showImage[page]" :src="tableImages[item.id]" contain width="100" height="80")

      template(#item.##="{ item }")
        .d-flex.items-center
          a.admin-view(v-if="isSuperAdmin" title="Ausweis herunterladen" @click.prevent="downloadCard(item.id)")
            v-icon file_download

          a(title="Card bearbeiten" @click.prevent="onEditCard(item)")
            v-icon edit

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'
import Urls from '@/router/urls'
import { downloadBlob } from '@/utils/download'

@Component({
  mixins: [
    AuthMixin,
    FormMixin
  ],

  watch: {
    selectedItemIds () {
      if (!this.selectedItemIds.length) {
        this.tableAction = ''
      }
    }
  }
})
export default class CardList extends Vue {
  tableData = {}
  tableItems = []
  tableImages = []

  selectedItems = {}
  selectAllItems = {}
  showImage = {}
  isExpanded = false

  page = 1
  pagination = {}
  fromArchive = false

  get headers () {
    return [
      {
        text: '#',
        align: 'left',
        value: '#',
        use_slot_filter: true,
        use_slot_cell: true
      },
      {
        text: 'ID',
        align: 'left',
        value: 'id'
      },
      {
        text: 'Produkt Typ',
        align: 'left',
        value: 'product_type',
      },
      {
        text: 'Produktcode',
        align: 'center',
        value: 'product_short_code',
        width: 100
      },
      {
        text: 'Nachname',
        align: 'left',
        value: 'familyname'
      },
      {
        text: 'Vorname',
        align: 'left',
        value: 'forname',
      },
      {
        text: 'Klasse/Abteilung',
        align: 'left',
        value: 'external_department',
      },
      {
        text: 'Geburtstag',
        align: 'center',
        value: 'birthday',
        use_slot_cell: true
      },
      {
        text: 'Validiert?',
        align: 'center',
        value: 'validated',
        use_slot_cell: true,
        filter_type: 'checkbox'
      },
      {
        text: '...',
        align: 'center',
        value: 'group',
        use_slot_header: true,
        hidden: this.isExpanded
      },
      {
        text: 'Externe-ID',
        align: 'left',
        value: 'external_id',
        hidden: !this.isExpanded
      },
      {
        text: 'Online-Ready?',
        align: 'center',
        value: 'online_ready',
        use_slot_cell: true,
        filter_type: 'checkbox',
        filter_default: true,
        hidden: !this.isExpanded
      },
      {
        text: 'Gelöscht?',
        align: 'center',
        value: 'is_delete',
        use_slot_cell: true,
        filter_type: 'checkbox',
        filter_default: false,
        hidden: !this.isExpanded
      },
      {
        text: 'Online',
        align: 'center',
        value: 'is_online',
        use_slot_cell: true,
        filter_type: 'checkbox',
        filter_default: null,
        hidden: !this.isExpanded
      },
      {
        text: 'Karte',
        align: 'center',
        value: 'is_hardcopy',
        use_slot_cell: true,
        filter_type: 'checkbox',
        filter_default: null,
        hidden: !this.isExpanded
      },
      {
        text: 'Bestelldatum',
        align: 'center',
        value: 'created_at',
        use_slot_cell: true,
        hidden: !this.isExpanded
      },
      {
        text: 'Bild',
        align: 'right',
        value: 'image',
        use_slot_filter: true,
        use_slot_header: true,
        use_slot_cell: true
      },
      {
        text: '',
        align: 'right',
        value: '##',
        use_slot_cell: true
      },
    ]
  }

  tableActions = [
    'Ausweise validieren',
    'Auswahl exportieren (Excel)',
    'Auswahl exportieren (Excel als Mail)',
    'Auswahl exportieren (inkl. Bilder)',
  ]
  tableAction = ''
  pageId = 'cards'

  get selectedItemIds () {
    return Object.keys(this.selectedItems)
      .filter(k => this.selectedItems[k])
      .map(k => parseInt(k))
  }

  created () {
    const pageFilters = this.getPageFilters(this.pageId)
    this.showImage = pageFilters.showImage || {}
    this.isExpanded = !!pageFilters.isExpanded
  }

  loadCards (pagination = null) {
    if (pagination) {
      this.pagination = pagination
    }
    this.page = pagination.page

    this.$axios
      .post(Urls.Card.List, {
        pagination,
        'use_archive': this.fromArchive,
      })
      .then(res => {
        this.tableData = {
          ...res.data,
          pagination
        }
      })
  }

  initSelectedItems (items) {
    this.tableItems = items
    items.forEach(item => {
      if (this.selectAllItems[this.page]) {
        this.selectedItems[item.id] = true
      } else if (this.selectedItems[item.id] === undefined) {
        this.selectedItems[item.id] = false
      }

      if (this.tableImages[item.id] === undefined) {
        this.tableImages[item.id] = null
      }
    })
    this.selectedItems = {
      ...this.selectedItems
    }
    if (this.showImage[this.page]) {
      this.onChangeShowImage()
    }
  }

  onSelectArchive(){
    this.loadCards(this.pagination)
  }

  onExpandColGroup (value) {
    this.isExpanded = value
    this.setPageFilter({
      page_id: this.pageId,
      field_id: 'isExpanded',
      value
    })
  }

  onChangeSelectAll () {
    if (this.selectAllItems[this.page]) {
      this.setItemSelection(true)
    } else {
      this.setItemSelection(false)
    }
    this.selectedItems = {
      ...this.selectedItems
    }
  }

  onChangeSelection () {
    const selected = this.tableItems.filter(item => this.selectedItems[item.id])
    this.selectAllItems[this.page] = selected.length === this.tableItems.length

    this.selectAllItems = {
      ...this.selectAllItems
    }
    this.selectedItems = {
      ...this.selectedItems
    }
  }

  setItemSelection (v) {
    this.tableItems.forEach(item => {
      this.selectedItems[item.id] = v
    })
  }

  handleValidateOrders () {
    this.setIsLoading(true)

    this.$axios
      .post(Urls.Card.Validate, {
        order_id: this.selectedItemIds
      })
      .then(() => {
        this.$toast.success('OK', 'Erfolg')
        this.loadCards(this.pagination)
        this.tableAction = ''
        this.selectedItems = {}
        this.selectAllItems = {}
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.$confirmDialog.close()
        this.setIsLoading(false)
      })
  }

  handleExportExcel (asMail = false, withImages = false) {
      this.setIsLoading(true)

      this.$axios
          .post(Urls.Card.ExportExcel, {
              // order_ids: this.selectedItemIds.join('-')
              order_ids: this.selectedItemIds,
              as_mail: asMail,
              with_image:withImages,
          }, false, true)
          .then(res => {
              if(!asMail){
                  downloadBlob(res.data, this.getFileNameFromHeader(res.headers))
              }else{
                  this.$toast.success('Bitte ihr Postfach überprüfen.', 'Mail gesendet')
              }
          })
          .catch(err => {
              this.$toast.error('Fehler', err.message || 'Failed to download file')
          })
          .finally(() => {
              this.setIsLoading(false)
          })
    }

  onTakeAction () {
      if (this.tableAction === 'Auswahl exportieren (Excel)') {
          this.handleExportExcel(false, false)
      }else if(this.tableAction === 'Auswahl exportieren (inkl. Bilder)'){
          this.handleExportExcel(false, true)
      }else if(this.tableAction === 'Auswahl exportieren (Excel als Mail)'){
          this.handleExportExcel(true, false)
      }else{
          this.$confirmDialog.show({
              text: `Sind Sie sicher dass Sie die Aktion ${ this.tableAction } durchführen wollen?`,
              handleOk: () => {
                  if (this.tableAction === 'Ausweise validieren') {
                      this.handleValidateOrders()
                  } else {
                      this.$confirmDialog.close()
                  }
              }
          })
      }
  }

  onChangeShowImage () {
    this.setPageFilter({
      page_id: this.pageId,
      field_id: 'showImage',
      value: {
        ...this.showImage
      }
    })

    if (!this.showImage[this.page]) {
      return
    }

    const getImageCalls = this.tableItems
      .filter(item => !this.tableImages[item.id])
      .map(item => new Promise((resolve, reject) => {
        const params = {
          order_id: item.id
        }

        return this.$axios
          .get(Urls.Card.GetImage, params, true)
          .then(res => {
            this.tableImages[item.id] = window.URL.createObjectURL(res.data)
            resolve(res)
          })
          .catch(err => {
            this.tableImages[item.id] = ''
            reject(err)
          })
      }))

    Promise.all(getImageCalls)
      .finally(() => {
        this.tableImages = {
          ...this.tableImages
        }
      })
  }

  onEditCard (item) {
    this.$router.push({
      name: 'card-edit',
      params: {
        id: item.id,
        fromArchive: this.fromArchive,
      }
    })
  }
}
</script>

<style lang="scss">
.table-action {
  max-width: 250px;
}
</style>
