import Vue from 'vue'
import VueSnotify from 'vue-snotify'

const config = {
  bodyMaxLength: 500,
  closeOnClick: true,
  oneAtTime: true,
  pauseOnHover: false,
  position: 'rightTop',
  showProgressBar: true,
  timeout: 2000,
  titleMaxLength: 100,
}

Vue.use(VueSnotify, {
  toast: config
})

Vue.prototype.$toast = {
  success (title, message, dismiss = true) {
    config.timeout = dismiss ? 2000 : -1
    Vue.prototype.$snotify.success(title, message, config)
  },

  error (title, message, dismiss = false) {
    config.timeout = dismiss ? 3000 : -1
    Vue.prototype.$snotify.error(title, message, config)
  },

  info (title, message, dismiss = false) {
    config.timeout = dismiss ? 3000 : -1
    Vue.prototype.$snotify.info(title, message, config)
  },

  warning (title, message, dismiss = false) {
    config.timeout = dismiss ? 3000 : -1
    Vue.prototype.$snotify.warning(title, message, config)
  },
}
