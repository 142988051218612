<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 170 170"
  >
    <g i:extraneous="self">
      <g>
        <g>
          <path
            class="st0"
            d="M84.46,136.29H23.21c-3.37,0-6.12-2.76-6.12-6.12v-98c0-3.37,2.75-6.12,6.12-6.12h42.87"
          />
          <path
            class="st0"
            d="M84.46,38.29h61.25c3.36,0,6.12,2.76,6.12,6.12v36.75"
          />
          <g>
            <line class="st0" x1="66.08" y1="26.04" x2="84.46" y2="38.29" />
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <rect
                  x="115.08"
                  y="124.14"
                  class="st0"
                  width="36.75"
                  height="24.5"
                />
              </g>
              <g>
                <path
                  class="st0"
                  d="M121.2,121.9v-10.01c0-6.76,5.48-12.25,12.24-12.25c6.78,0,12.26,5.49,12.26,12.25v9.1"
                />
              </g>
            </g>
            <g>
              <g>
                <path class="st1" d="M117.53,127.81" />
              </g>
            </g>
          </g>
        </g>
      </g>
      <path
        class="st2"
        d="M96.7,117.04c-5.93-9.36-16.35-15.59-28.25-15.59c-11.9,0-22.32,6.23-28.25,15.59"
      />
      <g>
        <path
          class="st3"
          d="M83.73,68.26c-2.02,1.14-4.27,1.95-6.69,2.34c-8.39,1.35-16.39-2.74-20.43-9.66"
        />
        <circle class="st3" cx="68.48" cy="71.32" r="15.55" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgSafeReliable',
}
</script>

<style lang="scss">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st1 {
  opacity: 0.1;
  fill: none;
  stroke: currentColor;
  stroke-width: 7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st2 {
  fill: none;
  stroke: currentColor;
  stroke-width: 7;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st3 {
  fill: none;
  stroke: currentColor;
  stroke-width: 7;
  stroke-miterlimit: 10;
}
</style>
