<template lang="pug">
  h3.my-10
    span(v-if="isPayed") Vielen Dank für Ihre Bestellung!
    span(v-else) Bei Ihrer Zahlung ist ein Fehler aufgetreten
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
// import Urls from '@/router/urls'

@Component({
})
export default class StripeSuccess extends Vue {
  @Prop({ type: String, default: '' }) orderId

  isPayed = false

  created () {
    this.isPayed = true
    this.gotoOrderPage()
    // this.$axios.get(Urls.Order.GetOrderPayed, {
    //   order_id: this.orderId
    // })
    //   .then(res => {
    //     this.isPayed = res.data.payed
    //     this.gotoOrderPage()
    //   })
    //   .catch(err => {
    //     this.$toast.error(err.message, 'Fehler')
    //   })
  }

  gotoOrderPage () {
    const orderData = this.$store.getters.orderData
    if (!orderData || !orderData.ts || !orderData.pr) {
      return
    }

    this.$store.dispatch('setOrderData', {
      paid_status: this.isPayed ? 'success' : 'failed'
    })
      .then(() => {
        this.$router.push({
          name: 'ordering',
          params: {
            tenantShortCode: orderData.ts,
            productShortCode: orderData.pr,
          }
        })
      })
  }
}
</script>