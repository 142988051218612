const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

const downloadBase64 = (b64Data, fileName = 'file-download', mimeType = 'application/octet-stream') => {
  const blob = b64toBlob(b64Data, mimeType)
  downloadBlob(blob, fileName, mimeType)
}

const downloadBlob = (blob, fileName = 'file-download', mimeType = 'application/octet-stream') => {
  var a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  a.addEventListener('click', (e) => {
    e.stopPropagation()
  })

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName)
  } else {
    a.href = window.URL.createObjectURL(blob)
    a.download = fileName
    a.click()
  }

  document.body.removeChild(a)
}

const downloadCommon = (data, fileName = 'file-download', type = 'text/csv') => {
  const blob = new Blob([ data ], { type: type })
  downloadBlob(blob, fileName)
}

export {
  downloadBlob,
  downloadBase64,
  downloadCommon
}
