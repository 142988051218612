<template lang="pug">
  div.login-form.mx-auto(@keydown.enter="onLogin")
    v-card.pa-6
      v-card-title
        v-icon(color="primary" size="32") power_settings_new
        h3.primary--text.ml-2 Login

      v-form.mt-3(ref="login-form" lazy-validation)
        v-text-field(
          v-model="form.email"
          label="E-Mail"
          :rules="[ rules.required, rules.email ]"
          append-icon="email"
          dense
          outlined
        )

        v-text-field(
          v-model="form.password"
          label="Passwort"
          :rules="[ rules.required, rules.password ]"
          append-icon="enhanced_encryption"
          type="password"
          dense
          outlined
        )

        v-btn.mt-4(
          block
          color="primary"
          :loading="loading"
          @click="onLogin"
        ) Login

        v-row.mt-4
          v-col(cols="6")
            a(@click.prevent="openRoute('forgot-password')") Passwort vergessen

          v-col.text-right(cols="6")
            a(@click.prevent="showEmailHint = !showEmailHint") E-Mail vergessen

          v-col.pt-0(v-if="showEmailHint" cols="12") Bitte wenden Sie sich an Ihre Organisation um Ihre E-Mail zu erfahren

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import FormMixin from '@/mixins/form'

import { mapActions, mapGetters } from 'vuex'

@Component({
  mixins: [
    FormMixin
  ],

  computed: mapGetters([
    'authToken'
  ]),

  methods: mapActions([
    'login'
  ])
})
export default class Login extends Vue {
  form = {
    email: '',
    password: '',
  }

  loading = false
  showEmailHint = false

  onLogin () {
    if (!this.isFormValid('login-form')) {
      return
    }

    this.loading = true

    this.login(this.form)
      .then(res => {
        if (res === false) {
          this.gotoRoute('tenant')
        } else {
          this.gotoRoute('admin-home')
        }
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>

<style scoped>
.login-form {
  margin-top: 150px;
  max-width: 450px;
  width: 100%;
}
</style>
