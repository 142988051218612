<template lang="pug">
  v-card.pa-6
    v-row.align-center.mt-4(v-if="!isOfTenant")
      v-col(cols="12" sm="6")
        h2.primary--text Benutzerverwaltung

      v-col.text-right(cols="12" sm="6")
        v-btn(color="primary" @click="gotoRoute('user-add')") + Neuen Benutzer anlegen

    v-data-table.mt-6(
      v-bind="userTableProps"
      :headers="headers"
      :items="users"
      :options="dataOptions"
    )
      template(#item.disabled="{ value }") {{ value ? 'Ja' : "Nein" }}
      template(#item.use_for_notify="{ value }") {{ value ? 'Ja' : "Nein" }}
      template(#item.created_at="{ value }") {{ formatDateTime(value, 'DD.MM.YYYY HH:mm:ss') }}
      template(#item.#="{ item }")
        a.mr-1(v-if="userData.user_id !== item.id" title="Benutzer entfernen" @click.prevent="onRemoveUser(item)")
          v-icon remove_circle_outline

        a(title="Benutzer bearbeiten" @click.prevent="onEditUser(item)")
          v-icon edit

      template(#footer.page-text="{ pageStart, pageStop, itemsLength }")
        span {{ pageStart }}-{{ pageStop }} von {{ itemsLength }}

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'
import Urls from '@/router/urls'

@Component({
  mixins: [
    AuthMixin,
    FormMixin
  ],
})
export default class UserList extends Vue {
  @Prop({ type: Boolean, default: false }) isOfTenant
  users = []

  userTableProps = {
    'no-data-text': 'Keine Daten vorhanden',
    'no-results-text': 'Keine Daten gefunden',
    'footer-props': {
      'items-per-page-options': [5, 15, 50, 200, 500, 2000],
      'items-per-page-text': 'Anzahl pro Seite',
    }
  }

  dataOptions = {
    itemsPerPage: 50,
  }

  headers = [
    {
      text: 'ID',
      align: 'left',
      value: 'user_id'
    },
    {
      text: 'Email',
      align: 'left',
      value: 'email'
    },
    {
      text: 'Vorname',
      align: 'left',
      value: 'forename'
    },
    {
      text: 'Nachname',
      align: 'left',
      value: 'lastname'
    },
    {
      text: 'Deaktiviert',
      align: 'center',
      value: 'disabled'
    },
    {
      text: 'Benachrichtigen',
      align: 'center',
      value: 'use_for_notify'
    },
    {
      text: 'Erstellt am',
      align: 'center',
      value: 'created_at'
    },
    {
      text: 'Gruppe',
      align: 'center',
      value: 'user_group'
    },
    {
      text: 'Aktion',
      align: 'center',
      value: '#',
    },
  ]

  tableOptions = {
    page: 1,
    itemsPerPage: -1,
    sortBy: [ 'user_id' ],
    sortDesc: [ false ],
    multiSort: false,
  }

  created () {
    this.loadUsers()
  }

  loadUsers () {
    this.$axios
      .get(Urls.User.List)
      .then(res => {
        this.users = res.data || []
      })
  }

  onEditUser (item) {
    this.$router.push({
      name: 'user-edit',
      params: {
        id: item.user_id
      }
    })
  }

  onRemoveUser (item) {
    this.$confirmDialog.show({
      text: `Der Benutzer wird von dem Mandanten ${ this.selectedTenant.name } entfernt.<br>`
        + `Der Benutzer wird <b>NICHT</b> gelöscht. Er hat  jedoch keinen Zugriff mehr auf diesen Mandanten.<br>`
        + `Benutzer tatsächlich vom Mandanten entfernen?`,
      handleOk: () => {
        this.$axios
          .post(Urls.User.RemoveProfile, {
            user_profile_id: item.user_id
          })
          .then(() => {
            this.loadUsers()
          })
          .catch(err => {
            this.$toast.error(err.message, 'Fehler')
          })
          .finally(() => {
            this.$confirmDialog.close()
          })
      }
    })
  }
}
</script>
