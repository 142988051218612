<template lang="pug">
  v-card.pa-6
    v-row.sticky-card-header.align-center.mt-4
      v-col(cols="12")
        h2.primary--text Rechnungslauf anlegen

    v-row.align-center.mt-4
      v-col(cols="12" sm="6" lg="3"): n-date-picker(
        v-model="filter.date_from"
        label="Von"
        format="YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
        clearable
      )

      v-col(cols="12" sm="6" lg="3"): n-date-picker(
        v-model="filter.date_to"
        label="Bis"
        format="YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
        clearable
      )

      v-col(cols="12" sm="6" lg="3"): v-select(
        v-model="filter.country_id"
        label="Land"
        outlined
        dense
        hide-details
        clearable
        :items="countries"
      )

      v-col.d-flex.align-center(cols="12" sm="6" lg="3")
        v-checkbox.mt-0.pt-0(
          v-model="filter.include_test"
          label="Test?"
          hide-details
        )

        v-btn.flex-grow-1.ml-5(color="primary" @click="loadInvoiceOrders") Filtern

      v-col.d-flex.justify-end.align-center(cols="12")
        v-text-field.email-input.mr-3(
          v-if="tableAction"
          v-model="additionalEmails"
          label="Zusätzliche Email-Empfänger"
          dense
          hide-details
          outlined
        )

        v-select.table-action(
          v-model="tableAction"
          clearable
          dense
          hide-details
          outlined
          :items="tableActions"
          :readonly="!selectedItems.length"
        )
        v-btn.ml-2(color="primary" :disabled="!tableAction || !selectedItems.length" @click="onCreatePaymentRun") Aktion durchführen

    v-data-table.mt-6(
      v-model="selectedItems"
      v-bind="invoiceTableProps"
      :headers="headers"
      :items="orders"
      :options="dataOptions"
      show-select
      item-key="d"
    )
      template(#item.mindate="{ value }") {{ formatDateTime(value, 'DD.MM.YYYY') }}
      template(#item.maxdate="{ value }") {{ formatDateTime(value, 'DD.MM.YYYY') }}

      template(#footer.page-text="{ pageStart, pageStop, itemsLength }")
        span {{ pageStart }}-{{ pageStop }} von {{ itemsLength }}
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'
import Urls from '@/router/urls'

@Component({
  mixins: [
    AuthMixin,
    FormMixin
  ],

  components: {
    NDatePicker: () => import('@/components/common/n-date-picker')
  },

  watch: {
    selectedItems () {
      if (!this.selectedItems.length) {
        this.tableAction = ''
      }
    }
  }
})
export default class CardList extends Vue {
  orders = []
  selectedItems = []

  invoiceTableProps = {
    'no-data-text': 'Keine Daten vorhanden',
    'no-results-text': 'Keine Daten gefunden',
    'footer-props': {
      'items-per-page-options': [5, 15, 50, 100, 200],
      'items-per-page-text': 'Anzahl pro Seite',
    }
  }

  dataOptions = {
    itemsPerPage: 100,
  }

  filter = {
    date_from: '',
    date_to: '',
    include_test: false,
    country_id: ''
  }
  additionalEmails = ''

  headers = [
    {
      text: 'Mand. ID',
      align: 'left',
      value: 'tenant_id'
    },
    {
      text: 'Mandant',
      align: 'left',
      value: 'tenant_name'
    },
    {
      text: 'Anzahl',
      align: 'left',
      value: 'count'
    },
    {
      text: 'Land',
      align: 'left',
      value: 'country_id',
    },
    {
      text: 'Summe Preis',
      align: 'left',
      value: 'sum_price'
    },
    {
      text: 'Währung',
      align: 'left',
      value: 'currency',
    },
    {
      text: 'Von',
      align: 'center',
      value: 'mindate',
    },
    {
      text: 'Bis',
      align: 'center',
      value: 'maxdate',
    },
  ]

  tableActions = [
    'Mandanten abrechnen'
  ]
  tableAction = ''

  loadInvoiceOrders () {
    this.$axios
      .get(Urls.InvoiceOrders.List, this.filter)
      .then(res => {
        this.selectedItems = []
        this.orders = (res.data || [])
          .map(o => ({
            ...o,
            selected: false
          }))
      })
  }

  onCreatePaymentRun () {
    this.setIsLoading(true)

    this.$axios
      .post(Urls.InvoiceOrders.CreatePaymentRun, {
        tenant_ids: this.selectedItems.map(item => item.tenant_id),
        additional_emails: this.additionalEmails
      })
      .then(() => {
        this.$toast.success('OK', 'Erfolg')
        this.loadInvoiceOrders()
        this.tableAction = ''
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }
}
</script>

<style lang="scss">
.table-action {
  max-width: 270px;
}

.email-input {
  max-width: 450px;
}
</style>
