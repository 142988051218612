<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 170 170"
  >
    <g i:extraneous="self">
      <g>
        <line class="st0" x1="73.97" y1="91.02" x2="73.97" y2="56.4" />
        <line class="st0" x1="73.97" y1="91.02" x2="94.74" y2="77.17" />
        <path
          class="st0"
          d="M136.28,59.13c-8.45-17.65-24.63-31.35-45.18-35.84c-35.35-7.72-70.25,14.8-77.94,50.29
          c-7.67,35.49,14.75,70.52,50.12,78.23c17.68,3.85,35.24,0.16,49.35-8.95"
        />
      </g>
      <g>
        <line class="st1" x1="113.96" y1="104.53" x2="139.74" y2="104.53" />
        <line class="st1" x1="113.96" y1="113.83" x2="139.74" y2="113.83" />
        <path
          class="st1"
          d="M159.83,125.63c-4.24,4.28-10.12,6.93-16.62,6.93c-12.91,0-23.38-10.47-23.38-23.38
          c0-12.91,10.47-23.38,23.38-23.38c6.43,0,12.26,2.6,16.48,6.8"
        />
        <path class="st1" d="M159.99,91.81" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgTimeCost',
}
</script>

<style lang="scss">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st1 {
  fill: none;
  stroke: currentColor;
  stroke-width: 7;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
</style>
