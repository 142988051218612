<template lang="pug">
  .page-section: .cardy-card.mx-auto: .form-card: .card-content.pa-8: v-row.align-start(v-if="cardInfo")
    v-col.px-4(cols="12" sm="3").card-image.d-flex.justify-center: v-img(
      v-if="cardInfo.image"
      :src="cardInfo.image"
      :aspect-ratio="0.707"
      :contain="true"
    )

    v-col(cols="12" sm="9"): v-row.card-info.pl-0.pl-sm-6
      v-col(cols="6")
        label.sub-heading Interne ID
        p {{ cardInfo.internal_id }}

      v-col(cols="6")
        label.sub-heading Nachname
        p {{ cardInfo.familyname }}

      v-col(cols="6")
        label.sub-heading Vorname
        p {{ cardInfo.forname }}

      v-col(cols="6")
        label.sub-heading Geburtstag
        p {{ formatDateTime(cardInfo.birthday, 'DD.MM.YYYY') }}

      v-col(cols="6")
        label.sub-heading Testausweis?
        p {{ cardInfo.is_test ? 'Ja' : 'Nein' }}

      v-col(cols="6")
        label.sub-heading Gelöscht?
        p {{ cardInfo.is_delete ? 'Ja' : 'Nein' }}

      v-col(cols="6")
        label.sub-heading Gültig ab
        p {{ formatDateTime(cardInfo.card_valid_from, 'DD.MM.YYYY') }}

      v-col(cols="6")
        label.sub-heading Gültig bis
        p {{ formatDateTime(cardInfo.card_valid_to, 'DD.MM.YYYY') }}

      v-col(cols="6")
        label.sub-heading Validiert?
        p {{ cardInfo.validated ? 'Ja' : 'Nein' }}

      v-col(cols="6")
        label.sub-heading Validiert von
        p {{ cardInfo.validated_from }}

      v-col(cols="6")
        label.sub-heading Validiert am
        p {{ formatDateTime(cardInfo.validated_at, 'DD.MM.YYYY') }}

      v-col(cols="6")
        label.sub-heading Erstellt am
        p {{ formatDateTime(cardInfo.created_at, 'DD.MM.YYYY') }}

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

import Urls from '@/router/urls'
import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'
// import { Base64 } from 'js-base64'

@Component({
  mixins: [
    FormMixin,
    AuthMixin
  ],
})
export default class Card extends Vue {
  @Prop({
    type: String,
    default: '',
    required: true
  }) tenantShortCode

  @Prop({
    type: String,
    default: '',
    required: true
  }) productShortCode

  @Prop({
    type: String,
    default: '',
    required: true
  }) userCode

  @Prop({
    type: String,
    default: '',
    required: true
  }) orderId

  cardInfo = null

  mounted () {
    this.loadCardInfo()
  }

  loadCardInfo () {
    const orderId = parseInt(this.orderId)
    if (isNaN(orderId)) {
      return
    }

    const params = {
      tenant_short_code: this.tenantShortCode,
      product_short_code: this.productShortCode,
      user_code: this.userCode,
      order_id: orderId
    }

    this.$axios
      .post(Urls.Card.Info, params)
      .then(res => {
        this.cardInfo = res.data || {}

        if (res.data.image) {
          const blob = this.base64toBlob(res.data.image)
          this.cardInfo.image = URL.createObjectURL(blob)
        }
      })
      .catch(err => {
        this.$toast.error('Fehler', err.message)
      })
  }
}
</script>

<style lang="sass">
.cardy-card
  position: relative
  z-index: 2
  max-width: 1024px

  .sub-heading
    font-size: 80%

  .card-image
    .v-image
      min-width: 140px
      max-width: 200px !important

  @media screen and (max-width: 640px)
    .card-image
      .v-image
        max-width: 200px !important
</style>
