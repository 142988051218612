import Vue from 'vue'
import Vuex from 'vuex'
import VueLocalStorage from 'vue-localstorage'
import config from '@/config'

if (window.localStorage) {
  Vue.use(VueLocalStorage)
}

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [(store) => {
    store.subscribe((mutation, state) => {
      // can filter the mutation by mutation.type and mutation.payload
      if (config.KEEP_LOGGED_IN && window.localStorage) {
        Vue.localStorage.set(config.STORAGE_KEY, JSON.stringify(state))
      }
    })
  }],

  modules: {
    auth: require('./auth').default,
    common: require('./common').default,
  },
})
