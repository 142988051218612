<template lang="pug">
  v-card.student-photo(outlined)
    .image-rect.d-flex.justify-center(v-if="!cropping")
      img(
        v-if="imageSrc"
        :src="imageSrc"
        :aspect-ratio="0.707"
        :contain="true"
      )

      img(
        v-else
        src="@/assets/images/user-selection.png"
        :aspect-ratio="0.707"
        :contain="true"
      )

    .cropper-wrapper(v-if="cropping")
      vue-cropper#vue-croper(
        ref="cropper"
        v-bind="cropperOptions"
      )

    v-card-actions.py-4
      v-row(v-if="imageError")
        v-col.red--text(cols="12") {{ imageError }}

      v-row(v-else)
        v-col.text-center(cols="6")
          //- a.d-inline-block.mx-auto(v-if="cropping" @click="onCropImage")
          //-   v-icon(color="primary") done
          v-btn.btn-done(v-if="cropping" color="secondary" small @click="onCropImage")
            v-icon(color="primary") done

          a.d-flex.justify-center.align-center(v-else-if="!hideSelect" :class="disabled && 'disabled'" @click="$refs.file.click()")
            span Bild hochladen
            v-icon file_upload

        v-col.position-relative.text-center(cols="6")
          span.position-absolute.hr-left(v-if="!hideSelect") |

          //- a.d-inline-block.mx-auto(v-if="cropping" @click="onResetImage")
          //-   v-icon(color="red") close
          v-btn(v-if="cropping" color="error" small @click="onResetImage")
            v-icon(color="white") close

          a.d-flex.justify-center.align-center(v-else :class="(!imageSrc || disabled) && 'disabled'" @click="onResetImage")
            span Bild entfernen
            v-icon delete

    input.d-none(
      ref="file"
      class="d-none"
      type="file"
      accept="image/*"
      @change="onSelectFile"
    )

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import FormMixin from '@/mixins/form'
import Urls from '@/router/urls'
import 'cropperjs/dist/cropper.css'

const IMAGE_WIDTH = 230
const IMAGE_HEIGHT = 340

@Component({
  mixins: [
    FormMixin
  ],

  components: {
    VueCropper: () => import('vue-cropperjs')
  },

  watch: {
    file () {
      if (this.file && typeof this.file === 'string') {
        this.imageSrc = this.file
      } else if (!this.file) {
        this.imageSrc = null
      }
    }
  }
})
export default class StudentPhoto extends Vue {
  @Prop({
    type: [Blob, String],
    default: null
  }) file

  @Prop({
    type: Boolean,
    default: false
  }) disabled

  @Prop({
    type: Boolean,
    default: false
  }) hideSelect

  imageSrc = null
  cropping = false
  imageError = null

  cropperOptions = {
    aspectRatio: IMAGE_WIDTH / IMAGE_HEIGHT,
    minCropBoxHeight: 1,
    zoomOnWheel: false,
    guides: false,
    viewMode: 1
  }

  mounted () {
    if (this.file) {
      this.imageSrc = this.file
    }
  }

  submitImage (blob) {
    const params = new FormData()

    params.append('file', blob)
    params.append('type', 'student')

    this.$axios
      .post(Urls.Order.TestImage, params)
      .then(() => {
        this.$emit('select', blob)
      })
      .catch(err => {
        this.imageError = err.message
        this.$emit('reset')
      })
  }

  onSelectFile (e) {
    if (!e.target.files || !e.target.files[0]) {
      return
    }

    this.imageError = null
    this.imageSrc = URL.createObjectURL(e.target.files[0])
    this.cropping = true

    setTimeout(() => {
      this.$refs.cropper.reset()
      this.$refs.cropper.replace(this.imageSrc)
    }, 300)
  }

  onCropImage () {
    const cropper = this.$refs.cropper
    const canvas = cropper.getCroppedCanvas({
      width: IMAGE_WIDTH,
      height: IMAGE_HEIGHT,
      fillColor: '#ffffff'
    })

    this.$refs.file.value = null
    this.imageSrc = canvas.toDataURL()
    this.cropping = false
    // for IE
    if (canvas.msToBlob) {
      const blob = canvas.msToBlob()
      if (blob) {
        this.submitImage(blob)
        return
      }
    }

    // for other browsers
    canvas.toBlob((blob) => {
      this.submitImage(blob)
    })
  }

  onResetImage () {
    this.cropping = false
    this.imageSrc = null
    this.imageError = null
    this.$refs.file.value = null
    this.$emit('reset')
  }
}
</script>

<style lang="sass">
.student-photo
  width: 100%

  .v-card__actions
    a
      color: #333
      line-height: 1.25

      &.disabled
        color: #ccc
        pointer-events: none

        .v-icon
          color: inherit

    .v-btn
      max-width: 250px
      width: 100%

    .hr-left
      top: 50%
      left: 0
      color: #999
      font-size: 30px
      transform: translateY(-50%)

  .cropper-wrapper
    display: flex
    align-items: center
    background-color: #000
    width: 100%
    height: 350px

    #vue-croper
      width: 100%
      height: 350px

      .cropper-container
        margin: auto

      .cropper-bg
        background-image: none

  .image-rect
    background-color: #efefef
    width: 100%
    height: 350px

    img
      max-width: 100%
      max-height: 100%

    .v-image
      box-shadow: none

  @media screen and (max-width: 1920px)
    .cropper-wrapper
      height: 15vw

      #vue-croper
        height: 15vw

    .image-rect
      height: 15vw

  @media screen and (max-width: 960px)
    .cropper-wrapper
      height: 30vw

      #vue-croper
        height: 30vw

    .image-rect
      height: 30vw
</style>
