<template lang="pug">
  v-data-table.custom-table(
    v-bind="tableProps"
    :class="[ stickyClass, stripped && 'stripped-table' ]"
    :headers="tableHeaders"
    :items="items"
    :custom-sort="() => []"
    :options.sync="dataOptions"
    :server-items-length="totalItems"
    :single-expand="showExpand"
    @update:options="loadItems()"
    @item-expanded="(e) => $emit('item-expanded', e)"
  )
    template(#body.prepend="{ items }")
      tr.header-filter.d-none.d-sm-table-row
        td.px-2(
          v-for="h in tableHeaders"
          :key="h.value"
          :class="h.filter_type === 'checkbox' && `text-${ h.filter_align || 'center' }`"
        )
          template(v-if="h.filterable")
            tri-state.d-inline-block(
              v-if="h.filter_type === 'checkbox'"
              v-model="filters[h.value]"
              @input="loadItems(h.value)"
            )

            v-text-field(
              v-else
              v-model="filters[h.value]"
              dense
              outlined
              hide-details
              :placeholder="h.text"
              @change="loadItems(h.value)"
            )

          template(v-if="h.use_slot_filter")
            slot(:name="`filter.${ h.value }`" :item="h")

      .header-filter.d-block.d-sm-none
        a.btn-toggle-filter(@click="showFilter = !showFilter")
          v-icon(v-if="!showFilter" size="36") keyboard_arrow_down
          v-icon(v-else size="36") keyboard_arrow_up

        .filter-list(v-if="showFilter"): template(v-for="h in tableHeaders")
          .row-filter(v-if="h.filterable" :key="h.value")
            .col-label {{ h.text }}
            .col-input
              tri-state.mt-1(
                v-if="h.filter_type === 'checkbox'"
                v-model="filters[h.value]"
                @input="loadItems(h.value)"
              )

              v-text-field(
                v-else
                v-model="filters[h.value]"
                dense
                outlined
                hide-details
                :placeholder="h.text"
                @change="loadItems(h.value)"
              )

          .row-filter(v-else-if="h.use_slot_filter" :key="h.value")
            .col-label {{ h.text }}
            .col-input
              slot(:name="`filter.${ h.value }`" :item="h")

    template(
      v-for="h in slotHeaders"
      :slot="`header.${ h.value }`"
      slot-scope="{ item }"
    )
      slot(:name="`header.${ h.value }`" :item="item")

    template(
      v-for="h in slotCells"
      :slot="`item.${ h.value }`"
      slot-scope="{ item, value }"
    )
      slot(:name="`item.${ h.value }`" :item="item" :value="value")

    template(#footer.page-text="{ pageStart, pageStop }")
      span {{ pageStart }}-{{ pageStop }} von {{ totalItems }}

    template(v-if="showExpand" #item="{ item, index, expand, isExpanded, isSelected, isMobile, headers }")
      slot(
        name="item"
        :item="item"
        :index="index"
        :expand="expand"
        :isExpanded="isExpanded"
        :isSelected="isSelected"
        :isMobile="isMobile"
        :headers="headers"
      )

    template(#expanded-item="{ item, headers }")
      slot(name="expanded-item" :item="item" :headers="headers")

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    FormMixin
  ],

  watch: {
    tableData (v) {
      this.items = v.data || []
      this.sortFields = v.sort_fields || []
      this.filterFields = v.filter_fields || []
      this.totalItems = v.total
      this.tableProps['footer-props'].pagination.itemsLength = v.total
      this.tableProps['footer-props'].pagination.pageStart = (v.pagination.page - 1) * v.pagination.count + 1
      this.tableProps['footer-props'].pagination.pageStop = v.pagination.page * v.pagination.count
      if (this.tableProps['footer-props'].pagination.pageStop > this.totalItems) {
        this.tableProps['footer-props'].pagination.pageStop = this.totalItems
      }

      this.$emit('loaded', this.items)
    }
  }
})
export default class CustomTable extends Vue {
  @Prop({ type: String, default: '' }) pageId // if pageId is empty, filter values are not saved in vuex
  @Prop({ type: Object, default: () => {} }) tableData
  @Prop({ type: Array, default: () => [] }) headers
  @Prop({ type: Boolean, default: true }) stickyHeader
  @Prop({ type: Boolean, default: false }) stripped
  @Prop({ type: Boolean, default: false }) showExpand

  items = []
  sortFields = []
  filterFields = []

  filters = {}
  totalItems = 0

  showFilter = false
  isMac = false

  get stickyClass () {
    if (!this.stickyHeader) {
      return ''
    }

    return 'sticky-header'
    // return this.isMac
    //   ? 'sticky-header filter-no-top'
    //   : 'sticky-header'
  }

  get tableHeaders () {
    return this.headers
      .filter(h => !h.hidden)
      .map(h => ({
        ...h,
        filterable: this.isFilterable(h.pagination_value || h.value),
        sortable: this.isSortable(h.pagination_value || h.value)
      }))
  }

  get slotHeaders () {
    return this.headers.filter(h => h.use_slot_header)
  }

  get slotCells () {
    return this.headers.filter(h => h.use_slot_cell)
  }

  created () {
    const pageFilters = this.pageId
      ? this.getPageFilters(this.pageId)
      : {}

    this.headers
      // .filter(h => h.filter_default !== undefined && h.filter_default !== null)
      .forEach(h => {
        if (pageFilters[h.value] !== undefined) {
          this.filters[h.value] = pageFilters[h.value]
        } else if (h.filter_default !== undefined) {
          this.filters[h.value] = h.filter_default
        }
      })

    // this.loadItems()
    this.isMac= window.navigator.userAgent.indexOf('Mac') >= 0
  }

  getPaginationField (valueField) {
    const col = this.headers.find(h => h.value === valueField)
    if (!col) {
      return ''
    }
    return col.pagination_value || col.value
  }

  isFilterable (field) {
    return this.filterFields.findIndex(f => f === field) >= 0
  }

  isSortable (field) {
    return this.sortFields.findIndex(f => f === field) >= 0
  }

  loadItems (filterFieldId = null) {
    if (filterFieldId) {
      this.dataOptions.page = 1
      // store the filter value to the vuex store
      if (this.pageId) {
        this.setPageFilter({
          page_id: this.pageId,
          field_id: filterFieldId,
          value: this.filters[filterFieldId]
        })
      }
    }

    const sorts = this.dataOptions.sortBy
      .map((s, i) => ({
        field: this.getPaginationField(s),
        is_desc: this.dataOptions.sortDesc[i]
      }))

    const filters = {}
    Object.keys(this.filters).forEach(k => {
      const v = this.filters[k]
      if (v || v === false) {
        const field = this.getPaginationField(k)
        filters[field] = {
          value: this.filters[k].toString()
        }
      }
    })

    const pagination = {
      sorts,
      filters,
      page: this.dataOptions.page,
      count: this.dataOptions.itemsPerPage > 0
        ? this.dataOptions.itemsPerPage
        : 0,
    }

    this.$emit('load', pagination)
  }
}
</script>

<style lang="scss">
.btn-toggle-filter {
  display: flex;
  justify-content: center;
  width: 100%;
}

.filter-list {
  border: 1px solid #ccc;
  max-height: calc(100vh - 360px);
  overflow: auto;
}
</style>
