<template lang="pug">
  v-card.tenant-list.pa-6
    v-row.align-center.mt-4
      v-col(cols="12" sm="6")
        h2.primary--text Liste Mandanten

      v-col.text-right(cols="12" sm="6")
        v-btn(color="primary" @click="gotoRoute('tenant-add')") + Neuen Mandanten

    custom-table.scrolling-table.mt-6(
      page-id="tenants"
      :headers="headers"
      :table-data="tableData"
      @load="(e) => loadTenants(e)"
    )
      template(#item.is_test="{ value }") {{ value ? 'Ja' : "Nein" }}
      template(#item.#="{ item }")
        .d-flex.align-center.justify-end
          a.mr-1(title="Mandanten bearbeiten" @click.prevent="onEditTenant(item)")
            v-icon edit

          a(title="Mandanten löschen" @click.prevent="onDeleteConfirm(item)")
            v-icon delete

          a(title="Mandanten auswählen" @click.prevent="onSelectTenant(item)")
            v-icon star




    delete-dialog(
      :value="showDeleteConfirm"
      :loading="loading === 'delete'"
      @ok="onDeleteTenant"
      @close="showDeleteConfirm = false"
    )
      label
        | Sind Sie sicher, dass Sie den Mandanten {{ this.deleteName }} löschen wollen? <br><br>
        | Für den Mandanten existieren zur Zeit <b> {{ deleteCounter }} </b> Bestellungen (inkl. Archiv). <br><br>
        | Durch das Löschen werden ALLE Daten zu dem Mandanten unwiederruflich gelöscht! <br><br>

    delete-dialog(
      :value="showAlternateDeleteConfirm"
      :loading="loading === 'delete'"
      @ok="onAlternateDeleteTenant"
      @close="showAlternateDeleteConfirm = false"
    )
      label
        | Der Mandant hat <b> {{ deleteCounter }} </b> Bestellungen. Sind Sie wirklich absolut sicher dass Sie den Mandanten löschen wollen?? <br><br>

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

import FormMixin from '@/mixins/form'
import Urls from '@/router/urls'

@Component({
  mixins: [
    FormMixin
  ],

  components: {
    DeleteDialog: () => import('@/components/common/delete-dialog'),
  },

})
export default class TenantList extends Vue {
  tableData = {}
  showAlternateDeleteConfirm = false
  showDeleteConfirm = false
  loading = null
  deleteItem = null
  deleteCounter = null
  deleteName = ""

  headers = [
    {
      text: 'ID',
      align: 'left',
      value: 'id'
    },
    {
      text: 'Name',
      align: 'left',
      value: 'name'
    },
    {
      text: 'Name2',
      align: 'left',
      value: 'name2'
    },
    {
      text: 'Land',
      align: 'left',
      value: 'country_id'
    },
    {
      text: 'Zugangscode',
      align: 'left',
      value: 'short_code'
    },
    {
      text: 'Mandanten-Typ',
      align: 'left',
      value: 'tenant_type'
    },
    {
      text: 'PLZ',
      align: 'left',
      value: 'plz'
    },
    {
      text: 'Ort',
      align: 'left',
      value: 'city'
    },
    {
      text: 'Test',
      align: 'center',
      value: 'is_test',
      use_slot_cell: true
    },
    {
      text: 'Aktion',
      align: 'right',
      value: '#',
      use_slot_cell: true
    },
  ]

  loadTenants (pagination) {
    this.$axios
      .post(Urls.Tenant.List, {
        pagination
      })
      .then(res => {
        this.tableData = {
          ...res.data,
          pagination
        }
      })
  }

  onEditTenant (item) {
    this.selectTenant(item)
      .then(() => {
        this.$router.push({
          name: 'tenant-edit',
          params: {
            id: item.id
          }
        })
      })
  }

  onSelectTenant (item) {
    this.selectTenant(item)
      .then(() => {
        this.$router.push({
          name: 'product-list'
        })
      })
  }

  onDeleteConfirm(item) {
    this.deleteItem = item
    this.deleteName = this.deleteItem.name
    this.showDeleteConfirm = true

    this.$axios
      .get(Urls.Tenant.TenantOrderCount, {
        tenant_id: this.deleteItem.id,
      }, false)
      .then((data) => {
        this.deleteCounter = data.data.count
      })
  }

  onDeleteTenant() {
    if (this.deleteCounter <= 10 || this.deleteCounter == null) {
      this.loading = 'delete'
      this.$axios
        .delete(Urls.Tenant.TenantDelete, {
          tenant_id: this.deleteItem.id,
        }, false)
        .then(() => {
          this.$toast.success('Mandant gelöscht', 'Löschen')
          this.showDeleteConfirm = false
          this.selectTenant(null)
          this.loadTenants()
        })
        .catch(err => {
          this.$toast.error(err.message, 'Fehler')
        })
        .finally(() => {
          this.loading = null
          this.deleteItem = null
          this.showDeleteConfirm = false
        })
    } else {
      this.showDeleteConfirm = false
      this.showAlternateDeleteConfirm = true
    }
  }

  onAlternateDeleteTenant () {
    this.loading = 'delete'
    this.$axios
      .delete(Urls.Tenant.TenantDelete, {
        tenant_id: this.deleteItem.id,
      }, false)
      .then(() => {
        this.$toast.success('Mandant gelöscht', 'Löschen')
        this.showAlternateDeleteConfirm = false
        this.selectTenant(null)
        this.loadTenants()
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.loading = null
        this.deleteItem = null
        this.showAlternateDeleteConfirm = false
      })
  }
}
</script>
