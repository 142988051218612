import Vue from 'vue'
import moment from 'moment'

import config from '@/config'
import ApiCall from '@/utils/api-call'
import Urls from '@/router/urls'

const getErrorDetails = (e) => {
  const regex = /\((.*):(\d+):(\d+)\)$/
  const stacks = e.stack.split('\n')

  if (stacks.length <= 1) {
    return {
      filepath: 'unknown',
      line: 'unknown',
    }
  }

  const match = regex.exec(e.stack.split('\n')[1])
  if (!match || match.length <= 1) {
    return {
      filepath: 'unknown',
      line: 'unknown',
    }
  }

  let filepath = match[1]
  const srcPos = filepath.indexOf('/src/')

  if (srcPos >= 0) {
    filepath = filepath.substring(srcPos)
  }

  const qmPos = filepath.indexOf('?')
  if (qmPos > 0) {
    filepath = filepath.substring(0, qmPos)
  }

  return {
    filepath,
    line: match[2],
    // column: match[3],
  }
}

Vue.config.errorHandler = async (err, vm, info) => {
  const errComponent = vm && vm.$vnode ? vm.$vnode.tag : 'unknown'
  const errInfo = `Error in ${ info }`
  const errDetails = getErrorDetails(err)

  const params = {
    secret_key: config.JSLOG_SECRET_KEY,
    client_timestamp: moment().format(),
    message: err.message,
    type: err.name,
    component: errComponent,
    line_nr: `${ errDetails.line }`,
    params: `${ errInfo }: ${ errDetails.filepath }`,
    browser: navigator.userAgent
  }

  await ApiCall.post(Urls.Dev.SaveError, params)
}

window.addEventListener('unhandledrejection', async (event) => {
  // event.promise contains the promise object
  event.preventDefault()
  const errDetails = getErrorDetails(event.reason)

  const params = {
    secret_key: config.JSLOG_SECRET_KEY,
    client_timestamp: moment().format(),
    message: event.reason.message,
    type: event.reason.name,
    component: 'promise',
    line_nr: `${ errDetails.line }`,
    params: `${ event.type }: ${ errDetails.filepath }`,
    browser: navigator.userAgent
  }

  await ApiCall.post(Urls.Dev.SaveError, params)
})
