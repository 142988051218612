<template lang="pug">
  v-navigation-drawer.primary(
    v-model="drawer"
    app
    dark
    clipped
    fixed
    width="250"
  )
    v-list.mt-10(expand)
      template(v-for="(item,i) in sidebarMenus")
        v-list-group(
          v-if="!item.hidden && item.children && item.children.length > 0"
          :key="i"
          :no-action="true"
          :color="`white`"
          :prepend-icon="item.icon"
          :value="getActiveClass(item) !== ''"
        )
          v-list-item.px-0(slot="activator")
            v-list-item-content(@click="gotoRoute(item.name)")
              v-list-item-title {{ item.text }}

          template(v-for="subItem in item.children")
            v-list-item(
              v-if="!subItem.hidden"
              :class="getActiveClass(subItem)"
              :key="subItem.title"
              :to="{ name: subItem.name }"
              :append="true"
              :exact="true"
            )
              v-list-item-action
                v-icon {{ subItem.icon }}

              v-list-item-content
                v-list-item-title
                  span(:id="getItemId(subItem)") {{ subItem.text }}

        v-list-item(
          v-else-if="!item.hidden"
          :class="getActiveClass(item)"
          :key="i"
          :to="{ name: item.name }"
          :append="true"
          :exact="true"
        )
          v-list-item-action
            v-icon {{ item.icon }}

          v-list-item-content
            v-list-item-title
              span(:id="getItemId(item)") {{ item.text }}
</template>

<script>
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'

import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    AuthMixin,
    FormMixin
  ],

  computed: mapGetters([
    'sidebarVisible',
  ]),

  methods: mapActions([
    'setSidebarVisible'
  ])
})
export default class NavigationBar extends Vue {
  @Watch('drawer')
  onSetSidebarVisible () {
    this.setSidebarVisible(this.drawer)
  }

  @Watch('sidebarVisible')
  onSetDrawer () {
    this.drawer = this.sidebarVisible
  }

  drawer = true

  get sidebarMenus () {
    return [
      {
        text: 'Mein Profil',
        name: 'my-profile',
        hidden: this.isSuperAdmin,
        icon: 'account_box'
      },
      {
        text: 'Verwaltung',
        hidden: !this.isSuperAdmin,
        icon: 'contact_mail_outline',
        children: [
          {
            text: 'Bestellungen',
            name: 'order-list',
            icon: 'format_list_bulleted',
          },
          {
            text: 'R-Lauf anlegen',
            name: 'invoice-list',
            icon: 'format_list_bulleted',
            hidden: this.userData && !this.userData.can_invoice
          },
          {
            text: 'Rechnungsläufe',
            name: 'run-list',
            icon: 'format_list_bulleted',
            hidden: this.userData && !this.userData.can_invoice
          },
          {
            text: 'Registrierungen',
            name: 'register-filter',
            icon: 'format_list_bulleted',
          },
        ],
      },
      {
        text: 'Auswertung',
        hidden: !this.isSuperAdmin || !this.userData.can_report,
        icon: 'insert_chart',
        children: [
          {
            text: 'Bestellungen',
            name: 'report',
            icon: 'format_list_bulleted',
          },
        ],
      },
      {
        text: 'Mandanten',
        hidden: !this.isSuperAdmin,
        icon: 'supervisor_account',
        children: [
          {
            text: 'Liste Mandanten',
            name: 'tenant-list',
            icon: 'format_list_bulleted',
          },
        ],
      },
      {
        text: 'Benutzerverwaltung',
        hidden: !this.selectedTenant || (!this.isSuperAdmin && !this.isAdmin),
        icon: 'person_add_alt',
        name: 'user-list',
      },
      {
        text: 'Produkte',
        hidden: !this.selectedTenant || this.isUser,
        icon: 'inventory_2',
        children: [
          {
            text: 'Produktliste',
            name: 'product-list',
            icon: 'format_list_bulleted',
          },
        ],
      },
      {
        text: 'Ausweise',
        hidden: !this.selectedTenant,
        icon: 'credit_card',
        name: 'card-list',
      },
      // {
      //   text: 'Kunden wählen',
      //   name: 'company-list',
      //   hidden: !this.selectedAgency
      // },
    ]
  }

  getItemId (item) {
    let id = item.name

    if (item.params) {
      const params = Object.keys(item.params).map(k => item.params[k]).join('-')
      id = `${ id }-${ params }`
    }
    return id
  }

  getActiveClass (item) {
    let activeClass = ''

    if (item.name !== this.$route.name) {
      if (item.children) {
        activeClass = item.children.map(c => this.getActiveClass(c)).join('')
      }
      return activeClass
    }

    let bSelected = true

    if (item.params) {
      Object.keys(this.$route.params)
        .map(key => {
          if (item.params[key] !== this.$route.params[key]) {
            bSelected = false
          }
        })
    }

    if (bSelected) {
      activeClass = 'nav-item--active'

      setTimeout(() => {
        this.updateScrollPosition(item)
      }, 300)
    }
    return activeClass
  }

  getElementTop (element) {
    if (!element) {
      return 0
    }

    let top = 0

    do {
      top += element.offsetTop || 0
      element = element.offsetParent
    } while (element)

    return top
  }

  updateScrollPosition (item) {
    const id = this.getItemId(item)
    const excludeIds = [ '' ]

    if (!id || excludeIds.includes(id)) {
      return
    }

    const scrollWrapper = document.getElementsByClassName('v-navigation-drawer__content')[0]
    const selectedElement = document.getElementById(id)

    if (!scrollWrapper) {
      return
    }

    let scrollTop = scrollWrapper.clientHeight + scrollWrapper.scrollTop
    let elementTop = this.getElementTop(selectedElement)
    let scrollOffsetY = 0

    elementTop = elementTop - scrollWrapper.offsetTop // 48 is height of nav item

    if (elementTop > scrollTop) {
      scrollOffsetY = scrollWrapper.scrollTop + (elementTop - scrollTop)
      scrollWrapper.scrollTo(0, scrollOffsetY + scrollWrapper.clientHeight / 2)
    } else if (elementTop < scrollWrapper.scrollTop) {
      scrollOffsetY = scrollWrapper.scrollTop - elementTop
      scrollWrapper.scrollTo(0, scrollOffsetY - scrollWrapper.clientHeight / 2)
    }
  }
}
</script>
