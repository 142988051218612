import moment from 'moment'

export default {
  email: val => (!val || /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/.test(val)) || 'Ungültige Email',
  number: val => /^[0-9]*$/.test(val, 10) || 'Bitte eine Zahl angeben',
  password: val => (val.length >= 8 && val.length <= 30) || 'Bitte mindestens 8 und maximal 30 Zeichen angeben',
  password_match: (v2) => (v) => v === v2 || 'Werte stimmen nicht überein',
  required: val => !!val || 'Bitte einen Wert angeben',
  required_boolean: val => (val === true || val === false) || 'Bitte einen Wert angeben',
  required_optional: (sibling) => (val) => (sibling === true || val === true) || 'Bitte einen Wert angeben',
  between: val => (val >= 0 && val <= 10) || 'Bitte einen Wert zwischen 0 und 10 angeben',
  minLen: (n) => (val) => !val || val.length >= n || `Bitte geben Sie mindestens ${ n } Zeichen an`,
  maxLen: (n) => (val) => !val || val.length <= n || `Bitte geben Sie maximal ${ n } Zeichen an`,
  digits: val => !val || /^[0-9.]+$/.test(val) || 'Bitte eine Zahl angeben',
  integer: val => !val || (!isNaN(parseInt(val)) && val % 1 === 0) || 'Bitte geben Sie eine Ganzzahl ein',
  float: val => !val || (!isNaN(parseFloat(val)) && val % 1 !== 0) || 'Bitte geben Sie eine Kommazahl ein',
  valid_array: val => val.length > 0 || 'Bitte einen Wert angeben',
  object: val => !val || typeof val === 'object' || 'Bitte einen Wert angeben',
  valid_date: (format) => (val) => {
    if (!val) {
      return true
    }

    let format_ = format || 'DD.MM.YYYY'

    const val_ = moment(val, format_, true)
    if (val_.isValid()) {
      return true
    }

    format_ = format_.replace('DD', 'TT')
    format_ = format_.replace('YYYY', 'JJJJ')

    return `Das Datumsformat muss ${ format_ } sein.`
  },
  date_between: (from, to, format = 'DD.MM.YYYY') => (val) => {
    if ((!from && !to) || !val) {
      return true
    }

    let curDate, fromDate, toDate

    curDate = moment(val, format)
    if (from) {
      fromDate = moment(from, format)
      if (curDate.isBefore(fromDate)) {
        return `Bitte geben Sie das Datum nach dem ${ fromDate.format('DD.MM.YYYY') } ein`
      }
    }

    if (to) {
      toDate = moment(to, format)
      if (curDate.isAfter(toDate)) {
        return `Bitte geben Sie das Datum vor dem ${ toDate.format('DD.MM.YYYY') } ein`
      }
    }

    return true
  }
}
