import Vue from 'vue'
import vuetify from './plugins/vuetify'
import './plugins/jslog'
import './plugins/translation'

import ApiCall from './utils/api-call'
import App from './App.vue'
import router from './router'
import store from './store'

import './utils/toast'
import 'vuetify/src/styles/main.sass'
import '@/assets/sass/main.scss'

import ConfirmDialog from '@/components/common/confirm-dialog'
import CustomTable from '@/components/common/custom-table'
import InfoTooltip from '@/components/common/info-tooltip'
import TriState from '@/components/common/tri-state'

Vue.component('ConfirmDialog', ConfirmDialog)
Vue.component('CustomTable', CustomTable)
Vue.component('InfoTooltip', InfoTooltip)
Vue.component('TriState', TriState)

Vue.config.productionTip = false
Vue.prototype.$axios = ApiCall

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
