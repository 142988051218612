<template lang="pug">
  v-card.product-image(outlined)
    .image-rect.d-flex.justify-center(v-if="!cropping")
      v-img(
        v-if="imageSrc"
        :src="imageSrc"
        :aspect-ratio="0.707"
        :contain="true"
      )

    .cropper-wrapper(v-if="cropping")
      vue-cropper#vue-croper(
        ref="cropper"
        v-bind="cropperOptions"
      )

    v-card-actions.py-3
      v-row(v-if="imageError")
        v-col.red--text(cols="12") {{ imageError }}

      v-row(v-else)
        v-col.d-flex.align-center.justify-center(v-if="cropping" cols="6")
          v-btn.btn-done(color="secondary" block small @click="onCropImage")
            v-icon(color="primary") done

        v-col.text-center(v-else cols="9")
          .d-flex.align-center
            slot(name="action-text")

        v-col.position-relative.text-center(v-if="cropping" cols="6")
            v-btn(color="error" block small @click="onResetImage")
              v-icon(color="white") close

        v-col.position-relative.text-center(v-else cols="3")
          v-btn(color="secondary" block small :disabled="disabled" @click="$refs.file.click()")
            v-icon(color="primary") cloud_upload

    input.d-none(
      ref="file"
      class="d-none"
      type="file"
      accept="image/*"
      @change="onSelectFile"
    )

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import FormMixin from '@/mixins/form'
import Urls from '@/router/urls'
import 'cropperjs/dist/cropper.css'

@Component({
  mixins: [
    FormMixin
  ],

  components: {
    VueCropper: () => import('vue-cropperjs')
  },

  watch: {
    file () {
      if (this.file) {
        this.imageSrc = this.file
      }
    }
  }
})
export default class ProductImage extends Vue {
  @Prop({ type: String, default: null }) file
  @Prop({ type: String, default: 'logo' }) type
  @Prop({ type: Number, default: 0, required: true }) width
  @Prop({ type: Number, default: 0, required: true }) height
  @Prop({ type: Boolean, default: false }) disabled
  @Prop({ type: Boolean, default: false }) hideSelect
  @Prop({ type: Boolean, default: false }) isBase64

  imageSrc = null
  cropping = false
  imageError = null

  cropperOptions = {
    aspectRatio: 1,
    minCropBoxHeight: 1,
    zoomOnWheel: false,
    guides: false,
    viewMode: 1
  }

  mounted () {
    this.cropperOptions.aspectRatio = this.width / this.height
    if (this.file) {
      this.imageSrc = this.file
    }
  }

  blobToBase64 (blob) {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result)
      }
    })
  };

  submitImage (blob) {
    const params = new FormData()

    params.append('file', blob)
    params.append('type', this.type)

    this.$axios
      .post(Urls.Order.TestImage, params)
      .then(() => {
        if (!this.isBase64) {
          this.$emit('select', blob)
        } else {
          this.blobToBase64(blob)
            .then(res => {
              this.$emit('select', res.split(',')[1])
            })
        }
      })
      .catch(err => {
        this.imageError = err.message
        this.onResetImage()
        this.$emit('reset')
      })
  }

  onSelectFile (e) {
    if (!e.target.files || !e.target.files[0]) {
      return
    }

    const image = new Image()
    const file = e.target.files[0]

    this.imageError = null
    this.imageSrc = URL.createObjectURL(file)

    image.src = this.imageSrc
    image.onload = () => {
      // console.log('image size: ', image.width, image.height)
      if (image.width === this.width && image.height === this.height) {
        this.submitImage(file)
      } else {
        this.cropping = true
        setTimeout(() => {
          this.$refs.cropper.reset()
          this.$refs.cropper.replace(this.imageSrc)
        }, 300)
      }
    }
  }

  onCropImage () {
    const cropper = this.$refs.cropper
    const canvas = cropper.getCroppedCanvas({
      width: this.width,
      height: this.height,
      fillColor: '#ffffff'
    })

    this.$refs.file.value = null
    this.imageSrc = canvas.toDataURL()
    this.cropping = false

    canvas.toBlob((blob) => {
      this.submitImage(blob)
    })
  }

  onResetImage () {
    this.$refs.file.value = null
    this.cropping = false
    this.imageSrc = null
    this.imageError = null
    this.$emit('reset')
    if (this.file) {
      this.imageSrc = this.file
    }
  }
}
</script>

<style lang="sass">
.product-image
  width: 318px

  .cropper-wrapper
    display: flex
    align-items: center
    background-color: #000
    width: 100%
    height: 180px

    #vue-croper
      width: 100%
      height: 180px

      .cropper-container
        margin: auto

      .cropper-bg
        background-image: none

  .image-rect
    background-color: #efefef
    width: 100%
    height: 180px

    img
      max-width: 100%
      max-height: 100%

    .v-image
      box-shadow: none
</style>
