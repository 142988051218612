<template lang="pug">
  .app-toolbar.d-flex.align-center
    v-btn.mr-5(icon @click.stop="setSidebarVisible(!sidebarVisible)")
      v-icon(color="primary") menu

    h2.primary--text
      a.d-flex.align-center.cursor-pointer(@click.prevent="gotoRoute('home')") Cardy
    // account_balance apartment
    v-spacer

    template(v-if="selectedTenant && isSuperAdmin")
      v-icon.mr-1(color="primary") supervised_user_circle
      a(@click="onSelectTenant(selectedTenant.id)")
        span.grey--text.text--darken-3 {{ selectedTenant.name }} {{ selectedTenant.short_code ? `(${ selectedTenant.short_code })` : '' }}
      a(@click="onDeselectTenant"): v-icon.deselect-tenant.ml-1.mr-6(color="black") close

    v-icon.mr-1(color="primary") person
    span.grey--text.text--darken-3 {{ userName }}

    v-btn.ml-5(text icon @click.stop="onLogout")
      v-icon(color="primary") logout

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    AuthMixin,
    FormMixin
  ],

  computed: mapGetters([
    'selectedTenant',
    'sidebarVisible',
    'userName'
  ]),

  methods: mapActions([
    'logout',
    'selectTenant',
    'setSidebarVisible'
  ])
})
export default class ToolBar extends Vue {
  clipped = true

  onDeselectTenant () {
    this.selectTenant(null)
    this.$router.push({
      name: 'admin-home'
    })
  }

  onSelectTenant (tenantID) {
    if(tenantID){
      this.$router.push({
        params:{
          id: tenantID
        },
        name: 'tenant-edit'
      })
    }
  }

  onLogout () {
    this.$confirmDialog.show({
      text: 'Wirklich abmelden?',
      handleOk: () => {
        this.$confirmDialog.close()
        this.logout()
      }
    })
  }
}
</script>

<style lang="sass">
.v-application
  .app-toolbar
    position: sticky
    top: 0
    background-color: #fff
    z-index: 2

  .deselect-tenant
    position: relative
    top: -9px
    font-size: 18px
</style>
