<template lang="pug">
  .cardy-register.page-section
    v-row.ma-0
      v-col.px-0(cols="12" md="6")
        h1 Kontakt-Anforderung
        p.large.pa-4 Um Cardy nutzen zu können, müssen Sie ihre Organisation (Schule, Uni, Firma etc.) einmalig registrieren. Dafür benötigen wir folgende Informationen.
        p.large.pa-4 Wir setzen uns anschließend umgehend mit Ihnen in Verbindung, um weitere benötigte Informationen zu erfragen.

        img.v-image.mt-8.mx-auto(src="@/assets/images/register.png" width="80%")

      v-col.px-0(cols="12" md="6")
        .form-card
          .card-content.mt-6.pb-10
            v-form(v-if="!showSuccess" ref="registering-form" lazy-validation): v-row.align-center.ma-0.pb-4
              v-card-text.pt-6.pb-0
                | Die Kontakt-Anforderung ist <b>NICHT</b> für Schüler, Eltern, Mitarbeiter, Mitglieder etc. gedacht, sondern für die dazugehörige Organisation (Schule, Firma, Verein, etc.)</br>
                | Bei Fragen zur Bestellung wenden Sie sich bitte an Ihre <b>Organisation</b>
              v-col.col(cols="12" sm="6" md="12" lg="6"): v-select(
                v-model="form.salutation"
                label="Anrede*"
                hide-details
                outlined
                :items="salutations"
                :rules="[ rules.required ]"
              )

              v-col.col(cols="12" sm="6" md="12" lg="6"): v-text-field(
                v-model="form.title"
                label="Titel"
                hide-details
                outlined
              )

              v-col.col(cols="12" sm="6" md="12" lg="6"): v-text-field(
                v-model="form.forename"
                label="Vorname*"
                hide-details
                outlined
                :rules="[ rules.required ]"
              )

              v-col.col(cols="12" sm="6" md="12" lg="6"): v-text-field(
                v-model="form.familyname"
                label="Nachname*"
                hide-details
                outlined
                :rules="[ rules.required ]"
              )

              v-col.col(cols="12"): v-text-field(
                v-model="form.tenantname"
                label="Name der Organisation* (z. B. Realschule Musterstadt)"
                hide-details
                outlined
                :rules="[ rules.required ]"
              )

              v-col.col(cols="12"): v-text-field(
                v-model="form.area"
                label="Bereich* (z.B. Schule, Universität, etc)"
                hide-details
                outlined
                :rules="[ rules.required ]"
              )

              v-col.col(cols="12"): v-text-field(
                v-model="form.Street"
                label="Strasse*"
                hide-details
                outlined
                :rules="[ rules.required ]"
              )

              v-col.col(cols="12" sm="6" md="12" lg="6"): v-text-field(
                v-model="form.plz"
                label="PLZ*"
                hide-details
                outlined
                :rules="[ rules.required ]"
              )

              v-col.col(cols="12" sm="6" md="12" lg="6"): v-text-field(
                v-model="form.city"
                label="Ort*"
                hide-details
                outlined
                :rules="[ rules.required ]"
              )

              v-col.col(cols="12" sm="6" md="12" lg="6"): v-text-field(
                v-model="form.telefone"
                label="Telefonnummer*"
                hide-details
                outlined
                :rules="[ rules.required ]"
              )

              v-col.col(cols="12" sm="6" md="12" lg="6"): v-text-field(
                v-model="form.email"
                label="E-Mail Adresse*"
                hide-details
                outlined
                :rules="[ rules.required, rules.email ]"
              )

              v-col.col(cols="12"): v-text-field(
                v-model="form.web_url"
                label="Web-URL (falls vorhanden)"
                hide-details
                outlined
              )

              v-col.col(cols="12"): v-checkbox(
                v-model="form.confirm"
                hide-details
                outlined
                :rules="[ rules.required ]"
              )
                span(slot="label") Bestätigen Sie, dass Sie eine Organisation (Schule, Firma, Verein, etc.) sind und <b>NICHT</b> ein Schüler/Mitglied/Mitarbeiter welcher einen Ausweis bestellen möchte.

              v-col.col(cols="12" sm="6" md="12" lg="6") *Pflichtangaben
              v-col.col(cols="12" sm="6" md="12" lg="6"): v-btn(
                block
                color="accent"
                @click="onRegister"
              ) Anfrage abschicken

            template(v-else)
              p.pa-0.pa-lg-5
                | Vielen Dank für die Kontakt-Anfrage.<br>
                | Wir melden uns schnellstmöglich bei Ihnen.<br><br>
                | Ihr Cardy-Team
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import Urls from '@/router/urls'

import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    FormMixin
  ]
})
export default class Home extends Vue {
  form = {
    salutation: '',
    title: '',
    forename: '',
    familyname: '',
    tenantname: '',
    area: '',
    telefone: '',
    email: '',
    confirm: false
  }
  showSuccess = false

  salutations = [
    'Frau',
    'Herr',
    'divers'
  ]

  onFocus (id) {
    const el = document.getElementById(id)
    if (el) {
      el.focus()
    }
  }

  onRegister () {
    if (!this.isFormValid('registering-form')) {
      return
    }

    this.$axios
      .post(Urls.Register.Registering, this.form)
      .then(() => {
        this.$toast.success('Vielen Dank. Wir bearbeiten Ihre Registrierung.')
        this.showSuccess = true
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
