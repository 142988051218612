<template lang="pug">
  .user-details
    v-dialog(v-model="showSetPasswordDialog" width="450")
      set-password(
        :user-id="id"
        @close="showSetPasswordDialog = false"
      )

    delete-dialog(
      :value="showResetPasswordDialog"
      :loading="loading"
      :width="525"
      button-text="um Passwort zu setzen"
      @ok="handleResetPassword"
      @close="showResetPasswordDialog = false"
    )
      label
        | Durch das Zurücksetzen des Passworts wird der Benutzer gesperrt.<br>
        | Dem Benutzer wird eine Mail mit einem Link zum Setzen seines neuen Passworts zugesandt.<br>
        | Erst nachdem er diesen Link geklickt hat, kann er sich erneut anmelden.<br><br>
        | Sind Sie sicher, dass Sie das Passwort zurücksetzen möchten?

    delete-dialog(
      :value="showConfirmDialog"
      :loading="loading"
      button-text="um zu Ändern"
      @ok="handleSaveUser"
      @close="onCancel"
    )
      label
        | Das Ändern der E-Mail deaktiviert den Benutzer, solange, bis er auf einen Link in einer ihm zugesandt E-Mail klickt.<br><br>
        | Wollen Sie die E-Mail wirklich ändern?

    .action-header
      v-btn.mr-2(v-if="isMyProfile || id === userData.user_id" color="primary" :loading="loading" @click="showSetPasswordDialog = true") Mein Passwort ändern
      v-btn.admin-button.mr-2(v-else-if="id && (isSuperAdmin || isAdmin)" color="primary" :loading="loading" @click="showResetPasswordDialog = true") Passwort zurücksetzen
      v-btn(v-if="isUpdated" color="primary" :loading="loading" @click="onSaveUser") Speichern

    v-card.pa-6: v-form(ref="form-user" lazy-validation)
      v-row
        v-col(sm="6"): h2.primary--text {{ title }}

      v-row.my-6
        v-col.pb-0(cols="12" sm="6" md="3"): v-text-field(
          v-model="user.user_id"
          label="ID"
          dense
          outlined
          disabled
        )

        v-col.pb-0(cols="12" sm="6" md="3"): v-select(
          v-model="user.salutation"
          label="Anrede"
          dense
          outlined
          :items="salutations"
          :rules="[ rules.required ]"
        )

        v-col.pb-0(cols="12" sm="6" md="3"): v-text-field(
          v-model="user.forename"
          label="Vorname"
          dense
          outlined
        )

        v-col.pb-0(cols="12" sm="6" md="3"): v-text-field(
          v-model="user.lastname"
          label="Nachname"
          dense
          outlined
          :rules="[ rules.required ]"
        )

        v-col.pb-0(cols="12" sm="6"): v-text-field(
          v-model="user.email"
          label="E-Mail"
          dense
          outlined
          :rules="[ rules.required, rules.email ]"
        )

        v-col.pb-0(cols="12" sm="6")
          v-select(
            v-model="user.user_group"
            label="Benutzer Gruppe"
            dense
            outlined
            :items="userGroups"
            :rules="[ rules.required ]"
            :disabled="(!isSuperAdmin && !isAdmin) || isMyProfile || id === userData.user_id"
          )
          p {{ userGroupDescription }}

        v-col.pb-0(v-if="id" cols="12" sm="6"): v-text-field(
          :value="formatDateTime(user.created_at, 'DD.MM.YYYY HH:mm:ss')"
          label="Erstellt am"
          dense
          outlined
          disabled
        )

        v-col.pb-0(v-if="id" cols="12" sm="6")

        v-col.mb-3(cols="6" sm="3"): v-checkbox.mt-1(
          v-model="user.disabled"
          label="Deaktiviert"
          hide-details
          :disabled="(!isSuperAdmin && !isAdmin) || isMyProfile || id === userData.user_id || !user.email_validated || user.must_reset_password"
        )

        v-col.mb-3(cols="6" sm="3"): v-checkbox.mt-1(
          v-model="user.use_for_notify"
          label="Validierungsstatus senden (täglich)"
          hide-details
          :disabled="(!isSuperAdmin && !isAdmin) || isMyProfile || id === userData.user_id"
        )

        template(v-if="id")
          v-col.mb-3(cols="12" sm="6" md="3"): v-checkbox.mt-1(
            v-model="user.must_reset_password"
            label="Passwort setzen nötig"
            hide-details
            disabled
          )

          v-col.mb-3(cols="12" sm="6" md="3"): v-checkbox.mt-1(
            v-model="user.email_validated"
            label="E-Mail validiert"
            hide-details
            disabled
          )

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

import Urls from '@/router/urls'
import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    AuthMixin,
    FormMixin
  ],

  components: {
    DeleteDialog: () => import('@/components/common/delete-dialog'),
    SetPassword: () => import('@/pages/auth/password'),
  },

  watch: {
    user: {
      deep: true,
      handler () {
        this.isUpdated = this.dataLoaded
      }
    },
  }
})
export default class UserDetails extends Vue {
  @Prop({ type: [ Number, String ], default: 0 }) id
  @Prop({ type: Boolean, default: false }) isMyProfile

  user = {
    disabled: false
  }
  userGroups = [
    {
      text: 'Administrator',
      value: 'Admin',
    },
    {
      text: 'Benutzer',
      value: 'User'
    }
  ]
  oldUser = ''

  showConfirmDialog = false
  showSetPasswordDialog = false
  showResetPasswordDialog = false
  loading = false
  dataLoaded = false
  isUpdated = false

  get title () {
    if (this.isMyProfile) {
      return 'Mein Profil'
    }

    if (!this.id) {
      return 'Benutzer anlegen'
    }

    return this.user && this.user.user_id
      ? `Benutzer (${ this.oldUser.forename } ${ this.oldUser.lastname }) bearbeiten`
      : `Benutzer bearbeiten`
  }

  get showPasswordButton () {
    if (this.isMyProfile) {
      return true
    }

    if (!this.id) {
      return false
    }

    return this.isSuperAdmin || this.isAdmin
  }

  get userGroupDescription () {
    if (!this.user || !this.user.user_group) {
      return ''
    }
    return this.user.user_group === 'Admin'
      ? 'Administratoren können alle Ausweise bearbeiten, sowie die Produkte und Benutzer verwalten.'
      : 'Benutzer können nur bestehende Ausweise Verwalten.'
  }

  created () {
    this.dataLoaded = !this.id
    this.isUpdated = !this.id
    this.loadUserDetails()
  }

  loadUserDetails () {
    if (!this.id) {
      return
    }

    const requestUri = this.isMyProfile
      ? Urls.User.MyProfile
      : Urls.User.Profile

    let params = {
      user_id: this.id
    }
    if (this.isMyProfile) {
      params = null
    }

    this.$axios
      .get(requestUri, params)
      .then(res => {
        const user = (res.data || {})
        this.user = {
          user_id: user.id,
          email: user.email,
          salutation: user.salutation,
          forename: user.forename,
          lastname: user.lastname,
          created_at: user.created_at,
          disabled: user.disabled,
          must_reset_password: user.must_reset_password,
          email_validated: user.email_validated,
          user_group: user.tenants[0].user_group,
          use_for_notify: user.tenants[0].use_for_notify,
        }
        if (this.isMyProfile && (this.isSuperAdmin || this.isAdmin)) {
          this.user.user_group = 'Admin'
        }
        this.oldUser = {
          ...this.user
        }
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.isUpdated = false
        this.dataLoaded = true
      })
  }

  onSaveUser () {
    if (!this.isFormValid('form-user')) {
      return
    }

    if (this.id && this.oldUser.email !== this.user.email) {
      this.showConfirmDialog = true
      return
    }

    this.handleSaveUser()
  }

  onCancel () {
    this.showConfirmDialog = false
    this.user = {
      ...this.oldUser
    }
    this.$nextTick(() => {
      this.isUpdated = false
    })
  }

  handleResetPassword () {
    this.loading = true
    this.$axios
      .post(Urls.Auth.ResetPassword, {
        user_id: this.user.user_id
      })
      .then(() => {
        this.loadUserDetails()
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.showResetPasswordDialog = false
        this.loading = false
      })
  }

  handleSaveUser () {
    const params = {
      email: this.user.email,
      salutation: this.user.salutation,
      forename: this.user.forename,
      lastname: this.user.lastname,
      use_for_notify: this.user.use_for_notify,
    }

    if (!this.isMyProfile) {
      params.user_id = this.user.user_id || null
      params.disabled = this.user.disabled
      params.user_group = this.user.user_group
    }

    const requestUri = this.isMyProfile
      ? Urls.User.MyProfile
      : Urls.User.Profile

    this.loading = true
    this.$axios
      .post(requestUri, params)
      .then(() => {
        this.isUpdated = false
        this.$toast.success('Daten gespeichert', 'Speichern')

        if (!this.id) {
          this.$router.push({
            name: 'user-list'
          })
        } else {
          this.loadUserDetails()
          if (this.isMyProfile) {
            this.$store.dispatch('updateUserData', params)
          }
        }
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.loading = false
        this.showConfirmDialog = false
      })
  }
}
</script>
