<template lang="pug">
  v-card.pa-6
    v-row.sticky-card-header.align-center.mt-4
      v-col(cols="6")
        h2.primary--text Liste Registrierungen

      v-col.d-flex.justify-end.align-center(cols="6")
        v-select.table-action(
          v-model="tableAction"
          clearable
          dense
          hide-details
          outlined
          :items="tableActions"
          :readonly="!selectedItemIds.length"
        )
        v-btn.ml-2(color="primary" :disabled="!tableAction || !selectedItemIds.length" @click="onTakeAction") Aktion durchführen

    custom-table.scrolling-table.mt-3(
      page-id="registers"
      :headers="headers"
      :table-data="tableData"
      stripped
      @load="(e) => loadData(e)"
      @loaded="initSelectedItems"
    )
      template(#filter.#="{ item }")
        v-checkbox.ml-2(v-model="selectAllItems[page]" dense @change="onChangeSelectAll")

      template(#item.#="{ item }")
        v-checkbox(v-model="selectedItems[item.id]" dense @change="onChangeSelection")

      template(#item.created_at="{ value }") {{ formatDateTime(value, 'DD.MM.YYYY HH:mm:ss') }}

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'
import Urls from '@/router/urls'

@Component({
  mixins: [
    AuthMixin,
    FormMixin
  ],

  watch: {
    selectedItemIds () {
      if (!this.selectedItemIds.length) {
        this.tableAction = ''
      }
    }
  }
})
export default class CardList extends Vue {
  tableData = {}
  tableItems = []

  selectedItems = {}
  selectAllItems = {}

  page = 1
  pagination = {}

  headers = [
    {
      text: '#',
      align: 'left',
      value: '#',
      use_slot_filter: true,
      use_slot_cell: true
    },
    {
      text: 'ID',
      align: 'left',
      value: 'id'
    },
    {
      text: 'Status',
      align: 'left',
      value: 'status'
    },
    {
      text: 'Anrede',
      align: 'left',
      value: 'salutation'
    },
    {
      text: 'Titel',
      align: 'left',
      value: 'title'
    },
    {
      text: 'Vorname',
      align: 'left',
      value: 'forname',
    },
    {
      text: 'Nachname',
      align: 'left',
      value: 'familyname'
    },
    {
      text: 'Mandanten-Name',
      align: 'left',
      value: 'tenantname'
    },
    {
      text: 'Strasse',
      align: 'left',
      value: 'street',
    },
    {
      text: 'PLZ',
      align: 'left',
      value: 'plz',
    },
    {
      text: 'Ort',
      align: 'left',
      value: 'city',
    },
    {
      text: 'Bereich',
      align: 'left',
      value: 'area',
    },
    {
      text: 'Telefon',
      align: 'left',
      value: 'telefone',
    },
    {
      text: 'Email',
      align: 'left',
      value: 'email',
    },
    {
      text: 'WebURL',
      align: 'left',
      value: 'web_url',
    },
    {
      text: 'Erstellt am',
      align: 'right',
      value: 'created_at',
      use_slot_cell: true
    },
  ]

  tableActions = [
    'Registrierung beantwortet'
  ]
  tableAction = ''

  get selectedItemIds () {
    return Object.keys(this.selectedItems)
      .filter(k => this.selectedItems[k])
      .map(k => parseInt(k))
  }

  loadData (pagination = null) {
    if (pagination) {
      this.pagination = pagination
    }
    this.page = pagination.page

    this.$axios
      .post(Urls.Register.Filter, {
        pagination
      })
      .then(res => {
        this.tableData = {
          ...res.data,
          pagination
        }
      })
  }

  initSelectedItems (items) {
    this.tableItems = items
    items.forEach(item => {
      if (this.selectAllItems[this.page]) {
        this.selectedItems[item.id] = true
      } else if (this.selectedItems[item.id] === undefined) {
        this.selectedItems[item.id] = false
      }
    })
    this.selectedItems = {
      ...this.selectedItems
    }
  }

  onChangeSelectAll () {
    if (this.selectAllItems[this.page]) {
      this.setItemSelection(true)
    } else {
      this.setItemSelection(false)
    }
    this.selectedItems = {
      ...this.selectedItems
    }
  }

  onChangeSelection () {
    const selected = this.tableItems.filter(item => this.selectedItems[item.id])
    this.selectAllItems[this.page] = selected.length === this.tableItems.length

    this.selectAllItems = {
      ...this.selectAllItems
    }
    this.selectedItems = {
      ...this.selectedItems
    }
  }

  setItemSelection (v) {
    this.tableItems.forEach(item => {
      this.selectedItems[item.id] = v
    })
  }

  handleAnswerRegistrations () {
    this.setIsLoading(true)

    this.$axios
      .post(Urls.Register.Answer, {
        register_id: this.selectedItemIds
      })
      .then(() => {
        this.$toast.success('OK', 'Erfolg')
        this.loadData(this.pagination)
        this.tableAction = ''
        this.selectedItems = {}
        this.selectAllItems = {}
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  onTakeAction () {
    if (this.tableAction === 'Registrierung beantwortet') {
      this.handleAnswerRegistrations()
    }
  }
}
</script>

<style lang="scss">
.table-action {
  max-width: 250px;
}
</style>
