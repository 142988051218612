import Vue from 'vue'

import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import config from '@/config'

const initState = {
  companies: [],
  filters: {},
  filter_expires: {},
  orderData: null,
  selectedTenant: null,
  sidebarVisible: true,
  isLoading: false,
}

function getInitialState (reset = false) {
  const stateFromStorage = window.localStorage
    ? JSON.parse(Vue.localStorage.get(config.STORAGE_KEY, '{}'))
    : null
  const commonState = stateFromStorage && stateFromStorage.common
    ? stateFromStorage.common
    : {}

  const excludeKeys = [
    'isLoading',
    'sidebarVisible',
  ]

  Object.keys(initState)
    .filter(k => !excludeKeys.includes(k))
    .map(k => {
      initState[k] = config.KEEP_LOGGED_IN && !reset && commonState[k]
        ? commonState[k]
        : null
    })

  return initState
}

mutations.RESET_STATE = (state) => {
  const state_ = getInitialState(true)

  Object
    .keys(state_)
    .map(key => {
      state[key] = state_[key]
    })
}

export default {
  state: getInitialState(),
  mutations,
  getters,
  actions
}
