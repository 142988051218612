<template lang="pug">
  div.forgot-form.mx-auto(@keydown.enter="onSendForgotRequest")
    v-card.pa-6
      v-card-title.pa-0
        v-icon(color="primary" size="32") settings
        h3.primary--text.ml-2 Passwort vergessen

      v-form.mt-6(v-if="!isSent" ref="forgot-form" lazy-validation)
        p.mb-4
          span Geben Sie bitte Ihre Email-Adresse ein.

        v-text-field(
          v-model="form.email"
          label="Email"
          append-icon="email"
          outlined
          dense
          :rules="[ rules.required, rules.email ]"
        )

        v-btn(block color="primary" :loading="loading" @click="onSendForgotRequest") senden

      p.mt-6.mb-3(v-else)
        span Falls wir Ihre E-Mail finden konnten, haben wir das Passwort Ihres Benutzers zurückgesetzt und Ihnen eine E-Mail zum Setzen des neuen Passworts zugesandt.

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

import Urls from '@/router/urls'
import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    FormMixin
  ],
})
export default class ForgotPassword extends Vue {
  form = {
    email: '',
  }

  loading = false
  isSent = false

  onSendForgotRequest () {
    if (!this.isFormValid('forgot-form')) {
      return
    }

    this.loading = true
    this.$axios
      .post(Urls.Auth.ForgotPassword, this.form)
      .then(() => {
        this.isSent = true
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>

<style scoped>
.forgot-form {
  margin-top: 150px;
  max-width: 450px;
  width: 100%;
}
</style>
