var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-6"},[(!_vm.isOfTenant)?_c('v-row',{staticClass:"align-center mt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Benutzerverwaltung")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.gotoRoute('user-add')}}},[_vm._v("+ Neuen Benutzer anlegen")])],1)],1):_vm._e(),_c('v-data-table',_vm._b({staticClass:"mt-6",attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.dataOptions},scopedSlots:_vm._u([{key:"item.disabled",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : "Nein"))]}},{key:"item.use_for_notify",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? 'Ja' : "Nein"))]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.formatDateTime(value, 'DD.MM.YYYY HH:mm:ss')))]}},{key:"item.#",fn:function(ref){
var item = ref.item;
return [(_vm.userData.user_id !== item.id)?_c('a',{staticClass:"mr-1",attrs:{"title":"Benutzer entfernen"},on:{"click":function($event){$event.preventDefault();return _vm.onRemoveUser(item)}}},[_c('v-icon',[_vm._v("remove_circle_outline")])],1):_vm._e(),_c('a',{attrs:{"title":"Benutzer bearbeiten"},on:{"click":function($event){$event.preventDefault();return _vm.onEditUser(item)}}},[_c('v-icon',[_vm._v("edit")])],1)]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_c('span',[_vm._v(_vm._s(pageStart)+"-"+_vm._s(pageStop)+" von "+_vm._s(itemsLength))])]}}])},'v-data-table',_vm.userTableProps,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }