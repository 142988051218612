<template lang="pug">
  h3.my-10
    span Ihre Bestellung wurde abgebrochen.
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import Urls from '@/router/urls'

@Component({
})
export default class StripeCancel extends Vue {
  @Prop({ type: String, default: '' }) orderId

  created () {
    if (!this.orderId) {
      return
    }

    this.$axios.post(Urls.Order.StripeCancelOrder, {
      order_id: parseInt(this.orderId)
    })
      .then(res => {
        this.gotoOrderPage()
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
  }

  gotoOrderPage () {
    const orderData = this.$store.getters.orderData
    if (!orderData || !orderData.ts || !orderData.pr) {
      return
    }

    this.$store.dispatch('setOrderData', {
      paid_status: 'cancelled'
    })
      .then(() => {
        this.$router.push({
          name: 'ordering',
          params: {
            tenantShortCode: orderData.ts,
            productShortCode: orderData.pr,
          }
        })
      })
  }
}
</script>