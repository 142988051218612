<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 170 170"
  >
    <g i:extraneous="self">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <polyline
                    class="st0"
                    points="84.83,155.41 32.32,155.41 32.32,24.15 57.98,24.15"
                  />
                  <polyline
                    class="st0"
                    points="111.75,24.15 137.34,24.15 137.34,96.34"
                  />
                </g>
                <path
                  class="st0"
                  d="M111.08,11.02v9.84c0,5.41-4.7,9.84-10.47,9.84H69.05c-5.77,0-10.47-4.44-10.47-9.84v-9.84"
                />
                <line
                  class="st0"
                  x1="58.58"
                  y1="11.02"
                  x2="111.08"
                  y2="11.02"
                />
              </g>
              <line class="st0" x1="110.74" y1="56.96" x2="84.49" y2="56.96" />
            </g>
          </g>
        </g>
        <g>
          <polyline
            class="st0"
            points="150.46,122.6 128.72,155.41 111.08,139.33"
          />
        </g>
        <path
          class="st1"
          d="M58.24,60.37L58.24,60.37c-1.88,0-3.41-1.53-3.41-3.41v0c0-1.88,1.53-3.41,3.41-3.41h0
          c1.88,0,3.41,1.53,3.41,3.41v0C61.64,58.84,60.12,60.37,58.24,60.37z"
        />
        <path
          class="st1"
          d="M58.24,86.71L58.24,86.71c-1.88,0-3.41-1.53-3.41-3.41v0c0-1.88,1.53-3.41,3.41-3.41h0
          c1.88,0,3.41,1.53,3.41,3.41v0C61.64,85.18,60.12,86.71,58.24,86.71z"
        />
        <path
          class="st1"
          d="M58.6,113.4L58.6,113.4c-1.88,0-3.41-1.53-3.41-3.41v0c0-1.88,1.53-3.41,3.41-3.41h0
          c1.88,0,3.41,1.53,3.41,3.41v0C62.01,111.88,60.48,113.4,58.6,113.4z"
        />
        <line class="st0" x1="110.74" y1="83.22" x2="84.49" y2="83.22" />
        <line class="st0" x1="110.74" y1="109.47" x2="84.49" y2="109.47" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgChecklist',
}
</script>

<style lang="scss">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st1 {
  fill: currentColor;
}
</style>
