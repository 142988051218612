<template lang="pug">
  v-dialog(
    v-model="showModal"
    content-class="white"
    max-width="450"
    width="100%"
  )
    v-card
      v-card-title(v-if="title") {{ title }}
      v-card-text.pt-3.pb-0(v-html="text")
      v-card-actions
        v-spacer

        v-btn(
          color="green darken-1"
          text
          @click="close"
        ) Nein

        v-btn(
          color="primary"
          text
          @click="onOk"
        ) Ja

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

@Component({

})
export default class ConfirmDialog extends Vue {
  title = ''
  text = ''
  showModal = false
  handleOk = null

  get value_ () {
    return this.value
  }

  set value_ (v) {
    this.$emit('input', v)
  }

  show (options = {}) {
    this.title = options.title || 'Bestätigen'
    this.text = options.text || ''
    this.handleOk = options.handleOk || (() => {})
    this.showModal = true
  }

  close () {
    this.showModal = false
  }

  onOk () {
    if (this.handleOk && typeof this.handleOk === 'function') {
      this.handleOk()
    }
  }
}
</script>
