export default {
  SET_COMPANIES (state, payload) {
    state.companies = payload
  },

  SET_ORDER_DATA (state, payload) {
    if (!payload) {
      state.orderData = null
    } else {
      state.orderData = {
        ...state.orderData,
        ...payload
      }
    }
  },

  SET_PAGE_FILTER (state, payload) {
    if (!payload.page_id) {
      return
    }
    if (!payload.field_id) {
      return
    }
    const filters = state.filters || {}
    const filter_expires = state.filter_expires || {}
    if (!filters[payload.page_id]) {
      filters[payload.page_id] = {}
    }
    if (!filter_expires[payload.page_id]) {
      filter_expires[payload.page_id] = {}
    }

    filters[payload.page_id][payload.field_id] = payload.value
    // update the expire time of page filter
    filter_expires[payload.page_id] = Math.floor(new Date() / 1000)

    state.filters = {
      ...filters
    }
    state.filter_expires = {
      ...filter_expires
    }
  },

  RESET_PAGE_FILTER (state, payload) { // payload is page id
    if (!payload) {
      return
    }
    if (!state.filters[payload]) {
      return
    }
    state.filters[payload] = null
    state.filters = {
      ...state.filters
    }
    if (!state.filter_expires[payload]) {
      return
    }
    state.filter_expires[payload] = 0
    state.filter_expires = {
      ...state.filter_expires
    }
  },

  SELECT_TENANT (state, payload) {
    state.selectedTenant = payload
  },

  SET_IS_LOADING (state, payload) {
    setTimeout(() => {
      state.isLoading = payload
    }, payload ? 0 : 200)
  },

  SET_SIDEBAR_VISIBLE (state, payload) {
    state.sidebarVisible = payload
  },
}
